import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { NotificationManager } from "react-notifications"

import { FaEraser, FaSearch, FaEdit, FaPlus } from 'react-icons/fa';
import { BiRefresh } from 'react-icons/bi';
import { ImBin } from 'react-icons/im';
import { Tooltip } from '@mui/material';
import axiosConfig from '../../../utils/axiosConfig';
import { Pagination } from '../../Pagination'

const ContenidoDash = () => {
  
  const tableNames = [ '', 'Descripcion', 'Opciones' ];
  
  const [ rowsPerPage, setRowsPerPage ] = useState( 10 );
  const [ prevPage,       setPrevPage ] = useState( null )
  const [ currentPage, setCurrentPage ] = useState( 1 )
  const [ nextPage,       setNextPage ] = useState( null )
  const [borrar, setborrar] = useState(true)
  const [ Drives, setDrives ] = useState( [] );
  const [ search, setSearch ] = useState( '');
  const [change, setchange] = useState(false)

  const getDrives = async () => {
    setchange(true)
    const data = await axiosConfig({
      method: 'get',
      url: `/contenidos`,
      params: {
        limit: rowsPerPage,
        page: currentPage,
        ...(search ? { search } : {}),
      },
    });
    
    if( !!data.rows ){
      setDrives( data.rows )
      setCurrentPage(data.currentPage)
      setPrevPage(data.prevPage)
      setNextPage(data.nextPage)
      setchange(false)
    }
  };

  const handleDelete = ( idContenido ) => {  

    Swal.fire({
      title: '¿Estás seguro que quieres eliminarlo?',
      showDenyButton: true,
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
    } ).then( ( result ) => {
      if( result.isConfirmed ){
        axiosConfig({
          method: 'PUT',
          url: `/contenidos/activo/${ idContenido }`,
        } ).then( async () => {
          NotificationManager.success( "", "Eliminado", 2500 )
          await getDrives();
        } ).catch( () => NotificationManager.success( "Ingresó mal los campos o hay problema con el internet", "Oops", 2500 ) )
      } else {
        if( result.isDenied ) {
          NotificationManager.info( "", "Cancelado", 2500 )
        }
      }
    } )

  }
  
  const handleBorrar = () => {
    setRowsPerPage( 10 );
    setSearch('');
    setborrar(!borrar);
  }
  
  useEffect( () => {
    getDrives();
  }, [ rowsPerPage ,borrar, currentPage] );

  return (
    <div className=''>
      <div className='p-8'>
        <div className='flex flex-row justify-between'>
          <div className='flex flex-row '>
          <Tooltip title='Borrar Busqueda' placement='top' arrow>
            <button className=' px-1.5 bg-gray-100 border-gray-400 border rounded'
            onClick={() => handleBorrar()}>
              <FaEraser size={16} />
            </button>
            </Tooltip>
            <div className='w-4'></div>
            <Tooltip title='Actualizar Busqueda' placement='top' arrow>
            <button
              type='button'
              className=' px-1 bg-gray-100 border-gray-400 border rounded'
              onClick={ () => getDrives() }
            >
              <BiRefresh size={24} />
            </button>
            </Tooltip>
            <div className='w-4'></div>
            <Tooltip title='Buscar' placement='top' arrow>
            <button
              type='button'
              className='  px-2 bg-gray-300 border-gray-300 border-b-2 border-l-2 border-t-2 rounded-l'
              onClick={ () => getDrives() }
            >
              <FaSearch />
            </button>
            </Tooltip>

            <input
              className='w-60  border-2 border-gray-300 rounded-r	 pt-1 '
              placeholder='Buscar'
              value={search}
              onChange={ ( event ) => setSearch( event.target.value ) }
            />
          </div>
          <div className='flex flex-row '>
            <div className='tablamenu pt-1 pr-2'>Número de registros</div>

            <div className='flex flex-row'>
              <select
                value={ rowsPerPage }
                onChange={ ( event ) => setRowsPerPage( parseInt( event.target.value ) ) }
                className='border-gray-400 border rounded '
              >
                <option value='10'>10</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
              </select>

              <Link to='/inicio/catalogo/contenidos/nuevoContenido'>
                <button className='botonverde py-1 pr-1 flex flex-row w-12/12 ml-4 justify-items-center rounded-sm shadow'>
                  <div className='pt-1 px-1'>
                    <FaPlus />
                  </div>
                  <div>Generar nuevo contenido</div>
                </button>
              </Link>
            </div>
          </div>
        </div>
        <table className='content-table w-full'>
          <thead>
          { Drives.length === 0 && <tr /> }  
            { Drives.length > 0 &&
              <tr>
                { Drives.length > 0 && tableNames.map( ( name, index ) => {
                  return <th key={ index } > { name } </th>;
                } ) }
              </tr>
            } 
          </thead>
          <tbody>
          { Drives.length === 0 && 
              <tr><td> 
                <div className='w-full grid justify-items-center text-xl font-bold'> No hay contenidos </div>
              </td></tr>
            }
            { Drives.map( ( Drives, index ) => {
              return (
                <tr key={ index } >
                  <td></td>
                  <td>{Drives.descripcion}</td>
                  <td>
                    <div className='flex flex-row justify-center'>
                      {/* <button className=' botonazuldetalles   '>
                        <Link
                          to={`/inicio/catalogo/cdetalle/${Drives.idContenido}`}
                          className='w-16'
                        >
                          <div className='flex flex-row'>
                            <div className='pt-1 px-1'>
                              <BsCardList />
                            </div>
                            <div>Detalles</div>
                          </div>
                        </Link>
                      </button> */}
                      <div className='p-3'></div>
                      <button className=' botonverdedetalles   '>
                        <Link
                          to={`/inicio/catalogo/contenidos/editar/${Drives.idContenido}`}
                          className='w-16'
                        >
                          <div className='flex flex-row'>
                            <div className='pt-1 px-1'>
                              <FaEdit />
                            </div>
                            <div>Editar</div>
                          </div>
                        </Link>
                      </button>
                      <div className='p-3'></div>

                      <button
                        type='button'
                        className=' botonrojodetalles   '
                        onClick={  () => handleDelete( Drives.idContenido )  }
                      >
                        <div className='flex flex-row'>
                          <div className='pt-1 px-1'>
                            <ImBin />
                          </div>
                          <div>Eliminar</div>
                        </div>
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        
        <div className='flex   mt-3 flex-row-reverse'>
        
        <Pagination
            { ...{ currentPage, prevPage, nextPage, change } }
            { ...{ setCurrentPage } }
          />
          
      </div>
      </div>
    </div>
  );
};

export default ContenidoDash;
