import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import SideMenu from '../components/SideBar/SideMenu';
import Topbar from './../components/topbar';
import { Panel } from './panel.routes';

import { getToken } from '../utils/authService';


const Layout = () => {
  const [ inactive, setInactive ] = useState( true );
  const [ estilo, setEstilo ] = useState({  maxWidth:"92vw",  paddingTop: "85px", trasition: "all 0.5s ease" })
  
  useEffect( () => {
    setEstilo( s => ({ ...s, ...( inactive ? {"maxWidth": "92vw" } : { "maxWidth": "83vw"} )  }) )
  }, [ inactive ] )
  
  const token = getToken()
  if ( !token ) {    
    return <Redirect to='/login' />;
  }

  return (
    <div className='flex flex-row'>
      <div className='bg-black'>
        <SideMenu onCollapse={ ( s ) => setInactive( s ) } />
      </div>
      <div className={`container ${ inactive ? 'inactive' : ''}`}>
        <div className=' w-full'>
          <Topbar inactive={inactive} />
        </div>
        <div style={estilo} >
          <Panel />
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default Layout;
