import React from 'react';
import { useHistory } from 'react-router-dom/';
import { FaUserCircle } from 'react-icons/fa';

import logo from '../assets/logo.png';
import jwt from 'jsonwebtoken';
import { logout } from '../utils/authService';
import { getToken } from '../utils/authService';
import Dropdown from '@material-tailwind/react/Dropdown';
// import DropdownItem from '@material-tailwind/react/DropdownItem';
import DropdownLink from '@material-tailwind/react/DropdownLink';

const Topbar = ( { inactive } ) => {
  const token = getToken();
  const nombre = jwt.decode(token);
  const history = useHistory();

  const padding = inactive ? "6rem" :  "13rem" ;
  
  return (
    <div className='Topbar flex flex-row justify-between bg-white h-20 ' style={ { paddingRight: padding } }>
      
      <div className='p-2'>
        <img src={logo} alt='Logo' />
      </div>

      <div className='flex flex-row'>
        <div className='pt-6 pl-2 '>
          <FaUserCircle />
        </div>
        <div>
          <div className='pt-5 px-2'>{ nombre.fullname }</div>
          <div className='Letralight text-xs '>{ nombre.role }</div>
        </div>

        <div className='pt-5 '>
          <Dropdown
            color='#ffffff'
            placement='bottom-start'
            buttonType='link'
            size='sm'
            rounded={false}
            block={false}
            ripple='dark'
            buttonText=""
          >
            <DropdownLink
              href='#'
              color='gray'
              ripple='light'
              onClick={ ( e ) => {
                  e.preventDefault();
                  logout();
                  history.push("/")
                } 
              }
            >
              Cerrar Sesión
            </DropdownLink>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
