import React, { useEffect, useState } from 'react';
import axiosConfig from '../../utils/axiosConfig';

import { AiOutlineCloseSquare } from 'react-icons/ai';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';

import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import jwt from 'jsonwebtoken';
import { getToken } from '../../utils/authService';

import Modal from 'react-modal';

import { NotificationManager } from 'react-notifications';

Modal.setAppElement('#root');

const dirAlterInitial = {
  calle: '',
  numero: '',
  colonia: '',
  cpAlternativo: '',
  ciudad: '',
  estado: '',
}

const Cotizar = () => {
  const token = getToken();
  const nombre = jwt.decode(token);
  const tableNames = [
    'AplicaCotizacion',
    'AplicaServicio',
    'CCSobrePeso',
    'CCTarifaBase',
    'CargosExtra',
    'CostoTotal',
    'DescripcionServicio',
    'Peso',
    'SobrePeso',
    'TarifaBase',
    'TipoEnvioRes',
  ];

  const history = useHistory();

  const [ usuarios, setUsuarios ] = useState([]);
  const [ costo, setCosto ] = useState('');
  const [ servicioW, setServicioW ] = useState('-');
  
  const [ contenido, setContenido] = useState([]);
  const [ idUsuario, setIdUsuario] = useState('');
  const [ vdetalle, setVdetalle] = useState({});
  const [ isOpen, setIsOpen] = useState( false );
  const [ valores, setValores] = useState();
  
  const [ idOrigen, setIdOrigen ] = useState( '' )
  const [ idDestino, setIdDestino ] = useState( '' )
  
  const [ segmentos, setSegmentos ] = useState([]);
  const [ sucursales, setSucursales ] = useState([]);

  const [ auxSucursal, setAuxSucursal ] = useState( [] );
  const [ autorizado, setAutorizado ] = useState( false );

  const [ codigoPostalAlternativoValid, setCodigoPostalAlternativoValid ] = useState( false )
  const [ ubicacionOrig, setUbicacionOrig ] = useState( [] )
  const [ ubicaciones, setUbicaciones] = useState([]);

  const [ ubicacionesDestino, setUbicacionesDestino ] = useState( [] );
  const [ contadorGuias, setContadroGuias ] = useState( 1 )
  
  const [ estados,  setEstados  ] = useState( [] );
  const [ ciudades, setCiudades ] = useState( [] );

  const [ dirAlter, setDirAlter ] = useState( false )
  const [ formDirAlter, setFormDirAlter ] = useState( dirAlterInitial );
  const {  calle, numero, colonia, cpAlternativo, ciudad, estado } = formDirAlter

  const handleChangeDirAlter = ( { target } ) => {
    
    if( target.name === 'estado' ){
      const idEstado = target.value
      
      if( idEstado === "" ){
        setFormDirAlter( s => ( { ...s, [target.name]: target.value } ) )
        setCiudades( [] )
        return
     }
      
      axiosConfig( {
        method: 'GET',
        url: `/ubicaciones/ciudades/${ idEstado }`,
      } ).then( ( data ) => {
        setCiudades( data.rows )
      } ).catch( err => {
        NotificationManager.error( "Ingresó mal los campos o hay problema con el internet 1", '¡Error!' , 3000, () => {} )
      } )

    }

    if( target.name === 'cpAlternativo' ){
      const direccionCP = target.value

      axiosConfig( {
        method: 'POST',
        url: '/guias/validarCobertura/',
        data: {
          cpDestino: String( direccionCP ) 
        }
      } ).then( data => {
        
        setCodigoPostalAlternativoValid( data.resultado !== 'Codigo postal destino existente' ) 
      } ).catch( err => console.log( err ) )

    }

    setFormDirAlter( s => ( { ...s, [target.name]: target.value } ) )
  }

  const getEstados = async () => {
    const data = await axiosConfig( {
       method: 'GET',
       url: `/ubicaciones/estados`,
    } );

    if( !!data.rows ){
       setEstados( data.rows )
    }else{
       NotificationManager.error( "Ingresó mal los campos o hay problema con el internet 2", '¡Error!' , 3000, () => {} )
    }
  };
  
  const getId = () => {
    for (let i = 0; i < usuarios.length; i++) {
      if (usuarios[i].name === nombre.fullname) {
        setIdUsuario(usuarios[i].user_id);
      }
    }
  };

  const toggleModal = () => {
    getId();
    setIsOpen( s => !s );
  }

  const getUbicacionOrig = async () => {
    
    const data = await axiosConfig( {
       method: "GET",
       url: 'usuarios'
    } );
    
    if( !!data.rows  && data.rows.length > 0 ){
      const token = getToken();
      const user  = jwt.decode( token );
      const aux   = data.rows.filter( item => item.email === user.email )[0]

      const resp = await axiosConfig( {
        method: "GET",
        url: `usuarios/${ aux.user_id }`
      } );
      
      if( !!resp.user ){
        
        const ubicaciones = await axiosConfig( {
          method: "GET",
          url: `ubicaciones`
        } );
        
        if( !!ubicaciones.rows && ubicaciones.rows.length > 0 ){

          // let ubicacionAux = []
          // let itemAux = {}
          
          // const { Usuario_asignacion_sucursal } = resp.user
          
          // if( !!Usuario_asignacion_sucursal && Usuario_asignacion_sucursal.length > 0 ){
            
          //   for (let i = 0; i < Usuario_asignacion_sucursal.length; i++) {
          //     for( let j = 0; j < ubicaciones.rows.length; j++ ) {
              
          //       if( Usuario_asignacion_sucursal[i].Sucursal.nombre === ubicaciones.rows[j].Sucursal.nombre ){
          //           itemAux = { direccionCP: ubicaciones.rows[j].direccionCP, nombreUbicacion: `${ ubicaciones.rows[j].nombreUbicacion } - ${ ubicaciones.rows[j].Sucursal.nombre }` }
          //           ubicacionAux.push( itemAux )
          //       }
          //     }
          //   }    
          // }
          const arregloAux = ubicaciones.rows.filter( item => item.idSucursal === parseInt( resp.user["Sucursal.idSucursal"] ) ) 
          setUbicacionOrig( arregloAux );

          if( arregloAux.length === 0 ) {
            NotificationManager.error( "No hay ubicaciones para asignarle a este usuario", "", 3000 )
          }
          
        } else {
          NotificationManager.error( "hay problema con Consulta ubicaciones", "Oops..", 2500 )
        }
      } else {
        NotificationManager.error( "hay problema con la consulta de ubicacion-user", "Oops..", 2500 )
      }
    } else {
      NotificationManager.error( "hay problema con la consulta de Usuarios", "Oops..", 2500 )
    }
  } 

  const handleCosto = async ( valueSelect ) => {
    for (let servicio of vdetalle.result.Respuesta.TipoServicio.TipoServicio) {
      if ( valueSelect === servicio.DescripcionServicio._text) {
        await setCosto( servicio.CostoTotal._text );
        await setServicioW(valueSelect);
      }
    }
  }

  const postCotizar = async ( values ) => {
    
    const data = await axiosConfig({
      method: 'POST',
      url: `/guias/cotizarGuia`,
      data: {
        largo: String( values.largo ),
        peso:  String( values.peso ),
        alto:  String( values.alto ),
        ancho: String( values.ancho ),
        ubicacionOrigen: values.cpOrigen,
        ubicacionDestino:  dirAlter ? cpAlternativo : values.cpDestino,
      },
    }).catch((err) => {
      if (err === 'Validation/ValidationError') {
        setIsOpen(false);
        NotificationManager.error( "Ingresó mal los campos o hay problema con el internet (Cotizar)", "Error", 2500 )
      }
    } );
    
    if( data ){
      const aux = data.result.Respuesta.TipoServicio.TipoServicio[0]
      setServicioW( aux.DescripcionServicio._text )
      setCosto( aux.CostoTotal._text )
      setVdetalle(data);
      toggleModal()
    }
  }

  const handleChangeOrigen = ( { target } ) => {
    const origen = target.value
    
    if( origen === "" ){
      formik.setFieldValue( "cpOrigen", '' )
      return;
    }
    
    formik.setFieldValue( "cpOrigen", origen )
    formik.setFieldValue( "ubicacionOrigen", ubicaciones.filter( item => item.direccionCP === origen ).pop().idUbicacion )
    
    const { idUbicacion } = ubicaciones.filter( item => item.direccionCP === origen ).pop()
    // console.log( aux )

    setIdOrigen( idUbicacion )
  }
  
  const GuardarGuia = async () => {
    
    if( dirAlter ){
      if( validarDirAlter() ){
        return
      }

      if( codigoPostalAlternativoValid ){
        NotificationManager.error( "Codigo postal Aternativo Invalido 6", "Oops...", 2500 )
        return;
      }

      axiosConfig({
        method: "POST",
        url: "/direccionAlternativa/addDireccionAlternativa/",
        data: {
          direccionCalle:   String( calle ),
          direccionNumero:  String( numero ),
          direccionColonia: String( colonia ),
          direccionCP:      String( cpAlternativo ),
          direccionCiudad:  String( ciudad ),
          direccionEstado:  String( estado ) 
        }
      } ).then( async ( resp ) => {
        
        await axiosConfig({
          method: 'post',
          url: `/guias?contadorGuias=${ contadorGuias }`,
          data: {
            largo:                  String( valores.largo ),
            peso:                   String( valores.peso ),
            alto:                   String( valores.alto ),
            ancho:                  String( valores.ancho ),
            ubicacionOrigen:        String( valores.ubicacionOrigen ),
            ubicacionDestino:       String( valores.ubicacionDestino ),
            idSegmento:             String( valores.idSegmento ),
            idUsuario:              Number( idUsuario ),
            nombreDestinatario:     String( valores.nombreDestinatario ) ,
            valorDeclarado:         String( valores.valorDeclarado ),
            servicio:               String( valores.servicio ),
            seguroOpcional:         valores.seguroOpcional,
            infoAdicional:          String( valores.infoAdicional ) ,
            idContenido:            String( valores.idContenido ),
            referenciaContenido:    String( valores.infoAdicional ),
            descripcion:            String( valores.descripcion ) ,
            costo:                  String( costo ), //esto esta harcodiado para hacer pruebas 
            descripcionContenido:   String( valores.descripcionContenido ),
            isCotizacion:           valores.isCotizacion,
            idSucursal:             String( valores.idSucursal ),
            isAutorizada:           autorizado,
            idDireccionAlternativa: Number( resp.idDireccionAlternativa )
          },
        } ).catch( ( err ) => {
          if (err === 'Validation/ValidationError') {
            setIsOpen(false);
            NotificationManager.error( "Ingresó mal los campos o hay problema con el internet 7", "Error", 2500 )
          }
        } );

      } ).catch( err => console.log( err ) )

    }else{

      await axiosConfig({
        method: 'post',
        url: `/guias?contadorGuias=${ contadorGuias }`,
        data: {
          largo:                String( valores.largo ),
          peso:                 String( valores.peso ),
          alto:                 String( valores.alto ),
          ancho:                String( valores.ancho ),
          ubicacionOrigen:      String( valores.ubicacionOrigen ),  //esto antes estaba con cpOrigen, pero se cambio por un error de llave foranea ( prueba )
          ubicacionDestino:     String( valores.ubicacionDestino ), // esto antes estaba con cpDestino, pero se cambio por un error de llave foranea ( prueba )
          idSegmento:           String( valores.idSegmento ),
          idUsuario:            Number( idUsuario ),
          nombreDestinatario:   String( valores.nombreDestinatario ) ,
          valorDeclarado:       String( valores.valorDeclarado ),
          servicio:             String( valores.servicio ),
          seguroOpcional:       valores.seguroOpcional,
          infoAdicional:        String( valores.infoAdicional ) ,
          idContenido:          String( valores.idContenido ),
          referenciaContenido:  String( valores.infoAdicional ),
          descripcion:          String( valores.descripcion ) ,
          costo:                String( costo ), //esto esta harcodiado para hacer pruebas 
          descripcionContenido: String( valores.descripcionContenido ),
          isCotizacion:         valores.isCotizacion,
          idSucursal:           String( valores.idSucursal ),
          isAutorizada:         autorizado,
        },
      } ).catch( ( err ) => {
        if (err === 'Validation/ValidationError') {
          setIsOpen(false);
          // aqui
          NotificationManager.error( "Ingresó mal los campos o hay problema con el internet 8", "Error", 2500 )
        }
      } );

    }

    toggleModal();
    
    NotificationManager.success( "Exito!!", "Guia Guardada ", 2500 )
    
    history.push( `/inicio/guias` );
  };
  
  const formik = useFormik({
    initialValues: {
      largo: '',
      peso: '',
      alto: '',
      ancho: '',
      ubicacionOrigen: '',
      ubicacionDestino: '',
      cpOrigen: '',
      cpDestino: '',
      idSegmento: 0,
      idSucursal: 0,
      idUsuario: 0,
      usuarioo: '',
      nombreDestinatario: '',
      valorDeclarado: '',
      servicio: '',
      seguroOpcional: true,
      infoAdicional: '',
      idContenido: 0,
      referenciaContenido: '',
      descripcion: '',
      costo: 0,
      descripcionContenido: '',
      isCotizacion: true,
      contadorGuias: 1
    },
    onSubmit: (values) => {
      if( validar( { ...values, idUsuario } )  ){
        return;
      }

      setValores( values );
      postCotizar( values );
    },
    validate: (values) => {
      let errors = {};
      if (values.alto === '' ) { errors.alto = 'El alto es requerido' }
      if (values.ancho === "" ) { errors.ancho ='El ancho es requerido' }
      //if (values.costo === 0 ) { errors.costo ='El costo es requerido' }
      if (values.largo === "" ) { errors.largo ='El largo es requerido' }
      if (values.peso === "" ) { errors.peso ='El peso es requerido' }
      if (values.cpOrigen === "" ) { errors.cpOrigen ='La ubicacion origen es requerida' }
      if (values.cpDestino === "" ) { errors.cpDestino ='La ubicacion destino es requerida' }
      if (values.descripcion === "" ) { errors.descripcion ='La descripcion es requerida' }
      if ( values.descripcionContenido === "" ) { errors.descripcionContenido ='La descripcion de contenido es requerida' }
      if ( values.idContenido === 0 ) { errors.idContenido ='El contenido es requerido' }
      if (values.idSegmento === 0 || values.idSegmento === '' ) { errors.idSegmento ='El segmento es requerido' }
      if (values.idSucursal === 0 || values.idSucursal === '') { errors.idSucursal ='La sucursal es requerida' }
      //if ( values.idUsuario === 0 ) { errors.idUsuario ='El usuario es requerido' }
      if (values.infoAdicional === "" ) { errors.infoAdicional ='La informacion adicional es requerida' }
      if (values.nombreDestinatario === "" ) { errors.nombreDestinatario ='El destinatario es requerido' }
      if ( values.referenciaContenido === "" ) { errors.referenciaContenido ='La referencia es requerida' }
      if (values.servicio === "" ) { errors.servicio ='El servicio es requerido' }
      if (values.usuarioo === "" ) { errors.usuarioo ='El usuario de origen es requerido' }
      if (String( values.valorDeclarado ) === "" ) { errors.valorDeclarado ='El valor declarado es requerido' }
      // console.log(errors)
      return errors;
    }
  });

  const isObjEmpty = (obj) => {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
  
    return true;
  }

  const getSegmentos = async () => {
    const { rows } = await axiosConfig({
      method: 'get',
      url: `segmentos`,
      params: {
        limit: 1000,
        page: 1,
      },
    });
    if( rows.length > 0 ){
      setSegmentos( rows );
    }
  };

  const getContenido = async () => {
    const { rows } = await axiosConfig({
      method: 'get',
      url: `contenidos`,
      params: {
        limit: 1000,
        page: 1,
      },
    });
    if( rows.length > 0 ){
      setContenido(rows);
    }
  };

  const getSucursales = async () => {
    const { rows } = await axiosConfig({
      method: 'get',
      url: `sucursales`,
      params: {
        limit: 1000,
        page: 1,
      },
    });
    if( rows.length > 0 ){
      setSucursales( rows );
    }
  };

  const getUsuarios = async () => {
    const { rows } = await axiosConfig({
      method: 'get',
      url: `usuarios`,
      params: {
        limit: 1000,
        page: 1,
      },
    } );
    if (rows) {
      setUsuarios(rows);
    }
  };

  const getUbicaciones = async () => {
    const { rows } = await axiosConfig({
      method: 'get',
      url: `ubicaciones`,
      params: {
        limit: 1000,
        page: 1,
      },
    });
    if (rows) {
      setUbicaciones(rows);
      setUbicacionesDestino( rows );
    }
  };

  const validar = ( values ) => {

    if( values.alto === '' || values.ancho === "" || values.largo === "" || values.peso === "" || values.cpOrigen === "" || values.cpDestino === "" || 
        values.descripcion === "" || values.descripcionContenido === "" || values.idContenido === 0 || values.idSegmento === 0 ||
        values.idSucursal === 0 || values.idUsuario === 0 || values.infoAdicional === "" || values.nombreDestinatario === "" ||
        values.referenciaContenido === "" || values.servicio === "" || values.usuarioo === "" || String( values.valorDeclarado ) === "" ){
        NotificationManager.warning( "Favor de llenar los campos", "Cuidado", 3000  )
        return true
    }
    
    return false
  }

  const validarDirAlter = () => {
    if( calle === '' || numero === "" || colonia === "" || cpAlternativo === "" || ciudad === "" || estado === "" ){
      NotificationManager.warning( "Favor de llenar los campos", "Cuidado", 3000  )
      return true
    } 

    return false;
  }

  const handleCpDestino = async ( { target } ) => {
    const destino = target.value
    
    if( destino === "-" ){
      formik.setFieldValue( "cpDestino", '' )
      return;
    }
    
    const { email } = jwt.decode( getToken() )
    const user_id = usuarios.filter( item => item.email === email )[0].user_id
    const { user:{ Usuario_asignacion_sucursal } } = await axiosConfig({
      method: "GET",
      url: `/usuarios/${ user_id }`
    } );
    
    const aux = ubicaciones.filter( item => item.direccionCP === destino )[0]
    // const aux = ubicaciones.filter( item => item.idUbicacion === parseInt( idUbicacion ) )[0]
    const aux2 = Usuario_asignacion_sucursal.find( item => item.idSucursal === aux.idSucursal )
    setAutorizado( !!aux2 )

    const { idUbicacion } = ubicaciones.filter( item => item.direccionCP === destino ).pop()
    setIdDestino( idUbicacion )
    formik.setFieldValue( "cpDestino", destino )
    formik.setFieldValue( "ubicacionDestino", ubicaciones.filter( item => item.direccionCP === destino ).pop().idUbicacion )

    const data = await axiosConfig( {
      method: 'POST',
      url: '/guias/validarCobertura/',
      data: {
        cpDestino: String( destino ) 
      }
    } );

    if( data ){
      setDirAlter( data.resultado !== 'Codigo postal destino existente' )
      setFormDirAlter( dirAlterInitial )
    }
  }
  
  useEffect( () => {
    getEstados();
    getSegmentos();
    getContenido();
    getSucursales();
    getUsuarios();
    getUbicacionOrig();
    getUbicaciones();
    formik.setFieldValue( "usuarioo", nombre.fullname )
  }, [] );

  useEffect( () => {
    const arreglo = []
    
    
    if( idOrigen !== ""  && formik.values.cpOrigen !== "" ) {
      let aux = ubicaciones.filter( item => item.direccionCP === String( formik.values.cpOrigen ) )[0]
      let aux2 = sucursales.filter( item => item.idSucursal === aux.idSucursal )[0]
      
      if( aux2 ){
        arreglo.push( aux2 )
      }
      
      setAuxSucursal( arreglo.filter( ( item, index ) => arreglo.indexOf( item ) === index ) )
    }

    if( idDestino !== "" && formik.values.cpDestino !== "" ){
      let aux = ubicaciones.filter( item => item.direccionCP === String( formik.values.cpDestino ) )[0]
      let aux2 = sucursales.filter( item => item.idSucursal === aux.idSucursal )[0]
      
      if( aux2 ){
        arreglo.push( aux2 )
      }
      
      setAuxSucursal( arreglo.filter( ( item, index ) => arreglo.indexOf( item ) === index ) ) 
    }
  
  }, [ formik.values.cpOrigen , formik.values.cpDestino ] )
  
  return (
    <div className='pl-6 py-2 pb-14'>
      <div className=' w-11/12 pb-3'>
        <div className='titulo  text-3xl'>Cotizar Guías</div>
      </div>
      <div className='Login w-11/12 bg-white shadow rounded'>
        <div className='rojodiv w-full h-6 rounded-t'></div>
        <form onSubmit={formik.handleSubmit}>
          <div className='tablamenu p-8'>
            <div className='flex flex-row'>
              <div className='w-1/4'>
                
              </div>
              <div className='w-1/4'>
                <div className=' text-lg font-black'> Usuario de origen</div>
                <input
                  disabled="disabled"
                  type='text'
                  id='usuarioo'
                  name='usuarioo'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.usuarioo}
                  className='w-11/12  border border-gray-300 rounded	 pt-1 '
                  placeholder='Ingrese nombre de usuario'
                />
                { formik.touched.usuarioo && formik.errors.usuarioo ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.usuarioo }</div> : null}
              </div>
              
              <div className='w-1/4'>
                <div className=' text-lg font-black'>
                  Ubicación de origen
                </div>
                <select
                  id='cpOrigen'
                  name='cpOrigen'
                  onChange={handleChangeOrigen}
                  onBlur={formik.handleBlur}
                  value={formik.values.cpOrigen}
                  className='w-11/12  border border-gray-300 rounded	 pt-1 '
                >
                  <option value="" >-</option>
                  {  
                    ubicacionOrig.map( ( u, index ) => (
                      <option key={ index } value={ u.direccionCP }> { u.nombreUbicacion } </option>
                    ) )
                  }
                </select>
                { formik.touched.cpOrigen && formik.errors.cpOrigen ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.cpOrigen }</div> : null}
                    { ubicacionOrig.length===0 ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    Este usuario no tiene ubiacion valida</div> : null}
              </div>
              
              <div className='w-1/4'>
                <div className=' text-lg font-black'>
                  Nombre de destinatario
                </div>
                <input
                  type='text'
                  id='nombreDestinatario'
                  name='nombreDestinatario'
                  onBlur={formik.handleBlur}
                  onChange={ formik.handleChange }
                  placeholder="Ingrese nombre del destinatario"
                  value={formik.values.nombreDestinatario}
                  className='w-11/12  border border-gray-300 rounded	 pt-1'
                />
                { formik.touched.nombreDestinatario && formik.errors.nombreDestinatario ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.nombreDestinatario }</div> : null}
              </div>
            </div>
            <div className='p-2'></div>
            <div className='flex flex-row'>
              <div className='w-1/4'>
                <div className=' text-lg font-black'>
                  Ubicación destino
                </div>
                <select
                    id='cpDestino'
                    name="cpDestino"
                    onChange={ handleCpDestino }
                    onBlur={formik.handleBlur}
                    value={formik.values.cpDestino}
                    className='w-11/12  border border-gray-300 rounded-sm '
                  >
                    <option value="-" >-</option>
                    { ubicacionesDestino.map((ubicacion) => (
                      <option  key={ubicacion.idUbicacion} value={ubicacion.direccionCP} >
                        { ubicacion.nombreUbicacion } - { ubicacion.Sucursal.nombre }
                      </option>
                    ) ) }
                  </select>
                  { formik.touched.cpDestino && formik.errors.cpDestino ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.cpDestino }</div> : null}
              </div>
              
              <div className='w-1/4'>
                <div className=' text-lg font-black'> Valor declarado </div>
                <input
                  min={ 0.1 }
                  step="0.01"
                  type='number'
                  id='valorDeclarado'
                  name='valorDeclarado'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={ formik.values.valorDeclarado }
                  placeholder="Ingresar valor declarado"
                  className='w-11/12  border border-gray-300 rounded	 pt-1 '
                />
                { formik.touched.valorDeclarado && formik.errors.valorDeclarado ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.valorDeclarado }</div> : null}
              </div>

              <div className='w-1/4'>
                <div className=' text-lg font-black'> Servicio </div>
                <select
                  className='w-11/12  border border-gray-300 rounded'
                  id='servicio'
                  name='servicio'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.servicio}
                >
                  <option value="" >---</option>

                  <option value={'Terreste consumo facturacion mensual'}>
                    Terreste consumo facturacion mensual
                  </option>

                  <option value={'Servicio LTL'}>Servicio LTL</option>

                  <option value={'Dia siguiente consumo fecturacion mensual'}>
                    Dia siguiente consumo fecturacion mensual
                  </option>

                  <option value={'11:30 consumo facturacion mensual'}>
                    11:30 consumo facturacion mensual
                  </option>
                </select>
                { formik.touched.servicio && formik.errors.servicio ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.servicio }</div> : null}
              </div>

              <div className='w-1/4 flex flex-row pt-6'>
                <div className='p-1 px-4'>
                  <input
                    type='checkbox'
                    id='seguroOpcional'
                    name='seguroOpcional'
                    onChange={formik.handleChange}
                    value={formik.values.seguroOpcional}
                  />
                  
                </div>
                <div className=' text-lg font-black'> Seguro opcional </div>
              </div>
            </div>

            { dirAlter && 
              <div className='flex flex-row m-4'>
                
                <h1 className=' flex flex-row m-4 text-lg	font-bold text-red-600 '>
                  Su código postal no está cubierto ingrese una dirección alternativa
                </h1>
                
              </div>
            }
            { dirAlter && <div className='subtitulo mx-10 text-xl '>Dirección</div> }
            { dirAlter &&
              <div className='m-4 mx-10 flex flex-row'>
                <div className='w-1/5'>
                    <div className='font-black pb-2'>Calle</div>
                    <input
                      type='text'
                      id='calle'
                      name='calle'
                      
                      onChange={ handleChangeDirAlter }
                      value={ calle }
                      className='  border border-gray-300 rounded '
                    />
                  
                </div>

                <div className='w-1/5'>
                    <div className='font-black  pb-2'>Número</div>
                    <input
                      type='text'
                      id='numero'
                      name='numero'
                      onChange={ handleChangeDirAlter }
                      value={ numero }
                      className='  border border-gray-300 rounded '
                    />
                </div>

                <div className='w-1/5'>
                    <div className='font-black  pb-2'>Colonia</div>
                    <input
                      type='text'
                      id='colonia'
                      name='colonia'
                      onChange={ handleChangeDirAlter }
                      value={ colonia }
                      className='  border border-gray-300 rounded '
                    />
                </div>

                <div className='w-1/5'>
                    <div className='font-black  pb-2'>Código postal</div>
                    <input
                      min="1"
                      step="1"
                      type='number'
                      id='cpAlternativo'
                      name='cpAlternativo'
                      onChange={ handleChangeDirAlter }
                      value={ cpAlternativo }
                      className='  border border-gray-300 rounded '
                    />
                </div>
              </div>
            }
            {
              dirAlter &&
              <div className='mx-10 flex flex-row'>
                
                <div className='w-1/5 mr-10'>
                  <div className='font-black pb-2'>Estado</div>
                  <select
                      id='estado'
                      name='estado'
                      onChange={ handleChangeDirAlter }
                      value={ estado }
                      className=' border border-gray-300 rounded '
                  >    
                    <option value={""} >-</option>
                    { estados.map( ( e, index ) => (
                      <option key={ index } value={ e.idEstado }  >{ e.nombre }</option>
                    ) ) }
                  </select>
                </div>
              

                <div className='w-1/5 ml-4'>
                  <div className='font-black pb-2'>Ciudad</div>
                  <select
                      id='ciudad'
                      name='ciudad'
                      onChange={ handleChangeDirAlter }
                      value={ ciudad }
                      className='  border border-gray-300 rounded '
                  >
                      <option value="">-</option>
                  {
                      ciudades.map( ( c, index ) => (
                        <option key={ index } value={ c.idCiudad }> { c.nombre } </option>
                      ) )
                  }
                  </select>
                </div>

              </div>
            }
         

            <div className='flex flex-row mt-10 m-4'>
              <div className=' text-lg font-black'>
                  Centro de costos 
                  <Tooltip title="Centro de costos corresponde a la sucursal y segmento a la cual se hará el cargo del costo de la guía." placement="right" arrow>
                    <IconButton>
                      <HelpRoundedIcon/>
                    </IconButton>
                  </Tooltip>
                </div>
            </div>

            <div className='p-2'></div>

            <div className='w-full flex flex-row'>
                
                <div className='column-1 w-1/4'>
                  <div className=' text-lg font-black'> Sucursal </div>
                  <select id="idSucursal" name="idSucursal" 
                  onBlur={formik.handleBlur}
                  className='w-11/12  border border-gray-300 rounded' 
                  value={ formik.values.idSucursal } 
                  onChange={ formik.handleChange }>
                    <option value={''}>---</option>
                    {
                      auxSucursal.map( ( s, index ) => (
                        <option key={ index } value={ s.idSucursal } > { s.nombre } </option>
                      ) )
                    }
                  </select>
                  { formik.touched.idSucursal && formik.errors.idSucursal ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.idSucursal }</div> : null}
                </div>

                <div className='column-1 w-1/4'>
                  <div className=' text-lg font-black'> Segmentos </div>
                  <select id="idSegmento" name="idSegmento" className='w-11/12  border border-gray-300 rounded' onBlur={formik.handleBlur}
                  value={ formik.values.idSegmento } onChange={ formik.handleChange }>
                    <option value={''} >---</option>
                    {
                      segmentos.map( ( s, index ) => (
                        <option key={ index }  value={ s.idSegmento }> { s.nombre } </option>  
                      ) )
                    }
                  </select>
                  { formik.touched.idSegmento && formik.errors.idSegmento ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.idSegmento }</div> : null}
                </div>

            </div>
            
            <div className='p-2'></div>
            <div>
              <div className=' text-lg font-black'>Dimensiones del paquete</div>
            </div>
            <div className='p-2'></div>
            <div className='flex flex-row'>
              <div className='w-1/4'>
                <div className=' text-lg font-black'> Largo </div>
                <input
                  min="1.0"
                  type='number'
                  id='largo'
                  name='largo'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.largo}
                  className='w-11/12  border border-gray-300 rounded	 pt-1 '
                  placeholder='Ingrese la medida en CM'
                />
                { formik.touched.largo && formik.errors.largo ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.largo }</div> : null}
              </div>
              <div className='p-2'></div>

              <div className='w-1/4'>
                <div className=' text-lg font-black'> Alto </div>
                <input
                  min="1.0"
                  type='number'
                  id='alto'
                  name='alto'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.alto}
                  className='w-11/12  border border-gray-300 rounded	 pt-1 '
                  placeholder='Ingrese la medida en CM'
                />
                { formik.touched.alto && formik.errors.alto ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.alto }</div> : null}
              </div>
              <div className='p-2'></div>

              <div className='w-1/4'>
                <div className=' text-lg font-black'> Ancho </div>
                <input
                  min="1.0"
                  type='number'
                  id='ancho'
                  name='ancho'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.ancho}
                  className='w-11/12  border border-gray-300 rounded	 pt-1 '
                  placeholder='Ingrese la medida en CM'
                />
                { formik.touched.ancho && formik.errors.ancho ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.ancho }</div> : null}
              </div>
              <div className='p-2'></div>
              <div className='w-1/4'>
                <div className=' text-lg font-black'> Peso </div>
                <input
                  min="1.0"
                  type='number'
                  id='peso'
                  name='peso'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.peso}
                  className='w-11/12  border border-gray-300 rounded	 pt-1 '
                  placeholder='Ingrese el peso en KG'
                />
                { formik.touched.peso && formik.errors.peso ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.peso }</div> : null}
              </div>
            </div>
            <div className='pb-2'></div>
            <div className='text-lg font-black'>Información adicional</div>
            <div className='w-full pr-4'>
              <input
                type='text'
                id='infoAdicional'
                name='infoAdicional'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={ formik.values.infoAdicional }
                className='w-full  border border-gray-300 rounded	 pt-1 '
              />
              { formik.touched.infoAdicional && formik.errors.infoAdicional ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.infoAdicional }</div> : null}
            </div>
            <div className=' mr-4 p-4 border-b-2'></div>
            
            <div className='p-2'></div>
            
            <div className='flex flex-row'>

              <div className='w-3/6 '>
                <div className=' text-lg font-black'> Contenido </div>
                <select
                  name="idContenido"
                  id='idContenido'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.idContenido}
                  className='w-full border border-gray-300 rounded	 pt-1 '
                >
                  <option value={0}>-</option>
                  {contenido.map((contenidos) => {
                    return (
                      <option
                        key={contenidos.idContenido}
                        value={contenidos.idContenido}
                      >
                        {contenidos.descripcion}
                      </option>
                    );
                  })}
                </select>
                { formik.touched.idContenido && formik.errors.idContenido ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.idContenido }</div> : null}
              </div>
              <div className='p-2 ml-2'></div>

              <div className='w-6/12 mr-5'>
                <div className=' text-lg font-black'>
                  Descripción de contenido
                </div>
                <input
                  type='text'
                  id='descripcionContenido'
                  name='descripcionContenido'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.descripcionContenido}
                  className='w-full border border-gray-300 rounded	 pt-1 '
                />
                { formik.touched.descripcionContenido && formik.errors.descripcionContenido ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.descripcionContenido }</div> : null}
              </div>

            </div>
            
            <div className='p-4'></div>

            <div className='flex flex-row'>
              <div className='w-1/3'>
                <div className=' text-lg font-black'> Referencia </div>
                <input
                  type='text'
                  id='referenciaContenido'
                  name='referenciaContenido'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.referenciaContenido}
                  className='w-11/12  border border-gray-300 rounded	 pt-1 '
                />
                { formik.touched.referenciaContenido && formik.errors.referenciaContenido ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.referenciaContenido }</div> : null}
              </div>
              <div className='p-1'></div>

              <div className='w-1/3'>
                <div className=' text-lg font-black'> Descripción </div>
                <input
                  type='text'
                  id='descripcion'
                  name='descripcion'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.descripcion}
                  className='w-11/12  border border-gray-300 rounded	 pt-1 '
                />
                { formik.touched.descripcion && formik.errors.descripcion ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.descripcion }</div> : null}
              </div>


            </div>

          </div>
          <div className='tablamenu pl-8 flex'>
          <div className='flex mr-4 text-lg font-black'> Cantidad de guias: </div>
          <div className='inline-flex border-gray-300 border rounded'>
            <button
              type='button'
              onClick={() => {contadorGuias > 1 ? setContadroGuias(contadorGuias - 1) : setContadroGuias(1)}}
              className={`px-2 border-r border-black`}
            >
              -
            </button>
            <div className='px-3 center'>
              {contadorGuias}
            </div>
            <button
            type='button'
              onClick={() => setContadroGuias(contadorGuias + 1)}
              className={`px-2 border-l border-black`}
            >
              +
            </button>
          </div>
        </div>
            <div className='flex justify-center'>
              <button
                type='submit'
                onClick={()=> ( isObjEmpty(formik.errors) ? null : NotificationManager.warning( "Favor de llenar los campos", "Cuidado", 3000  ))}
                className='botonverde py-1 w-48 h-10 rounded-sm shadow mt-3 mb-5'
                >
                Cotizar
              </button>
            </div>
          
          <div>
            <Modal isOpen={ isOpen } contentdiv='Example Modal'>
              <div className='flex flex-row justify-between border-b-2 pb-4'>
                <div className='titulo  text-3xl'>
                  Informacion de la guía cotizada
                </div>

                <button className='rojoletra' onClick={ toggleModal } >
                  <AiOutlineCloseSquare size={28} />
                </button>
              </div>

              <form>
                <div className='flex flex-col'>
                  <div className='mt-4'>
                    <div className='titulo text-xl'>
                      Codigo postal de origen
                    </div>
                    {vdetalle.result
                      ? vdetalle.result.Respuesta.CodigoPosOri._text
                      : ''}
                    <div className='font-semibold'>Informacion de origen</div>
                    <div className='flex flex-row'>
                      <div className='mr-4'>
                        <div className='font-semibold'>Plaza</div>
                        {vdetalle.result
                          ? vdetalle.result.Respuesta.Origen.PlazaOri._text
                          : ''}
                      </div>
                      <div className='mr-4'>
                        <div className='font-semibold'>Estado</div>
                        {vdetalle.result
                          ? vdetalle.result.Respuesta.Origen.EstadoOri._text
                          : ''}
                      </div>
                      <div className='mr-4'>
                        <div className='font-semibold'>Municipio</div>
                        {vdetalle.result
                          ? vdetalle.result.Respuesta.Origen.MunicipioOri._text
                          : ''}
                      </div>
                    </div>
                    <div className='mt-4'>
                      <div className='titulo text-xl'>
                        Codigo postal de destino
                      </div>
                      {vdetalle.result
                        ? vdetalle.result.Respuesta.Destino.CpDestino._text
                        : ''}
                      <div className='font-semibold'>
                        Informacion de destino
                      </div>
                      <div className='flex flex-row'>
                        <div className='mr-4'>
                          <div className='font-semibold'>Plaza</div>
                          {vdetalle.result
                            ? vdetalle.result.Respuesta.Destino.Plaza1._text
                            : ''}
                        </div>
                        <div className='mr-4'>
                          <div className='font-semibold'>Estado</div>
                          {vdetalle.result
                            ? vdetalle.result.Respuesta.Destino.Estado._text
                            : ''}
                        </div>
                        <div className='mr-4'>
                          <div className='font-semibold'>Municipio</div>
                          {vdetalle.result
                            ? vdetalle.result.Respuesta.Destino.Municipio._text
                            : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=' border-b-2 mb-4 pb-4'></div>
                  <div className='titulo text-xl'>Modalidad de entrega</div>
                  <div className='flex flex-row mt-2'>
                    <div className='mr-4'>
                      <div className='font-semibold'>Frecuencia</div>
                      {vdetalle.result
                        ? vdetalle.result.Respuesta.ModalidadEntrega.Frecuencia
                            ._text
                        : ''}
                    </div>
                    <div className='mr-4'>
                      <div className='font-semibold'>Ocurre Forzoso</div>
                      {vdetalle.result
                        ? vdetalle.result.Respuesta.ModalidadEntrega
                            .OcurreForzoso._text
                        : ''}
                    </div>
                  </div>
                  <div className=' border-b-2 mb-4 pb-4 '></div>
                  <div className='tablemenu flex flex-row'>
                    <div className='w-1/4'>
                      <div className='font-semibold '> Servicio </div>
                      <select
                        className='w-11/12  border border-gray-300 rounded-sm '
                        id='servicioW'
                        name='servicioW'
                        onChange={ ( e ) => {
                          handleCosto(e.target.value);
                        } }
                        value={ servicioW }
                      >
                        { !!vdetalle.result
                          && vdetalle.result.Respuesta.TipoServicio.TipoServicio.map(
                              (servicio, index) => {
                                return (
                                  <option
                                    key={ index }
                                    value={servicio.DescripcionServicio._text}
                                  >
                                    {servicio.DescripcionServicio._text}
                                  </option>
                                );
                              },
                            )
                        }

                      </select>
                    </div>
                    <div className='w-1/4'>
                      <div className='font-semibold '> Costo </div>
                      <input
                        type='text'
                        disabled
                        value={costo}
                        className='w-11/12  border border-gray-300 rounded	 pt-1 '
                      />
                    </div>
                  </div>
                  <div className='titulo text-xl'>Dias de entrega</div>
                  <div className='flex flex-row mt-2 pb-2 border-2 border-slate-300 rounded-md '>
                    <div className='mr-4 w-1/6 '>
                      <div className='titulo text-lg flex flex-row  justify-center'>
                        Lunes
                      </div>
                      <div className='flex flex-row justify-center'>
                        {vdetalle.result
                          ? vdetalle.result.Respuesta.DiasEntrega.Lunes._text
                          : ''}
                      </div>
                    </div>
                    <div className='mr-4 w-1/6 '>
                      <div className='titulo text-lg flex flex-row justify-center'>
                        Martes
                      </div>
                      <div className='flex flex-row justify-center'>
                        {vdetalle.result
                          ? vdetalle.result.Respuesta.DiasEntrega.Martes._text
                          : ''}
                      </div>
                    </div>
                    <div className='mr-4 w-1/6 '>
                      <div className='titulo text-lg flex flex-row justify-center'>
                        Miercoles
                      </div>
                      <div className='flex flex-row justify-center'>
                        {vdetalle.result
                          ? vdetalle.result.Respuesta.DiasEntrega.Miercoles
                              ._text
                          : ''}
                      </div>
                    </div>
                    <div className='mr-4 w-1/6 '>
                      <div className='titulo text-lg flex flex-row justify-center'>
                        Jueves
                      </div>
                      <div className='flex flex-row justify-center'>
                        {' '}
                        {vdetalle.result
                          ? vdetalle.result.Respuesta.DiasEntrega.Jueves._text
                          : ''}
                      </div>
                    </div>
                    <div className='mr-4 w-1/6 '>
                      <div className='titulo text-lg flex flex-row justify-center'>
                        Viernes
                      </div>
                      <div className='flex flex-row justify-center'>
                        {vdetalle.result
                          ? vdetalle.result.Respuesta.DiasEntrega.Viernes._text
                          : ''}
                      </div>
                    </div>
                    <div className='mr-4 w-1/6 '>
                      <div className='titulo text-lg flex flex-row justify-center'>
                        Sabado
                      </div>
                      <div className='flex flex-row justify-center'>
                        {vdetalle.result
                          ? vdetalle.result.Respuesta.DiasEntrega.Sabado._text
                          : ''}
                      </div>
                    </div>
                  </div>
                  <table className='content-table w-full'>
                    <thead>
                      <tr>
                        { tableNames.map( ( name, index ) => {
                          return <th key={ index }>{name}</th>;
                        } ) }
                      </tr>
                    </thead>
                    <tbody>
                      { !!vdetalle.result && vdetalle.result.Respuesta.TipoServicio.TipoServicio.map( ( Drives, index ) => (
                        <tr key={ index }>
                          <td>{Drives.AplicaCotizacion._text}</td>
                          <td>{Drives.AplicaServicio._text}</td>
                          <td>{Drives.CCSobrePeso._text}</td>
                          <td>{Drives.CCTarifaBase._text}</td>
                          <td>{Drives.CargosExtra._text}</td>
                          <td>{Drives.CostoTotal._text}</td>
                          <td>{Drives.DescripcionServicio._text}</td>
                          <td>{Drives.Peso._text}</td>
                          <td>{Drives.SobrePeso._text}</td>
                          <td>{Drives.TarifaBase._text}</td>
                          <td>{Drives.TipoEnvioRes._text}</td>
                        </tr>
                      ) ) }
                    </tbody>
                  </table>
                </div>
                <div className='flex flex-row justify-center'>
                  <button
                    type='submit'
                    className='botonverde py-1 w-48 h-10 rounded-sm shadow'
                    onClick={() => {
                      GuardarGuia();
                    }}
                  >
                    Guardar guia
                  </button>
                </div>
              </form>
            </Modal>
          </div>

        </form>
      </div>
    </div>
  );
};
export default Cotizar;
