import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import { MdOutlineArrowBack } from 'react-icons/md';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';

import jwt from 'jsonwebtoken';
import { getToken } from '../../utils/authService';

import axiosConfig from '../../utils/axiosConfig';

const dirAlterInitial = {
  calle: '',
  numero: '',
  colonia: '',
  cpAlternativo: '',
  ciudad: '',
  estado: '',
}

const NuevaGuias = () => {

  const history = useHistory()  
  
  const [ ubicacionOrig, setUbicacionOrig ] = useState( [] )
  const [ idUser, setIdUser ] = useState( 0 )

  const [ segmentos, setSegmentos] = useState([]);
  const [ ubicaciones, setUbicaciones] = useState([]);
  const [ sucursales, setSucursales ] = useState( [] );
  const [ contenido, setContenido] = useState([]);
  const [ auxSucursal, setAuxSucursal ] = useState( [] )

  const [ autorizado, setAutorizado ] = useState( false );
  const [ codigoPostalAlternativoValid, setCodigoPostalAlternativoValid ] = useState( false )
  const [ contadorGuias, setContadroGuias] = useState(1);
  
  const [ dirAlter, setDirAlter ] = useState( false )
  const [ formDirAlter, setFormDirAlter ] = useState( dirAlterInitial );
  const { calle, numero, colonia, cpAlternativo, ciudad, estado } = formDirAlter

  const [ estados,  setEstados  ] = useState( [] );
  const [ ciudades, setCiudades ] = useState( [] );

  const handleChangeDirAlter = ( { target } ) => {

    if( target.name === 'estado' ){
      const idEstado = target.value
      
      if( idEstado === "" ){
        setFormDirAlter( s => ( { ...s, [target.name]: target.value } ) )
        setCiudades( [] )
        return
     }
      
      axiosConfig( {
        method: 'GET',
        url: `/ubicaciones/ciudades/${ idEstado }`,
      } ).then( ( data ) => {
        setCiudades( data.rows )
      } ).catch( err => {
        NotificationManager.error( "Ingresó mal los campos o hay problema con el internet", '¡Error!' , 3000, () => {} )
      } )

    }

    if( target.name === 'cpAlternativo' ){
      const direccionCP = target.value

      axiosConfig( {
        method: 'POST',
        url: '/guias/validarCobertura/',
        data: {
          cpDestino: String( direccionCP ) 
        }
      } ).then( data => {
        console.log( data )
        setCodigoPostalAlternativoValid( data.resultado !== 'Codigo postal destino existente' ) 
      } ).catch( err => console.log( err ) )

    }

    setFormDirAlter( s => ( { ...s, [target.name]: target.value } ) )
  }

  // const formikAlt = useFormik({
  //   initialValues:  {
  //       calle: '',
  //       numero: '',
  //       colonia: '',
  //       cpAlternativo: '',
  //       ciudad: '',
  //       estado: '',
  //   },
  //   onSubmit: values => {
  //     console.log(values);
  //   },
  //   validate: values => {
  //     let errors = {};
  //     if (values.calle === '' ) { errors.calle = 'La calle es requerida' }
  //     if (values.numero === '' ) { errors.numero ='El numero es requerido' }
  //     if (values.colonia === '' ) { errors.colonia ='La colonia es requerida' }
  //     if (values.cpAlternativo === '' ) { errors.cpAlternativo ='El codigo postal es requerido' }
  //     if (values.ciudad === '' ) { errors.ciudad ='La ciudad es requerida' }
  //     if (values.estado === '' ) { errors.estado ='El estado es requerido' }
  //     //console.log(errors)
  //     return errors;
  //   }
  // })

  const getEstados = async () => {
    const data = await axiosConfig( {
       method: 'GET',
       url: `/ubicaciones/estados`,
    } );

    if( !!data.rows ){
       setEstados( data.rows )
    }else{
       NotificationManager.error( "Ingresó mal los campos o hay problema con el internet", '¡Error!' , 3000, () => {} )
    }
  };

  const getSucursales = async () => {
    const { rows } = await axiosConfig({
      method: 'get',
      url: `sucursales`,
      params: {
        limit: 1000,
        page: 1,
      },
    } );
    
    if( !!rows && rows.length > 0 ) {  
      setSucursales( rows );
    }

  };  

  const getUbicacionOrig = async () => {
    const data = await axiosConfig( {
       method: "GET",
       url: 'usuarios'
    } );
    
    if( data.count > 0 ){
      const token = getToken();
      const user  = jwt.decode( token );
      const aux   = data.rows.filter( item => item.email === user.email )[0]
      
      setIdUser( aux.user_id )
      const resp = await axiosConfig( {
        method: "GET",
        url: `usuarios/${ aux.user_id }`
      } );

      if( !!resp.user ){

        const ubicaciones = await axiosConfig( {
          method: "GET",
          url: `ubicaciones`
        } );

        if( !!ubicaciones.rows ){
          // console.log( resp.user["Sucursal.idSucursal"] )
          
          // let ubicacionAux = []
          // let itemAux = {}
          // const { Usuario_asignacion_sucursal } = resp.user

          // if( !!Usuario_asignacion_sucursal ){
            
          //   for (let i = 0; i < Usuario_asignacion_sucursal.length; i++) {
          //       for( let j = 0; j < ubicaciones.rows.length; j++ ) {
          //           if( Usuario_asignacion_sucursal[i].Sucursal.nombre === ubicaciones.rows[j].Sucursal.nombre ){
          //               itemAux = { idUbicacion: ubicaciones.rows[j].idUbicacion, nombreUbicacion: `${ ubicaciones.rows[j].nombreUbicacion} - ${ ubicaciones.rows[j].Sucursal.nombre }`  }
          //               ubicacionAux.push( itemAux )
          //             }
          //           }
          //         }
          // }
          // console.log( ubicaciones.rows.filter( item => item.idSucursal === parseInt( resp.user["Sucursal.idSucursal"] ) ) )

          const arregloAux = ubicaciones.rows.filter( item => item.idSucursal === parseInt( resp.user["Sucursal.idSucursal"] ) )
          setUbicacionOrig( arregloAux );
          
          if( arregloAux.length === 0 ){
            NotificationManager.error( "No hay ubicaciones para asignarle a este usuario", "", 3000 )
          }
        } else {
          NotificationManager.error( "hay problema con Consulta ubicaciones", "Oops..", 2500 )
        }
      } else {
        NotificationManager.error( "hay problema con la consulta de ubicacion-user", "Oops..", 2500 )
      }
    } else {
      NotificationManager.error( "hay problema con la consulta de Usuarios", "Oops..", 2500 )
    }
  };

  const getSegmentos = async () => {
    const { rows } = await axiosConfig({
      method: 'get',
      url: `segmentos`,
      params: {
        limit: 1000,
        page: 1,
      },
    } );

    if( !!rows && rows.length > 0) {
      setSegmentos( rows );
    }
  
  };

  const getUbicaciones = async () => {
    const { count, rows } = await axiosConfig({
      method: 'get',
      url: `ubicaciones`,
      params: {
        limit: 1000,
        page: 1,
      },
    } );

    if( !!count && count > 0) {
      setUbicaciones(rows);
    }
    
  };

  const getContenido = async () => {
    const { count, rows } = await axiosConfig({
      method: 'get',
      url: `contenidos`,
      params: {
        limit: 1000,
        page: 1,
      },
    } );
    
    if( !!count && count > 0) {
      setContenido(rows);
    }
  
  };

  const handleCpDestino = async ( { target } ) => {
    const destino = target.value
    
    if( destino === "" ){
      formik.setFieldValue( "ubicacionDestino", "" )
      return;
    }
    
    const { user } = await axiosConfig({
      method: "GET",
      url: `/usuarios/${ idUser }`
    } );

    const aux = ubicaciones.filter( item => item.idUbicacion === parseInt( destino ) )[0]
    const aux2 = user.Usuario_asignacion_sucursal.find( item => item.idSucursal === aux.idSucursal )
    setAutorizado( !!aux2 )

    const { direccionCP }  = ubicaciones.filter( item => String(item.idUbicacion) === String(destino) )[0]
    
    formik.setFieldValue( "ubicacionDestino", destino )
    
    const data = await axiosConfig( {
      method: 'POST',
      url: '/guias/validarCobertura/',
      data: {
        cpDestino: String( direccionCP ) 
      }
    } );

    if( data ){
      setDirAlter( data.resultado !== 'Codigo postal destino existente' )
      setFormDirAlter( dirAlterInitial )
    }
  };

  const formik = useFormik({
    initialValues: {
      largo: '',
      peso: '',
      alto: '',
      ancho: '',
      ubicacionOrigen: '',
      ubicacionDestino: '',
      idSegmento: 0,
      idSucursal: 0,
      usuarioo: '',
      idUsuario: 0,
      nombreDestinatario: '',
      valorDeclarado: '',
      servicio: '',
      seguroOpcional: true,
      infoAdicional: '',
      idContenido: 0,
      referenciaContenido: '',
      descripcion: '',
      costo: 0,
      descripcionContenido: '',
      isCotizacion: true,
      contadorGuias: 1,
      isAutorizada: false
    },
    onSubmit: (values) => {

      if( validar( { ...values, idUsuario: idUser} )  ){
        return;
      }
        
      const datos = {
        largo:                  String( values.largo ),
        peso:                   String( values.peso ),
        alto:                   String( values.alto ),
        ancho:                  String( values.ancho ),
        ubicacionOrigen:        String( values.ubicacionOrigen ),
        idSegmento:             String( values.idSegmento ),
        idUsuario:              Number( idUser ),
        nombreDestinatario:     String( values.nombreDestinatario ) ,
        valorDeclarado:         String( values.valorDeclarado ),
        servicio:               String( values.servicio ),
        seguroOpcional:         values.seguroOpcional,
        infoAdicional:          String( values.infoAdicional ) ,
        idContenido:            String( values.idContenido ),
        referenciaContenido:    String( values.infoAdicional ),
        descripcion:            String( values.descripcion ) ,
        costo:                  String( values.costo ),
        descripcionContenido:   String( values.descripcionContenido ),
        isCotizacion:           values.isCotizacion,
        idSucursal:             String( values.idSucursal ),
        isAutorizada:           autorizado
      }
      
      if( dirAlter ){
        if( validarDirAlter( { calle, numero, colonia, cpAlternativo, ciudad, estado } ) ){
          return;
        }
        if( codigoPostalAlternativoValid ){
          NotificationManager.error( "Codigo postal Aternativo Invalido", "Oops...", 2500 )
          return;
        }
        
        axiosConfig({
          method: "POST",
          url: "/direccionAlternativa/addDireccionAlternativa/",
          data: {
            direccionCalle:   String( formDirAlter.calle ),
            direccionNumero:  String( formDirAlter.numero ),
            direccionColonia: String( formDirAlter.colonia ),
            direccionCP:      String( formDirAlter.cpAlternativo ),
            direccionCiudad:  String( formDirAlter.ciudad ),
            direccionEstado:  String( formDirAlter.estado ) 
          }
        } ).then( ( resp ) => {

          axiosConfig({
            method: 'POST',
            url: `/guias?contadorGuias=${ contadorGuias }`,
            data: {
              ...datos,
              idDireccionAlternativa: Number( resp.idDireccionAlternativa )
            },
          } ).then( () => {
            // console.log( resp )
            NotificationManager.success( "Nueva guia creada", "Guardado", 2500 )
            history.push( `/inicio/guias` )
          }).catch((err) => {
            console.log( err )
            if (err === 'Validation/ValidationError') {
              NotificationManager.error( "Ingresó mal los campos o hay problema con el internet", "Oops...", 2500 )
            }
          });

        } ).catch( err => console.error( err ) )
        
      }else{

        axiosConfig({
          method: 'POST',
          url: `/guias?contadorGuias=${ contadorGuias }`,
          data: {
            ubicacionDestino: String( values.ubicacionDestino ),
            ...datos
          },
        } ).then( () => {
          // console.log( resp )
          NotificationManager.success( "Nueva guia creada", "Guardado", 2500 )
          history.push( `/inicio/guias` )
        }).catch((err) => {
          console.log( err )
          if (err === 'Validation/ValidationError') {
            NotificationManager.error( "Ingresó mal los campos o hay problema con el internet", "Oops...", 2500 )
          }
        });

      }
    },
    validate: (values) => {
      let errors = {};
      if (values.alto === '' ) { errors.alto = 'El alto es requerido' }
      if (values.ancho === "" ) { errors.ancho ='El ancho es requerido' }
      //if (values.costo === 0 ) { errors.costo ='El costo es requerido' }
      if (values.largo === "" ) { errors.largo ='El largo es requerido' }
      if (values.peso === "" ) { errors.peso ='El peso es requerido' }
      if (values.ubicacionOrigen === "" ) { errors.ubicacionOrigen ='La ubicacion origen es requerida' }
      if (values.ubicacionDestino === "" ) { errors.ubicacionDestino ='La ubicacion destino es requerida' }
      if (values.descripcion === "" ) { errors.descripcion ='La descripcion es requerida' }
      if ( values.descripcionContenido === "" ) { errors.descripcionContenido ='La descripcion de contenido es requerida' }
      if ( values.idContenido === 0 ) { errors.idContenido ='El contenido es requerido' }
      if (values.idSegmento === '' || values.idSegmento === 0) { errors.idSegmento ='El segmento es requerido' }
      if (values.idSucursal === '' || values.idSucursal === 0) { errors.idSucursal ='La sucursal es requerida' }
      if (values.infoAdicional === "" ) { errors.infoAdicional ='La informacion adicional es requerida' }
      if (values.nombreDestinatario === "" ) { errors.nombreDestinatario ='El destinatario es requerido' }
      if ( values.referenciaContenido === "" ) { errors.referenciaContenido ='La referencia es requerida' }
      if (values.servicio === "" ) { errors.servicio ='El servicio es requerido' }
      if (values.usuarioo === "" ) { errors.usuarioo ='El usuario de origen es requerido' }
      if (String( values.valorDeclarado ) === "" ) { errors.valorDeclarado ='El valor declarado es requerido' }
      return errors;
    }
  } );

  const isObjEmpty = (obj) => {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
  
    return true;
  }
  
  const putUsuarioOrigen = () => {
    const token = getToken();
    const user  = jwt.decode( token );
    formik.setFieldValue( 'usuarioo', user.fullname )
  }

  useEffect( () => {
    const arreglo = []
    
    if( formik.values.ubicacionDestino !== "" ) {
      let aux = ubicaciones.filter( item => item.idUbicacion === Number( formik.values.ubicacionDestino ) )[0]
      let aux2 = sucursales.filter( item => item.idSucursal === aux.idSucursal )[0]
      
      if( aux2 ){
        arreglo.push( aux2 )
      }
      
      setAuxSucursal( arreglo.filter( ( item, index ) => arreglo.indexOf( item ) === index ) )
    }

    if( formik.values.ubicacionOrigen !== "" ){
      let aux = ubicaciones.filter( item => item.idUbicacion === Number( formik.values.ubicacionOrigen ) )[0]
      let aux2 = sucursales.filter( item => item.idSucursal === aux.idSucursal )[0]
      
      if( aux2 ){
        arreglo.push( aux2 )
      }
      
      setAuxSucursal( arreglo.filter( ( item, index ) => arreglo.indexOf( item ) === index ) ) 
    }

  }, [formik.values.ubicacionOrigen,  formik.values.ubicacionDestino ] )


  useEffect(() => {
    getUbicacionOrig();
    getSegmentos();
    getUbicaciones();
    getContenido();
    getSucursales();
    getEstados();
    putUsuarioOrigen();
  }, [] );
  
  const validar = ( values ) => {
    
    if( values.alto === '' || values.ancho === "" || 
        values.largo === "" || values.peso === "" || values.ubicacionOrigen === "" || values.ubicacionDestino === "" || 
        values.descripcion === "" || values.descripcionContenido === "" || values.idContenido === 0 || values.idSegmento === '' ||
        values.idSucursal === '' || values.idUsuario === 0 || values.infoAdicional === "" || values.nombreDestinatario === "" ||
        values.referenciaContenido === "" || values.servicio === "" || values.usuarioo === "" || String( values.valorDeclarado ) === "" ){
        //NotificationManager.warning( "Favor de llenar los campos", "Cuidado", 3000  )
        return true
    }
    
    return false
  }

  const validarDirAlter = ( values ) => {
    if( values.calle === "" || numero === "" || colonia === "" || 
    cpAlternativo === "" || ciudad === "" || estado === "" ){
      NotificationManager.warning( "Favor de llenar los campos faltantes en la direccion alternativa", "Cuidado", 3000 );  
      return true
    }

    return false;
  }

  return (
    <div className='pt-2 mb-10'>
      <div className='Login w-full bg-white shadow rounded'>
        <div className='flex flex-row p-3 border-b-2'>
          <div>
            <Link to='/inicio/guias'>
              <div className=' px-2'>
                <MdOutlineArrowBack size='24' />
              </div>
            </Link>
          </div>
          <div className=' text-xl titulo'>Crear nueva guía</div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className='tablamenu p-8'>
            <div className='flex flex-row'>
              {/* ////////////////////////////////////////////////////////// */}
              <div className='w-1/4 mr-4'></div>
              <div className='w-1/4 ml-4'>
                <div className=' text-lg font-black'> Usuario de origen</div>
                <input
                  disabled="disabled"
                  type='text'
                  id='usuarioo'
                  name='usuarioo'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.usuarioo}
                  className='w-11/12  border border-gray-300 rounded	 pt-1 '
                  placeholder='Ingrese nombre de usuario'
                />
                { formik.touched.usuarioo && formik.errors.usuarioo ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.usuarioo }</div> : null}
              </div>

              <div className='w-1/4 ml-4'>
                <div className=' text-lg font-black'>
                  Ubicación origen
                </div>
                <select
                    name={`ubicacionOrigen`}
                    id='ubicacionOrigen'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.ubicacionOrigen}
                    className='w-11/12  border border-gray-300 rounded-sm '
                  >
                    <option value="" >-</option>
                    { ubicacionOrig.map( ( ubicacion, index ) => {
                      return (
                        <option key={ index } value={ ubicacion.idUbicacion } >
                          { ubicacion.nombreUbicacion }
                        </option>
                      );
                    }) }
                  </select>
                  { formik.touched.ubicacionOrigen && formik.errors.ubicacionOrigen ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.ubicacionOrigen }</div> : null}
                  { ubicacionOrig.length===0 ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    Este usuario no tiene ubiacion valida</div> : null}
              </div>

              <div className='w-1/4 ml-4'>
                <div className=' text-lg font-black'>
                  Nombre de destinatario
                </div>
                <input
                  type='text'
                  id='nombreDestinatario'
                  name='nombreDestinatario'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nombreDestinatario}
                  className='w-11/12  border border-gray-300 rounded pt-1 '
                  placeholder='Ingrese nombre del destinatario'
                />
                { formik.touched.nombreDestinatario && formik.errors.nombreDestinatario ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.nombreDestinatario }</div> : null}
              </div>
            </div>

            <div className='flex flex-row mt-4'>
              <div className='w-1/4 ml-4'>
                <div className=' text-lg font-black'>
                  Ubicación destino
                </div>
                <select
                    name={`ubicacionDestino`}
                    id='ubicacionDestino'
                    onChange={ handleCpDestino }
                    onBlur={formik.handleBlur}
                    value={formik.values.ubicacionDestino}
                    className='w-11/12  border border-gray-300 rounded-sm '
                  >
                    <option value="">-</option>
                    { ubicaciones.map( ( ubicacion ) => {
                      return (
                        <option
                          id={ ubicacion.direccionCp }
                          key={ubicacion.idUbicacion}
                          value={ ubicacion.idUbicacion }
                        >
                          {ubicacion.nombreUbicacion} - { ubicacion.Sucursal.nombre }
                        </option>
                      )
                    } ) }
                  </select>
                  { formik.touched.ubicacionDestino && formik.errors.ubicacionDestino ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.ubicacionDestino }</div> : null}
              </div>

              <div className='w-1/4 ml-4'>
                <div className=' text-lg font-black'> Valor declarado </div>
                <input
                  min={ 0.1 }
                  step="0.01"
                  type='number'
                  id='valorDeclarado'
                  name='valorDeclarado'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={ formik.values.valorDeclarado }
                  placeholder="Ingresar valor declarado"
                  className='w-11/12  border border-gray-300 rounded	 pt-1 '
                />
                { formik.touched.valorDeclarado && formik.errors.valorDeclarado ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.valorDeclarado }</div> : null}
              </div>

              <div className='w-1/4 ml-4'>
                <div className=' text-lg font-black'> Servicio </div>
                <select
                  className='w-11/12  border border-gray-300 rounded-sm '
                  type='text'
                  id='servicio'
                  name='servicio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.servicio}
                >
                  <option value={''}>---</option>

                  <option value={'Terreste consumo facturacion mensual'}>
                    Terreste consumo facturacion mensual
                  </option>

                  <option value={'Servicio LTL'}>Servicio LTL</option>

                  <option value={'Dia siguiente consumo fecturacion mensual'}>
                    Dia siguiente consumo fecturacion mensual
                  </option>

                  <option value={'11:30 consumo facturacion mensual'}>
                    11:30 consumo facturacion mensual
                  </option>
                </select>
                { formik.touched.servicio && formik.errors.servicio ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.servicio }</div> : null}
              </div>

              <div className='w-1/4 ml-4 flex flex-row pt-6'>
                <div className='p-1 px-4'>
                  <input
                    type='checkbox'
                    id='seguroOpcional'
                    name='seguroOpcional'
                    onChange={formik.handleChange}
                    value={formik.values.seguroOpcional}
                  />
                </div>
                <div className=' text-lg font-black'> Seguro opcional </div>
              </div>
            </div>
            
            { dirAlter && 
              <div className='flex flex-row m-4'>
                
                <h1 className=' flex flex-row m-4 text-lg	font-bold text-red-600 '>
                  Su código postal no está cubierto ingrese una dirección alternativa
                </h1>
                
              </div>
            }
            { dirAlter && <div className='subtitulo mx-10 text-xl '>Dirección</div> }
            { dirAlter &&
              <div className='m-4 mx-10 flex flex-row'>
                <div className='w-1/5'>
                    <div className='font-black pb-2'>Calle</div>
                    <input
                      type='text'
                      id='calle'
                      name='calle'
                      onChange={ handleChangeDirAlter }
                      value={ calle }
                      className='  border border-gray-300 rounded '
                    />
                  
                </div>

                <div className='w-1/5'>
                    <div className='font-black  pb-2'>Número</div>
                    <input
                      type='text'
                      id='numero'
                      name='numero'
                      onChange={ handleChangeDirAlter }
                      value={ numero }
                      className='  border border-gray-300 rounded '
                    />
                </div>

                <div className='w-1/5'>
                    <div className='font-black  pb-2'>Colonia</div>
                    <input
                      type='text'
                      id='colonia'
                      name='colonia'
                      onChange={ handleChangeDirAlter }
                      value={ colonia }
                      className='  border border-gray-300 rounded '
                    />
                </div>

                <div className='w-1/5'>
                    <div className='font-black  pb-2'>Código postal</div>
                    <input
                      step="1"
                      min="1"
                      type='number'
                      id='cpAlternativo'
                      name='cpAlternativo'
                      onChange={ handleChangeDirAlter }
                      value={ cpAlternativo }
                      className='  border border-gray-300 rounded '
                    />
                </div>
                
              </div>
            }
            {
              dirAlter &&
              <div className='mx-10 p-0 flex flex-row'>

                <div className='w-1/5 mr-10'>
                  <div className='font-black pb-2'>Estado</div>
                  <select
                      id='estado'
                      name='estado'
                      onChange={ handleChangeDirAlter }
                      value={ estado }
                      className=' border border-gray-300 rounded '
                  >    
                    <option value={""} >-</option>
                    { estados.map( ( e, index ) => (
                      <option key={ index } value={ e.idEstado }  >{ e.nombre }</option>
                    ) ) }
                  </select>
                </div>
              
                <div className='w-1/5 ml-4'>
                  <div className='font-black pb-2'>Ciudad</div>
                  <select
                      id='ciudad'
                      name='ciudad'
                      onChange={ handleChangeDirAlter }
                      value={ ciudad }
                      className='  border border-gray-300 rounded '
                  >
                      <option value="">-</option>
                  {
                      ciudades.map( ( c, index ) => (
                        <option key={ index } value={ c.idCiudad }> { c.nombre } </option>
                      ) )
                  }
                  </select>
                </div>

              </div>
            }

            <div className='flex flex-row m-4 mt-10'>
              <div>
                <div className=' text-lg font-black'>
                  Centro de costos 
                  <Tooltip title="Centro de costos corresponde a la sucursal y segmento a la cual se hará el cargo del costo de la guía." placement="right" arrow>
                    <IconButton>
                      <HelpRoundedIcon/>
                    </IconButton>
                  </Tooltip>
                  </div>
                  
              </div>
            </div>
            
            <div className='flex flex-row'>
                <div className='w-1/4 ml-4'>
                    <div className=' text-lg font-black'> Sucursal </div>
                    <select
                      name={`idSucursal`}
                      id='idSucursal'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.idSucursal}
                      className='w-11/12  border border-gray-300 rounded-sm '
                    >
                      <option value={''}>-</option>
                          {
                            auxSucursal.map( ( s, index ) => ( 
                              <option key={ index }  value={ s.idSucursal }> { s.nombre } </option>
                            ) )
                          }
                    </select>
                    { formik.touched.idSucursal && formik.errors.idSucursal ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.idSucursal }</div> : null}
                </div>
              <div className='w-1/4 ml-4'>
                  <div className=' text-lg font-black'> Segmentos </div>
                  <select
                    name={`idSegmento`}
                    id='idSegmento'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.idSegmento}
                    className='w-11/12  border border-gray-300 rounded-sm '
                  >
                    <option value={''}>-</option>
                    {segmentos.map((segmento) => {
                      return (
                        <option
                          key={segmento.idSegmento}
                          value={segmento.idSegmento}
                        >
                          {segmento.nombre}
                        </option>
                      );
                    })}
                  </select>
                  { formik.touched.idSegmento && formik.errors.idSegmento ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.idSegmento }</div> : null}
              </div>
              <div className='w-1/4 ml-4'></div>
              <div className='w-1/4 ml-4'></div>
            </div>


            <div className='flex flex-row m-4'>
              <div>
                <div className=' text-lg font-black'> Dimensiones del paquete </div>
              </div>
            </div>
            
            <div className='flex flex-row'>
              <div className='w-1/4 ml-4'>
                  <div className=' text-lg font-black'> Largo </div>
                  <input
                    step="0.01"
                    min="0.01"
                    type='number'
                    id='largo'
                    name='largo'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.largo}
                    className='w-11/12  border border-gray-300 rounded	 pt-1 '
                    placeholder='Ingrese medidas en CM'
                  />
                  { formik.touched.largo && formik.errors.largo ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.largo }</div> : null}
              </div>
              <div className='w-1/4 ml-4'>
                  <div className=' text-lg font-black'> Alto </div>
                  <input
                    step="0.01"
                    min="0.01"
                    type='number'
                    id='alto'
                    name='alto'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.alto}
                    className='w-11/12  border border-gray-300 rounded	 pt-1 '
                    placeholder='Ingrese medidas en CM'
                  />
                  { formik.touched.alto && formik.errors.alto ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.alto }</div> : null}
              </div>
              <div className='w-1/4 ml-4'>
                <div className=' text-lg font-black'> Ancho </div>
                <input
                  step="0.01"
                  min="0.01"
                  type='number'
                  id='ancho'
                  name='ancho'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.ancho}
                  className='w-11/12  border border-gray-300 rounded	 pt-1 '
                  placeholder='Ingrese medidas en CM'
                />
                { formik.touched.ancho && formik.errors.ancho ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.ancho }</div> : null}
              </div>
              <div className='w-1/4 ml-4'>
                <div className=' text-lg font-black'> Peso </div>
                <input
                  step="0.01"
                  min="0.01"
                  type='number'
                  id='peso'
                  name='peso'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.peso}
                  className='w-11/12  border border-gray-300 rounded	 pt-1 '
                  placeholder='Ingrese peso en KG'
                />
                { formik.touched.peso && formik.errors.peso ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.peso }</div> : null}
              </div>
            </div>


            <div className='flex flex-row mt-4'>
                <div className='w-full ml-4'>
                <div className=' text-lg font-black'> Información adicional </div>
                <div className='w-full pr-5'>
                <input
                    type='text'
                    id='infoAdicional'
                    name='infoAdicional'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.infoAdicional}
                    className='w-full  border border-gray-300 rounded	 pt-1 '
                  />
                  { formik.touched.infoAdicional && formik.errors.infoAdicional ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.infoAdicional }</div> : null}
                </div>
                </div>
            </div>
            
            <div className='p-2 border-b-2'></div>
            <div className='flex flex-row mt-4'>
              <div className='w-1/2 ml-4 mr-4'>
                <div className=' text-lg font-black'> Contenido </div>
                <select
                  name={`idContenido`}
                  id='idContenido'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.idContenido}
                  className='w-full  border border-gray-300 rounded-sm '
                >
                  <option value={''}>-</option>
                  {contenido.map((contenidos) => {
                    return (
                      <option
                        key={contenidos.idContenido}
                        value={contenidos.idContenido}
                      >
                        {contenidos.descripcion}
                      </option>
                    );
                  })}
                </select>
                { formik.touched.idContenido && formik.errors.idContenido ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.idContenido }</div> : null}
              </div>

              <div className='w-1/2 ml-4 pr-5'>
                <div className=' text-lg font-black'>
                  Descripción de contenido
                </div>
                <input
                  type='text'
                  id='descripcionContenido'
                  name='descripcionContenido'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.descripcionContenido}
                  className='w-full  border border-gray-300 rounded	 pt-1 '
                />
                { formik.touched.descripcionContenido && formik.errors.descripcionContenido ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.descripcionContenido }</div> : null}
              </div>
            </div>

            <div className='flex flex-row mt-4'>
              <div className='w-1/3 ml-4'>
                <div className=' text-lg font-black'> Referencia </div>
                <input
                  type='text'
                  id='referenciaContenido'
                  name='referenciaContenido'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.referenciaContenido}
                  className='w-11/12  border border-gray-300 rounded	 pt-1 '
                />
                { formik.touched.referenciaContenido && formik.errors.referenciaContenido ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.referenciaContenido }</div> : null}
              </div>

              <div className='w-1/3 ml-4'>
                <div className=' text-lg font-black'> Descripción </div>
                <input
                  type='text'
                  id='descripcion'
                  name='descripcion'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.descripcion}
                  className='w-11/12  border border-gray-300 rounded	 pt-1 '
                />
                { formik.touched.descripcion && formik.errors.descripcion ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.descripcion }</div> : null}
              </div>
              {/* <div className='w-1/3 ml-4 pr-5'>
                <div className=' text-lg font-black'> Costo </div>
                <input
                  min="1.0"
                  step="0.01"
                  type='number'
                  id='costo'
                  name='costo'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.costo}
                  className='w-11/12  border border-gray-300 rounded	 pt-1 '
                />
                { formik.touched.costo && formik.errors.costo ? <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                    {formik.errors.costo }</div> : null}
              </div> */}
            </div>
          </div>

          <div className='tablamenu pl-8 flex'>
          <div className='flex mr-4 ml-4 text-lg font-black'> Cantidad de guias: </div>
          <div className='inline-flex border-gray-300 border rounded'>
            <button
              type='button'
              onClick={() => {contadorGuias > 1 ? setContadroGuias(contadorGuias - 1) : setContadroGuias(1)}}
              className={`px-2 border-r border-black`}
            >
              -
            </button>
            <div className='px-3 center'>
              {contadorGuias}
            </div>
            <button
            type='button'
              onClick={() => setContadroGuias(contadorGuias + 1)}
              className={`px-2 border-l border-black`}
            >
              +
            </button>
          </div>
        </div>
          <div className='flex flex-row justify-center w-full pb-3 pb-10'>
            <button
              type='submit'
              onClick={()=> ( isObjEmpty(formik.errors) ? null :NotificationManager.warning( "Favor de llenar los campos", "Cuidado", 3000  ))}
              className='botonverde py-1 w-48 h-10 rounded-sm shadow mt-3'
            >
              Generar guía
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default NuevaGuias;
