import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BiRefresh } from 'react-icons/bi';
import { FaSearch } from 'react-icons/fa';
import { BsCardList } from 'react-icons/bs';
import axiosConfig from '../../utils/axiosConfig';
import { FaEraser, FaEdit } from 'react-icons/fa';
import { Pagination } from '../Pagination'
import Tooltip from '@mui/material/Tooltip';

const Usuariosdash = () => {
  const tableNames = ['Nombre de usuario', 'Correo', 'Rol', 'Opciones'];
  const [ rowsPerPage, setRowsPerPage ] = useState(10);
  const [ prevPage,    setPrevPage    ] = useState( null ) 
  const [ currentPage, setCurrentPage  ] = useState( 1 );
  const [ nextPage,    setNextPage    ] = useState( null )
  const [change, setchange] = useState(false)
  const [borrar, setborrar] = useState(true)
  const [ Drives, setDrives] = useState([]);
  const [ search, setSearch ] = useState('');
  
  const getDrives = async () => {
    setchange(true)
    const data = await axiosConfig({
      method: 'get',
      url: `/usuarios`,
      params: {
        limit: rowsPerPage,
        page: currentPage,
        ...(search ? { search } : {}),
      },
    });

    if( !!data.rows ){
      setchange(false)
      setDrives( data.rows.sort( ( a, b ) => ( a.numero > b.numero ? 1 : ( a.numero < b.numero ? -1 : 0 ) ) ) )
      setCurrentPage(data.currentPage)
      setNextPage(data.nextPage)
      setPrevPage(data.prevPage)
    }
  }

  const handleBorrar = () => {
    setRowsPerPage( 10 );
    setSearch('');
    setborrar(!borrar);
  }

  useEffect(() => {
    getDrives();
  }, [ rowsPerPage, borrar, currentPage] );


  return (
    <div className='pl-6 pt-2 pr-6'>
      <div className='pb-3 flex flex-row justify-between w-full'>
        <div className='titulo  text-3xl'>Usuarios</div>
      </div>

      <div className='Login w-full bg-white shadow rounded'>
        <div className='rojodiv w-full h-6 rounded-t'></div>
        <div className='p-8'>
          <div className='flex flex-row justify-between'>
            <div className='flex flex-row '>
            <Tooltip title="Borrar busqueda" placement="top" arrow>
              <button className=' px-1.5 bg-gray-100 border-gray-400 border rounded'
              onClick={() => handleBorrar()}>
                <FaEraser size={16} />
              </button>
            </Tooltip>

              <div className='w-4'></div>
              <Tooltip title="Actualizar Busqueda" placement="top" arrow>
                <button
                  className=' px-1 bg-gray-100 border-gray-400 border rounded'
                  onClick={() => {
                    getDrives();
                  }}
                >
                  <BiRefresh size={24} />
                </button>
              </Tooltip>
              
              <div className='w-4'></div>
              <Tooltip title="Buscar" placement="top" arrow>
              <button
                className='  px-2 bg-gray-300 border-gray-300 border-b-2 border-l-2 border-t-2 rounded-l'
                onClick={() => {
                  getDrives();
                }}
              >
                <FaSearch />
              </button>
            </Tooltip>
              

              <input
                className='w-60  border-2 border-gray-300 rounded-r	 pt-1 '
                placeholder='Buscar'
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
            </div>
            <div className='flex flex-row '>
              <div className='tablamenu pt-1 pr-2'>Número de registros</div>

              <div className='flex flex-row'>
                <select
                  value={ rowsPerPage }
                  onChange={ ( event ) => setRowsPerPage( parseInt( event.target.value ) ) }
                  className='   border-gray-400 border rounded '
                >
                  <option value='10'>10</option>
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                </select>
              </div>
            </div>
          </div>
          <table className='content-table w-full'>
            <thead>
            { Drives.length === 0 && <tr /> }  
            { Drives.length > 0 &&
              <tr>
                { Drives.length > 0 && tableNames.map( ( name, index ) => {
                  return <th key={ index } > { name } </th>;
                } ) }
              </tr>
            }  
            </thead>
            <tbody>
            { Drives.length === 0 && 
              <tr><td> 
                <div className='w-full grid justify-items-center text-xl font-bold'> No hay usuarios </div>
              </td></tr>
            }
              {Drives.map( ( Drives, index ) => {
                return (
                  <tr key={ index }>
                    <td>{Drives.name}</td>
                    <td>{Drives.email}</td>
                    <td>{Drives.rol}</td>
                    <td>
                    <div className='flex flex-row justify-center'>
                          <button className=' botonazuldetalles   '>
                            <Link
                              to={`/inicio/usuarios/detalle/${Drives.user_id}`}
                              className='w-16'
                            >
                              <div className='flex flex-row'>
                                <div className='pt-1 px-1'>
                                  <BsCardList />
                                </div>
                                <div>Detalles</div>
                              </div>
                            </Link>
                          </button>
                          <div className='p-3'></div>
                          <button className=' botonverdedetalles   '>
                            <Link
                              to={`/inicio/usuarios/editar/${Drives.user_id}`}
                              className='w-16'
                            >
                              <div className='flex flex-row'>
                                <div className='pt-1 px-1'>
                                  <FaEdit />
                                </div>
                                <div>Editar</div>
                              </div>
                            </Link>
                          </button>
                          <div className='p-3'></div>
                        </div>

                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className='flex   mt-3 flex-row-reverse'>
        
          <Pagination 
            { ...{ currentPage, prevPage, nextPage, change} }
            { ...{ setCurrentPage} }
          />
            
        </div>
            
        </div>
      </div>
    </div>
  );
};

export default Usuariosdash;
