import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import SucursalesDash  from './../components/Catalogo/Sucursales/sucursalesDash';
import EditarSucursal  from './../components/Catalogo/Sucursales/editarSucursal';
import DetalleSucursal from './../components/Catalogo/Sucursales/detalleSucursal';
import NuevaSucursal   from './../components/Catalogo/Sucursales/nuevaSucursal';

import SegmentosDash   from './../components/Catalogo/Segmentos/segmentosDash';
import SegmentoDetalle from './../components/Catalogo/Segmentos/segmentoDetalle';
import SegmentoEditar  from './../components/Catalogo/Segmentos/segmentoEditar';
import SegmentoNuevo   from './../components/Catalogo/Segmentos/segmentoNuevo';

import ContenidoDash from '../components/Catalogo/Contenido/contenidoDash';
import ContenidoDetalle from './../components/Catalogo/Contenido/contenidoDetalles';
import ContenidoEditar from './../components/Catalogo/Contenido/contenidoeditar';
import ContenidoNuevo from './../components/Catalogo/Contenido/contenidoNuevo';

const CatalogoRoute = () => {
  const { url } = useRouteMatch();
  
  return (
    <Switch>

      <Route exact path={`${url}/`} component={SucursalesDash} />
      <Route exact path={`${url}/detalle/:id`} component={DetalleSucursal} />
      <Route exact path={`${url}/editar/:id`} component={ EditarSucursal } />
      <Route exact path={`${url}/nuevaSucursal`} component={NuevaSucursal} />

      <Route exact path={`${url}/segmentos`} component={SegmentosDash} />
      <Route exact path={`${url}/segmentos/detalle/:id`} component={SegmentoDetalle} />
      <Route exact path={`${url}/segmentos/editar/:id`} component={SegmentoEditar} />
      <Route exact path={`${url}/segmentos/nuevoSegmento`} component={SegmentoNuevo} />

      <Route exact path={`${url}/contenidos`} component={ContenidoDash} />
      <Route exact path={`${url}/contenidos/detalle/:id`} component={ContenidoDetalle} />
      <Route exact path={`${url}/contenidos/editar/:id`} component={ContenidoEditar} />
      <Route exact path={`${url}/contenidos/nuevoContenido`} component={ContenidoNuevo} />

    </Switch>
  );
};

export default CatalogoRoute;
