import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Usuariosdash from './../components/Usuarios/usuariosdash';
import UsuariosDetalles from './../components/Usuarios/usuariodetalles';
import Usuarioseditar from './../components/Usuarios/usuarioseditar';
import UsuariosNuevo from '../components/Usuarios/usuarionuevo';

const UsuariosRoute = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      {/* <Redirect exact path={`${url}/`} to={`${url}/dashboardmetas`} /> */}
      <Route exact path={`${url}/`} component={Usuariosdash} />
      <Route exact path={`${url}/detalle/:id`} component={UsuariosDetalles} />
      <Route exact path={`${url}/editar/:id`} component={Usuarioseditar} />
      <Route exact path={`${url}/nuevo`} component={UsuariosNuevo} />
    </Switch>
  );
};

export default UsuariosRoute;
