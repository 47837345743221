import React, { useEffect, useState } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';

import axiosConfig from '../../utils/axiosConfig';

/**
 * @author
 * @function MenuItem
 **/
const MenuItem = (props) => {
   const { name, subMenus, iconClassName, to } = props;
   const [ notificacion, setNotificacion ] = useState( 0 );
   const { pathname } = useLocation();
   
   useEffect(() => {
      getDrives();
   }, [ pathname ] )

   const getDrives = async () => {
      const data = await axiosConfig( {
         method: 'GET',
         url: `/guias`,
         params: {
            isAutorizada: false,
         },
      } );

      if( data.rows && data.rows.length > 0 ) {
         setNotificacion( data.rows.length )
      }
   }
   
   return (
      <li onClick={props.onClick}  >
         <Link exac="true" to={to} className="pl-4 menu-item">
            <div className=' menu-icon'>
               <i className={iconClassName}></i>
            </div>
            <span> { name }
               { name === "Guias" && notificacion > 0 && 
                  <label style={ { backgroundColor: "#fff" , color: "red" } } className='mb-2 ml-4 px-2 rounded-xl '> 
                     { notificacion }
                  </label>
               }
            </span>
               {/* { name === "Guias" && 
                  <label style={ { backgroundColor: "#fff" , color: "red" } } className=' mb-2 px-2 rounded-xl '> 
                     { notificacion > 9 ? "+9" : notificacion }
                  </label>
               } */}
         </Link>
         { !!subMenus && subMenus.length > 0 && (
            <ul className={`sub-menu`}>
               { subMenus.map( ( menu, index ) => (
                  <li key={ index }>
                     <NavLink to={ menu.to }>{menu.name}</NavLink>
                  </li>
               ) ) }
            </ul>   
         ) }
      </li>
   );
};

export default MenuItem;
