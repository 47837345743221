import React from 'react';
import UbicacionesRoute from '../../routes/ubicaciones.routes';
const Ubicacione = () => {
  return (
    <div>
      <UbicacionesRoute />
    </div>
  );
};

export default Ubicacione;
