import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
///pruebA
import Login from './components/login';
import Layout from './routes/layout.routes';

function App() {
  document.title = 'Caffenio plataforma guías';

  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from='/' to='login' />
        <Route path='/login' component={Login} />
        <Route path='/inicio' component={Layout} />
        <Route render={() => <Redirect to='/' />} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
