import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axiosConfig from '../../../utils/axiosConfig';
import { useParams } from 'react-router-dom';
// import { ImBin } from 'react-icons/im';
// import { BiEdit } from 'react-icons/bi';
import { MdOutlineArrowBack } from 'react-icons/md';

const ContenidoDetalle = () => {
  const { id: idVdetalle } = useParams();

  const [vdetalle, setVdetalle] = useState({});

  const getFdetalles = useCallback( async () => {
      const data = await axiosConfig({
        method: 'get',
        url: `contenidos/${idVdetalle}`,
      });
      
      if (data) {
        setVdetalle(data);
      }
  }, [ idVdetalle ] ); 

  useEffect(() => {
    getFdetalles();
  }, [idVdetalle, getFdetalles]);
  

  return (
    <div>
      <div className='pl-6 pt-2 '>
        <div className='titulo pb-3 text-3xl'>Catálogos</div>

        <div className='Login w-11/12 bg-white shadow rounded'>
          <div className='rojodiv w-full h-6 rounded-t'></div>

          <div className='flex flex-row p-3 border-b-2'>
            <div>
              <Link to='/inicio/catalogo'>
                <div className=' px-2'>
                  <MdOutlineArrowBack size='24' />
                </div>
              </Link>
            </div>
            <div className=' text-xl titulo'>Detalles de contenido</div>
          </div>

          <div className='tablamenu p-8'>
            <div className='flex flex-row'>
              <div className='w-1/5'>
                <div className='font-black pb-2'>id de contenido</div>
                {vdetalle.idContenido}
              </div>
              <div className='w-1/5'>
                <div className='font-black  pb-2'>descripcion de contenido</div>
                {vdetalle.descripcion}
              </div>
            </div>
          </div>
          <div className='flex flex-row justify-center w-11/12 pb-3'></div>
        </div>
      </div>
    </div>
  );
};
export default ContenidoDetalle;
