import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Tabs = () => {
   const [ openTab, setOpenTab ] = useState();
   const { pathname } = useLocation()

   
   useEffect( () => {
      const tab = pathname.split('/').pop()

      switch( tab ){
         case 'catalogo':
            setOpenTab( 1 )
         break;
         case 'segmentos':
            setOpenTab( 2 )
         break;
         case 'contenidos':
            setOpenTab( 3 )
         break;
         default:
            setOpenTab( 1 )
            break;
      }
   }, [] )

   return (
      <div>
         <div className='w-full'>
            <ul className='flex  flex-wrap pb-4 flex-row' role='tablist'>
               <li className=''>
                  <Link to='/inicio/catalogo' >
                     <span className={ 'text-xs px-5 py-3 border-b-2  block leading-normal  ' +
                        (openTab === 1
                           ? ' text-lg	 border-b-4 bg-white TabsActive'
                           : 'rojoletra text-lg	 bg-white Tabs 	')
                        }
                        onClick={ () => setOpenTab(1) }
                        data-toggle='tab'
                        role='tablist'
                        >
                        Sucursales
                     </span>
                  </Link>
               </li>
               <li className=''>
                  <Link to='/inicio/catalogo/segmentos' >
                     <span
                        className={ 'text-xs px-5 py-3 border-b-2  block leading-normal ' +
                        (openTab === 2
                           ? ' text-lg	 border-b-4 bg-white TabsActive'
                           : 'rojoletra text-lg	 bg-white Tabs 	')
                        }
                        onClick={ () => setOpenTab(2) }
                        data-toggle='tab'
                        role='tablist'
                     >
                        Segmento de negocio
                     </span>
                  </Link>
               </li>
               <li className=''>
                  <Link to='/inicio/catalogo/contenidos' >
                     <span
                        className={
                           'text-xs px-5 py-3 border-b-2  block leading-normal ' +
                           (openTab === 3
                           ? ' text-lg	 border-b-4 bg-white TabsActive'
                           : 'rojoletra text-lg	 bg-white Tabs 	')
                        }
                        onClick={ () => setOpenTab(3) }
                        data-toggle='tab'
                        role='tablist'
                        >
                        Contenido
                     </span>
                  </Link>
               </li>
            </ul>
         </div>
      </div>
  );
};


export default Tabs;
