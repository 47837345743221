import React, { useState, useLayoutEffect } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import { MdOutlineArrowBack } from 'react-icons/md';

import axiosConfig from '../../../utils/axiosConfig';

const initialValues = {
  nombre: '',
  numero: '',
}

const SegmentoEditar = () => {
  const history = useHistory();
  const { id: idSegmento } = useParams();

  const [ segmentoValue, setSegmentoValue ] = useState( initialValues );
  const { nombre, numero } = segmentoValue

  const getSegmentoValue = async () => {
    const data = await axiosConfig({
      method: 'get',
      url: `segmentos/${ idSegmento }`,
    } );

    if( !!data ) {
      setSegmentoValue( data );
    }
  };

  useLayoutEffect(() => {
    getSegmentoValue();
  }, [ idSegmento ] );

  const handleChange = ( { target } ) => {
    setSegmentoValue( s => ( { ...s, [target.name]: target.value } ) )
  }

  const validar = () => {
    if( nombre === "" || numero === "" ){
      NotificationManager.warning( "Favor de llenar todos los campos", "Cuidado", 2500 )
      return true
    }
    return false
  }

  const handleSubmit = ( e ) => {
    e.preventDefault();

    if( validar() ){
      return;
    }

    axiosConfig({
      method: 'PUT',
      url: `/segmentos/${ idSegmento }`,
      data: {
        nombre,
        numero,
      },
    } ).then( () => {
      NotificationManager.success( "Segmento Editado", "Guardado", 2500 )
      history.push( `/inicio/catalogo/segmentos` );
    } ).catch( err => NotificationManager.error( "Ingresó mal los campos o hay problema con el internet", "Oops..", 2500 ) )
  }


  return (
    <div className='w-full'>
      <div className='Login  bg-white shadow rounded'>
        <div className='flex flex-row p-3 border-b-2'>
          <div>
            <Link to='/inicio/catalogo/segmentos'>
              <div className=' px-2'>
                <MdOutlineArrowBack size='24' />
              </div>
            </Link>
          </div>
          <div className=' text-xl titulo'>Editar Segmento</div>
        </div>
        <form onSubmit={ handleSubmit }>
          <div className='tablamenu p-6 flex flex-row'>
            <div className='w-1/3'>
              <div className='font-black'>Nombre de Segmento</div>

              <input
                type='text'
                id='nombre'
                name='nombre'
                onChange={ handleChange }
                value={ nombre }
                className='w-11/12 border border-gray-300 rounded	 pt-1 '
                autoComplete='off'
              />
            </div>

            <div className='w-1/3'>
              <div className='font-black'>Número de segmento</div>
              <input
                type='text'
                id='numero'
                name='numero'
                onChange={ handleChange }
                value={ numero }
                className='w-11/12  border border-gray-300 rounded	 pt-1 '
                autoComplete='off'
              />
            </div>
          </div>

          <div className='grid justify-items-center pb-4 pt-7'>
            <button
              type='submit'
              className='botonverde  w-64 h-7 rounded shadow'
              // onClick={postFormulario(form, values)}
            >
              Guardar cambios
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SegmentoEditar;
