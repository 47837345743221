import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '../Tabs';
import CatalogoRoute from '../../routes/catalogo.routes'

const Catalogos = () => {
   const { pathname } = useLocation()
   const [ mostrar, setMostrar ] = useState( true )

   const tab = pathname.split('/').pop()

   useEffect( () => {
      setMostrar( ( tab === 'segmentos' || tab === 'catalogo' || tab === 'contenidos' ? true : false ) )
   }, [ tab ] )
   
   return (
      <div className='pl-6 pt-2 pr-6'>
         <div className='pb-3 flex flex-row justify-between w-11/12'>
            <div className='titulo  text-3xl'>Catálogos</div>
         </div>

         <div className='Login bg-white w-full shadow rounded' >
            <div className='rojodiv w-full h-6 rounded-t' ></div>
            <div>
               { mostrar && <Tabs/>} 
               <CatalogoRoute />
            </div>
         </div>
      </div>
   );
};

export default Catalogos;
