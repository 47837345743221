import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import Guias from './../components/Guias/guias';
import Cotizar from './../components/Cotizar Guia/cotizar';
import Usuarios from './../components/Usuarios/usuarios';
import Ubicaciones from './../components/Ubicaciones/ubicaciones';
import Reportes from './../components/Reportes/reportes';
import ReportesC from './../components/ReportesC/reportesc';
import Catalogos from '../components/Catalogo/Catalogos'

export const Panel = () => {
  const { url } = useRouteMatch();
  
  return (
    <Switch>
      <Redirect exact path={`${url}/`} to={`${url}/guias`} />

      <Route path={`${url}/guias`} component={Guias} />
      <Route path={`${url}/cotizarguia`} component={Cotizar} />
      <Route path={`${url}/usuarios`} component={Usuarios} />
      <Route path={`${url}/ubicaciones`} component={Ubicaciones} />
      <Route path={`${url}/reportes`} component={Reportes} />
      <Route path={`${url}/reportesconsolidados`} component={ReportesC} />
      <Route path={`${url}/catalogo`} component={Catalogos} />
    </Switch>
  );
};
export default Panel;
