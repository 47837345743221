import jwt from 'jsonwebtoken';

export function storeToken(token) {
  sessionStorage.setItem('token', token);
}

export function getToken(decode = false) {
  const token = sessionStorage.getItem('token');
  if (decode) {
    const decoded = jwt.decode(token);
    return decoded;
  }
  return token;
}

export function isTokenExpired(token) {
  try {
    const decoded = jwt.decode(token);
    if (decoded.exp < Date.now() / 1000) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

export async function getVerifiedToken() {
  try {
    const token = getToken();
    if (token) {
      // let key = await getPublicKey();
      // const decoded = jwt.verify(token, key, { algorithms: ['RS256'] });
      const decoded = jwt.decode(token);
      return { token, decoded };
    }
    return null;
  } catch (error) {
    logout();

    return null;
  }
}

export function logout() {
  sessionStorage.clear();
}
