import React, { useState, useLayoutEffect } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import { MdOutlineArrowBack } from 'react-icons/md';

import axiosConfig from '../../../utils/axiosConfig';

const initialValues = {
  nombre: '',
  region: '',
  numero: '',
};

const EditarSucursal = () => {
  
  const history = useHistory();
  const { id: idSucursal } = useParams();
  
  const [ sucursalValue, setSucursalValue ] = useState( initialValues );
  const { nombre, region, numero } = sucursalValue

  const getSucursalValue = async () => {
    const data = await axiosConfig({
      method: 'GET',
      url: `sucursales/${ idSucursal }`,
    });

    if( !!data ) {
      setSucursalValue( data );
    }
  };

  const handleChange = ( { target } ) => {
    setSucursalValue( s => ({ ...s, [target.name]: target.value }) )
  }

  const handleSubmit = ( e ) => {
    e.preventDefault();

    if( validar() ){
      return;
    }

    axiosConfig({
      method: 'put',
      url: `/sucursales/${ idSucursal }`,
      data: {
        nombre,
        region,
        numero,
      },
    });
    
    NotificationManager.success( "", "Sucursal Editada", 2500 )

    let path = `/inicio/catalogo`;
    history.push(path);
  }
  
  const validar = () => {
    if( nombre === "" || region === "" || numero === "" ){
      NotificationManager.warning( "Favor de llenar todos los campos", "Cuidado", 2500 )
      return true;
    }
    return false;
  }

  useLayoutEffect(() => {
    getSucursalValue();
  }, [ idSucursal ] );
  
  return (
    <div className='w-12/12'>
      <div className='Login  bg-white shadow rounded'>
        <div className='flex flex-row p-3 border-b-2'>
          <div>
            <Link to='/inicio/catalogo'>
              <div className=' px-2'>
                <MdOutlineArrowBack size='24' />
              </div>
            </Link>
          </div>
          <div className=' text-xl titulo'>Editar Sucursal</div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className='tablamenu p-6 flex flex-row'>
            <div className='w-1/3'>
              <div className='font-black'>Nombre de formulario</div>
              <input
                type='text'
                id='nombre'
                name='nombre'
                onChange={ handleChange }
                value={ nombre }
                className='w-11/12 border border-gray-300 rounded	 pt-1 '
              />
            </div>
            <div className='w-1/3'>
              <div className='font-black'>Región</div>
              <select
                id='region'
                name='region'
                onChange={ handleChange }
                value={ region }
                className='w-11/12  border border-gray-300 rounded	 pt-1 '
              >
                <option value={''}> - </option>
                <option value={'Baja Norte'}> Baja Norte </option>
                <option value={'Noroeste'}> Noroeste </option>
                <option value={'Norte'}> Norte </option>
                <option value={'Centro'}> Centro </option>
                <option value={'Occidente'}> Occidente </option>
                <option value={'Sureste'}> Sureste </option>
              </select>
            </div>
            <div className='w-1/3'>
              <div className='font-black'>Número de sucursal</div>
              <input
                type='text'
                id='numero'
                name='numero'
                onChange={ handleChange }
                value={ numero }
                className='w-11/12  border border-gray-300 rounded	 pt-1 '

              />
            </div>
          </div>

          <div className='grid justify-items-center pb-4 pt-7'>
            <button
              type='submit'
              className='botonverde  w-64 h-7 rounded shadow'
              // onClick={postFormulario(form, values)}
            >
              Guardar cambios
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditarSucursal;
