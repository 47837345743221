import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { Tooltip } from '@mui/material';
import { BiRefresh } from 'react-icons/bi';
import { BsCardList, BsFillFileEarmarkPdfFill } from 'react-icons/bs';
import { FaDownload, FaEraser, FaAngleDown, FaAngleUp } from 'react-icons/fa';

import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';

import axiosConfig from '../../utils/axiosConfig';
import { Pagination } from '../Pagination'

const ListaGuias = () => {
  
  const [ nombreDestinatario, setNombreDestinatario ] = useState( '' );
  const [ idGuia,             setidGuia             ] = useState( [] );
  const [ idSucursal,         setIdSucursal         ] = useState( '' );
  const [ idSegmento,         setIdSegmento         ] = useState( '' );
  const [ infoAdicional,      setCont               ] = useState( '' );
  const [ descripcion,        setDesc               ] = useState( '' );
  const [ fechaInicial,       setFechaInicial       ] = useState( '' );
  const [ fechaFinal,         setFechaFinal         ] = useState( '' );

  const tableNames = [
    'Fecha de creación ',
    'Destinatario',
    'Origen',
    'Segmentos',
    'Destino',
    'Sucursal (centro de costos)',
    '#Guia',
    'Contenido',
    'Descripción',
    'Estatus',
    'Opciones'
  ];

  const [ Drives,      setDrives      ] = useState( [] );
  const [ Segmentos,   setSegmentos   ] = useState( [] );
  const [ sucursales,  setSucursales  ] = useState( [] );
  const [ mostrar,     setMostrar     ] = useState( false )
  const [borrar, setborrar] = useState(true)
  const [ rowPerPage,  setRowPerPage  ] = useState( 10 );
  const [change, setchange] = useState(false)
  const [ prevPage,    setPrevPage    ] = useState( null ) 
  const [ currentPage, setCurrentPage  ] = useState( 1 );
  const [ nextPage,    setNextPage    ] = useState( null ) 
  
  
  const validar = () => {
    var fecha1 = new Date(fechaInicial);
    var fecha2 = new Date(fechaFinal);
    if (fechaInicial.length===0) {
      NotificationManager.warning( "Debe de seleccionar una fecha inicial", "Cuidado", 2500 )
    } else if (fechaFinal.length===0 ) {
      NotificationManager.warning('Debe de seleccionar una fecha final', "Cuidado", 2500 )
    } else if (fecha1.getTime() > fecha2.getTime()) {
      NotificationManager.warning( 'La fecha inicial debe ser menor a la final', "Cuidado",  2500 )
    } else {
      handleDownloadReporte()
    }
  }

  const handleDownloadReporte = async (values, actions) => {
      try {
        let extension = 'xlsx';
  
        const response = await axiosConfig({
          method: 'get',
          url: `/reporte/repGuias`,
          params: {
          ...(idSegmento ? { idSegmento } : {}),
          ...(fechaInicial ? { fechaInicial } : {}),
          ...(fechaFinal ? { fechaFinal } : {}),
          ...(idSucursal ? { idSucursal } : {}),
          ...(idGuia ? { idGuia } : {}),
          ...(descripcion ? { descripcion } : {}),
          ...(infoAdicional ? { infoAdicional } : {}),
          ...(nombreDestinatario ? { nombreDestinatario } : {}),
          },
          responseType: 'blob',
        });
  
        saveAs(response.data, `${'reporteguias'}.${extension}`);
      } catch (error) {
        console.log('Error:', error);
      }
  };

  const getSegmentos = async () => {
    const { count,  rows } = await axiosConfig({
      method: 'get',
      url: `/segmentos`,
    } );
    
    if( count > 0 ){
      setSegmentos( rows );
    }
  };

  const getSucursales = async () => {
    const { count, rows } = await axiosConfig({
      method: 'get',
      url: `/sucursales`,
    } );
      
    if( count > 0 ){
      setSucursales( rows );
    }
    
  };

  const borrarFiltros = () => {
    setFechaInicial('');
    setFechaFinal('');
    setidGuia('');
    setCont('');
    setDesc('');
    setNombreDestinatario('');
    setIdSegmento('');
    setIdSucursal('');
    setborrar(!borrar);
    getDrives();
  };

  const getDrives = async () => {
    setchange(true)
    const data = await axiosConfig({
      method: 'get',
      url: `/guias`,
      params: {
        isAutorizada: true,
        limit: rowPerPage,
        page: currentPage,
        ...(idSegmento ? { idSegmento } : {}),
        ...(fechaInicial ? { fechaInicial } : {}),
        ...(fechaFinal ? { fechaFinal } : {}),
        ...(idSucursal ? { idSucursal } : {}),
        ...(idGuia ? { idGuia } : {}),
        ...(descripcion ? { descripcion } : {}),
        ...(infoAdicional ? { infoAdicional } : {}),
        ...(nombreDestinatario ? { nombreDestinatario } : {}),
      },
    } );
    
    if( !!data.rows ){
      setchange(false)
      setDrives( data.rows )
      setCurrentPage(data.currentPage)
      setNextPage(data.nextPage)
      setPrevPage(data.prevPage)
    }
  } 
  
  useEffect( () => getDrives() , [ rowPerPage, currentPage, borrar ] )

  useEffect(() => {
    getSegmentos();
    getSucursales();
    getDrives();
  }, [] );

  return (
    <div className='mb-7' >
      <div className='p-4' >
        <div className='flex' >
          <div className='flex flex-row' style={{width:"50%"}}>
            <Tooltip title="Borrar Filtros" placement="top" arrow>
            <button
              className=' px-1.5 bg-gray-100 border-gray-400 border rounded'
              onClick={() => {
                borrarFiltros();
              }}
            >
              <FaEraser size={16} />
            </button>
            </Tooltip>
            <div className='w-4'></div>
            <Tooltip title="Actualizar Busqueda" placement="top" arrow>
            <button
              className=' px-1 bg-gray-100 border-gray-400 border rounded'
              onClick={ ( event ) => {
                event.preventDefault();
                getDrives();
              }}
            >
              <BiRefresh size={24} />
            </button>
            </Tooltip>
            
          </div>
          <div className='flex flex-row justify-end' style={{width:"50%"}}>
              <div className='tablamenu pt-1 pr-2'>Número de registros</div>

              <div className='mr-2 flex flex-row'>
                <select
                  value={ rowPerPage }
                  onChange={ ( e ) => setRowPerPage( parseInt( e.target.value ) ) }
                  className='   border-gray-400 border rounded '
                >
                  <option value='10'>10</option>
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                </select>
              </div>
            </div>
        </div>
        <div className='flex flex-row align-middle'>
          <button className='py-1 w-48 flex flex-row mt-5' onClick={ () =>  setMostrar( current => !current ) }>
            Filtrar
            { mostrar ?
              <FaAngleUp 
              alignmentBaseline='middle'
              size={24}/>
              :
              <FaAngleDown 
              alignmentBaseline='middle'
              size={24}/>

            }
            
            </button>
        </div>
        <div style={{display: mostrar ? 'block' : 'none'}}>
        <div className='flex flex-row pt-4'>
          <div className='w-1/4 pr-4'>
            <div className='titulo text-lg'> Filtrar por fecha inicial</div>

            <input
              value={fechaInicial}
              onChange={(event) => setFechaInicial(event.target.value)}
              className='w-full  border border-gray-300 rounded pt-1 '
              type='date'
            />
          </div>
          <div className='w-1/4 pr-4'>
            <div className='titulo text-lg'> Filtrar por fecha final</div>

            <input
              value={fechaFinal}
              onChange={(event) => setFechaFinal(event.target.value)}
              className='w-full  border border-gray-300 rounded pt-1 '
              type='date'
            />
          </div>
          <div className='w-1/4 pr-4'>
            <div className='titulo text-lg'> Filtrar por sucursal</div>
            <select
              className=' w-full border h-7 border-gray-300 rounded '
              value={idSucursal}
              onChange={(event) => setIdSucursal(event.target.value)}
            >
              <option value={""}>-</option>
              { sucursales ?
              sucursales.map((sucursal, index) => {
                return (
                  <option key={ index } value={sucursal.idSucursal}>
                    {sucursal.nombre}
                  </option>
                );
              }) : <option>-</option>}
            </select>
          </div>
          <div className='w-1/4'>
            <div className='titulo text-lg'> Filtrar por segmento</div>
            <select
              value={idSegmento}
              onChange={(event) => setIdSegmento(event.target.value)}
              className='w-full  border border-gray-300 rounded-sm '
            >
              <option value={""}>-</option>
              { Segmentos.map((segmento, index) => {
                return (
                  <option key={ index } value={segmento.idSegmento}>
                    {segmento.nombre}
                  </option>
                );
              } ) }
            </select>
          </div>
        </div>
        <div className='flex flex-row pt-4'>
          <div className='w-1/4 pr-4'>
            <div className='titulo text-lg'> Filtrar por destinatario</div>
            <input
              type='text'
              className=' w-full border h-7 border-gray-300 rounded '
              value={nombreDestinatario}
              onChange={(event) => setNombreDestinatario(event.target.value)}
            ></input>
          </div>
          <div className='w-1/4 pr-4'>
            <div className='titulo text-lg'> Filtrar por contenido</div>
            <input
              type='text'
              className=' w-full border h-7 border-gray-300 rounded '
              value={infoAdicional}
              onChange={(event) => setCont(event.target.value)}
            ></input>
          </div>
          <div className='w-1/4 pr-4'>
            <div className='titulo text-lg'> Filtrar por descripción</div>
            <input
              type='text'
              className=' w-full border h-7 border-gray-300 rounded '
              value={descripcion}
              onChange={(event) => setDesc(event.target.value)}
            ></input>
          </div>
          <div className='w-1/4'>
            <div className='titulo text-lg'> Filtrar por número de guía</div>
            <input
              type='number'
              className=' w-full border h-7 border-gray-300 rounded '
              value={idGuia}
              onChange={(event) => setidGuia(event.target.value)}
            ></input>
          </div>
        </div>
          <div className='flex flex-row justify-end align-middle'>
            <button
              className='botonverde pr-2 mt-5 mr-5 py-1 flex flex-row w-58 h-10 justify-items-center rounded shadow'
              onClick={() => {validar()}}>
              <div className='pt-1 px-2'>
                <FaDownload />
              </div>
              <div>Descargar reporte de guías</div>
            </button>
            <button className='botonazuldetalles py-1 w-48 rounded-sm shadow mt-5' style={{textAlign:"center"}} 
            onClick={ () => getDrives() }>Aplicar Filtros</button>
          </div>
        </div>

        <div style={ { overflowX: "auto", } }>

          <table className='content-table w-full'>
            <thead >
              { Drives.length === 0 && <tr /> }  
              { Drives.length > 0 && 
                <tr>
                  { tableNames.map((name, index) => {
                    return <th key={ index }>{name}</th>;
                  })}
                </tr>
              }
              
            </thead>
            <tbody>
              { Drives.length === 0 && 
                <tr><td> 
                  <div className='w-full grid justify-items-center text-xl font-bold'> No hay Guías </div>
                </td></tr>
              }
              { Drives.length > 0 && Drives.map( ( Drives, index ) => {
                return (
                  <tr key={ index }>
                    <td>{DateTime.fromISO(Drives.createdAt).toLocaleString()}</td>

                    <td>{Drives.nombreDestinatario}</td>
                    <td>{Drives.UbicacionOrigen.nombreUbicacion }</td>
                    <td>{Drives.Segmento.nombre}</td>
                    <td>{Drives.UbicacionDestino === null ? Drives.DireccionAlternativa.direccionCalle : Drives.UbicacionDestino.direccionCalle } </td>
                    <td>{Drives.Sucursal.nombre } </td>
                    <td>{Drives.numGuia }</td>
                    <td>{Drives.Contenido.descripcion}</td>
                    <td>{Drives.descripcion}</td>
                    
                    <td>estatus</td>
                    
                    <td>
                      <div className='flex flex-row'>

                        <Link to={`/inicio/guias/detallesguias/${Drives.idGuia}`} >
                          <button className='flex flex-row mx-3 botonazuldetalles   '>
                            <div className='pt-1 px-1'>
                              <BsCardList />
                            </div>
                            <div>Detalles</div>
                          </button>
                        </Link>
                        
                        <a className='flex flex-row mx-3 botongrisbordes '
                          download="pdfTitle"
                          href={  `data:application/pdf;base64,${ Drives.pdfBase64 } `}

                          title='Download pdf document' >
                          <div className='px-1 pt-1'>
                            <BsFillFileEarmarkPdfFill />
                          </div>
                          <div className="flext flex-row" style={{ width: "120px" }}>Descargar Ticket</div>
                        </a>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          
        </div>
        
        <div className='flex   mt-3 flex-row-reverse'>
        
          <Pagination 
            { ...{ currentPage, prevPage, nextPage, change } }
            { ...{ setCurrentPage} }
          />
            
        </div>
      </div>
    </div>
  );
};

export default ListaGuias;