import React, { useEffect, useState } from 'react'
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs'

export const Pagination = ( props ) => {

   const [ pages, setPages ] = useState( [] )
   const { currentPage, prevPage, nextPage, } = props
   const { setCurrentPage } = props
   const { change } = props
   
   const check = () => {
      const arreglo = []
      if (prevPage) {
         arreglo.push(prevPage)
      }

       arreglo.push( currentPage )

      if (nextPage !== null) {
         arreglo.push(nextPage)
      }
      setPages(arreglo)
   }

   useEffect( () => {
      check()
   }, [ currentPage, nextPage, prevPage , change] );


   useEffect( () => check(), [] )

   const handleChange = ( action ) => {
      switch ( action ) {
         case "prevPage":
            setCurrentPage( s => ( prevPage === null ? 1 : s - 1 ) )           
            break;
         case "nextPage":
            setCurrentPage( s => ( nextPage === null ? s : s + 1 ) )
            break;
         case "middleOption":
            break;
         default:
            return;
      }
      
   }
   return (
      <div className='inline-flex	'>
         <button onClick={()=> handleChange("prevPage") } disabled={ (currentPage === 1 || change === true) ? true : false}
            name="prevPage"
            className={ `btn-primary bg-blue-500 text-white py-1 px-2 rounded-l focus:outline-none text-xl ${ currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'opacity-75 hover:opacity-100' }`} >
            <BsArrowLeftShort />
         </button>

         <div className='pagination' >
         {
            pages.map( ( item, index ) => ( 
               
               <button 
                  key={ index } 
                  type="button" 
                  name={ item } 
                  className={ `${ currentPage === item ? "active" : "" }` }  
                  onClick={ () => setCurrentPage( item ) } 
               >
                  { item }
               </button>
            ) )
         }
         </div>
         <button onClick={()=> handleChange("nextPage") } disabled={ (nextPage === null || change === true) ? true : false }
            name="nextPage"
            className={`btn-primary bg-blue-500 text-white py-1 px-2 rounded-r focus:outline-none text-xl ${ nextPage === null ? 'opacity-50 cursor-not-allowed' : 'opacity-75 hover:opacity-100' }`}>
            <BsArrowRightShort />
         </button>
      </div>
   )
}