import React, { useEffect, useState }  from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';

import DetallesUbicacion from './../components/Ubicaciones/detallesUbicacion';
import NuevaUbicacion from './../components/Ubicaciones/nuevaUbicacion';
import UbicacionesPanel from './../components/Ubicaciones/ubicacionesPanel';
import EditarUbicacion from './../components/Ubicaciones/editarUbicaciones';

import { getToken } from '../utils/authService';
import jwt from 'jsonwebtoken';
import axiosConfig from '../utils/axiosConfig';

const UbicacionesRoute = () => {
  const { url } = useRouteMatch();
  const [rol, setRol] = useState(null)
  const [ubiO, setUbiO] = useState([])

  const permisos = async() => {
    const token = getToken();
    const {email}  = jwt.decode( token );
    const rol = await axiosConfig({
      method: 'GET',
      url: `/usuarios`,
      params: {
        search: email
      },
    } );
    const resp = await axiosConfig( {
      method: "GET",
      url: `usuarios/${rol.rows[0].user_id}`
    } );
    if( resp ) {
      const ubicaciones = await axiosConfig( {
        method: "GET",
        url: `ubicaciones`,
      } );
      if( !!ubicaciones.rows){
        if ((ubicaciones.rows.filter( item => item.idSucursal === parseInt( resp.user["Sucursal.idSucursal"] ) )).length !== 0) {
          setUbiO( ubicaciones.rows.filter( item => item.idSucursal === parseInt( resp.user["Sucursal.idSucursal"] ) )[0].idUbicacion  );
        } else {
          setUbiO([])
        }
      }
    }

    if( !!rol.rows ){
      setRol(rol.rows[0].rol);
    }
  }    

    useEffect( () => {
      permisos();
    }, [rol] );

    if ( rol !== "Administrador" ) {   
      if (!!ubiO) {
      return (
        <Switch>
        {/* <Redirect exact path={`${url}/`} to={`${url}/dashboardmetas`} /> */}
          <Route exact path={`${url}/`} component={UbicacionesPanel} />
          <Route path={`${url}/detalle/${ubiO}`} component={DetallesUbicacion} />
          <Route path={`${url}/editar/${ubiO}`} component={EditarUbicacion} />
          <Route exact path={`${url}/nuevaubicacion`} component={NuevaUbicacion} />
          <Redirect to={`${url}/` }/>
        </Switch>
        );
      }
      
    }
  return (
    <Switch>
      {/* <Redirect exact path={`${url}/`} to={`${url}/dashboardmetas`} /> */}
      <Route exact path={`${url}/`} component={UbicacionesPanel} />
      <Route exact path={`${url}/detalle/:id`} component={DetallesUbicacion} />
      <Route exact path={`${url}/editar/:id`} component={EditarUbicacion} />
      <Route exact path={`${url}/nuevaubicacion`} component={NuevaUbicacion} />
    </Switch>
  );
};

export default UbicacionesRoute;
