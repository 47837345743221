import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NuevaGuias from '../components/Guias/nuevaGuia';
import DetallesGuias from './../components/Guias/DetallesGuias';
import ListaGuias from './../components/Guias/listaGuias';
import AutorizarGuiasDetalles from './../components/Guias/autorizarGuiasDetalle';
import AutorizarGuias from '../components/Guias/autorizarGuias'

const GuiasRoute = () => {
  const { url } = useRouteMatch();
  
  return (
    <Switch>
      <Route path={`${url}/autorizarguiasdetalles/:id`} component={AutorizarGuiasDetalles} />
      <Route path={`${url}/autorizarguias`} component={ AutorizarGuias }  />
      
      <Route path={`${url}/detallesguias/:id`} component={DetallesGuias} />
      <Route path={`${url}/nuevaguia`} component={NuevaGuias} />
      <Route path={`${url}/`} component={ListaGuias} />
    </Switch>
  );
};

export default GuiasRoute;
