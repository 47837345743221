import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axiosConfig from '../../utils/axiosConfig';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useFormik } from 'formik';

import { NotificationManager } from 'react-notifications';

const initVal = {
    nombreUbicacion: '',
    tipoUbicacion: '',
    idSucursal: '',
    direccionCalle: '',
    direccionNumero: '',
    direccionColonia: '',
    direccionCP: '',
    direccionCiudad: '',
    direccionEstado: '',
}

const NuevaUbicacion = () => {
   const history = useHistory()        
   const [ region, setRegion ] = useState( "" )
   const [ sucursales, setSucursales ] = useState( [] )
   const [ estados, setEstados ] = useState( [] );
   const [ ciudades, setCiudades ] = useState( [] );

   const formik = useFormik( { 
      initialValues: initVal,
      onSubmit: ( values ) => {
         if( validar( values ) ){
            return
         } 

         axiosConfig( {
               method: 'POST',
               url: `/ubicaciones`,
               data: { 
                  nombreUbicacion:  values.nombreUbicacion,
                  tipoUbicacion:    values.tipoUbicacion,
                  idSucursal:       +values.idSucursal,
                  direccionCalle:   values.direccionCalle,
                  direccionNumero:  values.direccionNumero,
                  direccionColonia: values.direccionColonia,
                  direccionCP:      String( values.direccionCP ),
                  direccionCiudad:  values.direccionCiudad,
                  direccionEstado:  values.direccionEstado,
               },
         } ).then( () => {
               NotificationManager.success( "Ubicacion Guardada con exito", "¡Guardado!", 2000 )
               history.push( "/inicio/ubicaciones" );
         } ).catch( err => {
               console.log( err )
               NotificationManager.error( err.message, '¡Error!' , 3000, () => {} )
         } )
      }
   } );

   const getSucursales = useCallback( () => {
      axiosConfig( {
         method: 'GET',
         url: `/sucursales`,
         params:{
            limit: 1000
         }
      } ).then( ( { rows } ) => {
         setSucursales( !!rows ? rows : [] )
      } ).catch( err => {
         NotificationManager.error( err.message, '¡Error!' , 3000, () => {} )
      } )
   } , [] )

   const getEstados = useCallback ( async ( ) => {
      const data = await axiosConfig( {
         method: 'GET',
         url: `/ubicaciones/estados`,
      } );

      if( !!data.rows ){
         setEstados( data.rows )
      }else{
         NotificationManager.error( "Ingresó mal los campos o hay problema con el internet", '¡Error!' , 3000, () => {} )
      }
   }, [] )

   const handleSucursal = ( { target } ) => {
      const { value } = target
      
      if( value === '-' ){
         setRegion( "" );
         formik.setFieldValue( "idSucursal", "-" )
         return;
      }

      formik.setFieldValue( "idSucursal", parseInt(value) )
      const auxSucursal = sucursales.filter( item => parseInt( value ) === item.idSucursal )
      setRegion( auxSucursal[0].region )
   }

   const handleEstado = ( { target } ) => {
      const idEstado = target.value
      
      if( idEstado === "" ){
         formik.setFieldValue( "direccionEstado", "" )
         setCiudades( [] )
         return
      }

      formik.setFieldValue( "direccionEstado", idEstado )
      
      axiosConfig( {
         method: 'GET',
         url: `/ubicaciones/ciudades/${ idEstado }`,
      } ).then( ( data ) => {
         setCiudades( data.rows )
      } ).catch( err => {
         NotificationManager.error( "Ingresó mal los campos o hay problema con el internet", '¡Error!' , 3000, () => {} )
      } )
      
   }

   useEffect( () => {
      getSucursales();
      getEstados();
   }, [ getSucursales, getEstados ] );

   const validar = ( values ) => {
      if( values.nombreUbicacion  === "" || values.tipoUbicacion  === "" || values.idSucursal  === "-" ||
      values.direccionCalle  === "" || values.direccionNumero  === "" || values.direccionColonia  === "" || 
      values.direccionCP  === ""  || values.direccionCP === 0 || values.direccionCiudad  === "" || values.direccionEstado  === "" ) {
         NotificationManager.warning( "Falta Llenar campos", "Error", 2500 );
         return true
      }
      return false
   }
   
   return (
      <div>
         <div className='pl-6 pt-2 '>
            <div className='pb-3  w-11/12'>
               <div className='titulo  text-3xl'>Ubicaciones</div>
            </div>

            <div className='Login w-11/12 bg-white shadow rounded'>
               <div className='rojodiv w-full h-6 rounded-t'></div>

               <div className='flex flex-row p-3 border-b-2'>
               <div>
                  <Link to='/inicio/ubicaciones'>
                     <div className=' px-2'>
                     <MdOutlineArrowBack size='24' />
                     </div>
                  </Link>
               </div>
               <div className=' text-xl titulo'>Agregar ubicación</div>
               </div>
               <form onSubmit={formik.handleSubmit}>
               <div className='tablamenu p-8'>
                  <div className='flex flex-row'>
                     <div className='w-1/5'>
                     <div className='font-black pb-2'>Nombre de ubicacion</div>
                     <input
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.nombreUbicacion}
                        onBlur={formik.handleBlur}
                        id='nombreUbicacion'
                        name='nombreUbicacion'
                        className='w-11/12  border border-gray-300 rounded-sm '
                        placeholder=''
                     />
                     </div>
                     <div className='w-1/5'>
                     <div className='font-black  pb-2'>Tipo de ubicacion</div>
                     <select
                        onChange={ formik.handleChange }
                        value={formik.values.tipoUbicacion}
                        id='tipoUbicacion'
                        name='tipoUbicacion'
                        className='w-11/12  border border-gray-300 rounded-sm '
                     >
                        <option value="">-</option>
                        <option value='Sucursal'>Sucursal</option>
                        <option value='CEDIS'>CEDIS</option>
                        <option value='CEDIS Oxxo'>CEDIS Oxxo</option>
                        <option value='Plaza'>Plaza</option>
                        <option value="Direccion particular">Direccion particular</option>
                        <option value="Proveedor Externo"> Proveedor Externo </option>
                        <option value="Cliente Externo">Cliente Externo</option>
                        <option value="Otros">Otros</option>  
                     </select>
                     </div>
                     <div className='w-1/5'>
                     <div className='font-black  pb-2'>Sucursal</div>
                     <select
                        onChange={ handleSucursal }
                        value={formik.values.idSucursal}
                        onBlur={formik.handleBlur}
                        id='idSucursal'
                        name='idSucursal'
                        className='w-11/12  border border-gray-300 rounded-sm '
                     >
                        <option value={"-"}>-</option>
                        {
                           sucursales.map( ( item, index ) => (
                                 <option key={ index } value={ item.idSucursal }> { item.nombre } </option>
                           ) )
                        }
                     </select>
                     </div>
                     <div className='w-1/5'>
                        <div className='font-black  pb-2'> Region </div>
                        <input
                           onChange={()=>{}}
                           value={region}
                           id='region'
                           name='region'
                           className='w-11/12  border border-gray-300 rounded-sm '
                           readOnly
                           disabled
                        />
                     </div>
                  </div>
                  
                  <div className='subtitulo text-xl py-5'>Dirección</div>
                  <div className='flex flex-row'>
                     <div className='w-1/5'>
                     <div className='font-black pb-2'>Calle</div>
                     <input
                        onChange={formik.handleChange}
                        value={formik.values.direccionCalle}
                        onBlur={formik.handleBlur}
                        id='direccionCalle'
                        name='direccionCalle'
                        className='w-11/12  border border-gray-300 rounded-sm '
                        placeholder=''
                     />
                     </div>

                     <div className='w-1/5'>
                     <div className='font-black  pb-2'>Número</div>
                     <input
                        onChange={formik.handleChange}
                        value={formik.values.direccionNumero}
                        onBlur={formik.handleBlur}
                        id='direccionNumero'
                        name='direccionNumero'
                        className='w-11/12  border border-gray-300 rounded-sm '
                        placeholder=''
                     />
                     </div>

                     <div className='w-1/5'>
                     <div className='font-black  pb-2'>Colonia</div>
                     <input
                        onChange={formik.handleChange}
                        value={formik.values.direccionColonia}
                        onBlur={formik.handleBlur}
                        id='direccionColonia'
                        name='direccionColonia'
                        className='w-11/12  border border-gray-300 rounded-sm '
                        placeholder=''
                     />
                     </div>

                     <div className='w-1/5'>
                     <div className='font-black  pb-2'>Código postal</div>
                     <input
                        type="number"
                        step="1"
                        min="1"
                        onChange={formik.handleChange}
                        value={formik.values.direccionCP}
                        onBlur={formik.handleBlur}
                        id='direccionCP'
                        name='direccionCP'
                        className='w-11/12  border border-gray-300 rounded-sm '
                        placeholder=''
                     />
                     </div>

                  </div>
                  
                  <div className='flex flex-row mt-6'>
                     <div className='w-1/5'>
                        <div className='font-black py-2'>Estado</div>
                        <select
                           onChange={ handleEstado }
                           value={formik.values.direccionEstado}
                           onBlur={formik.handleBlur}
                           id='direccionEstado'
                           name='direccionEstado'
                           className=' w-11/12 border border-gray-300 rounded-sm '
                        >
                           
                           <option value={""} >-</option>
                           { estados.map( ( e, index ) => (
                              <option key={ index } value={ e.idEstado }  >{ e.nombre }</option>
                           ) ) }
                        </select>
                     </div>

                     
                     <div className='w-1/5'>
                        <div className='font-black py-2'>Ciudad</div>
                        <select
                           onChange={formik.handleChange}
                           value={formik.values.direccionCiudad}
                           onBlur={formik.handleBlur}
                           id='direccionCiudad'
                           name='direccionCiudad'
                           className='w-11/12  border border-gray-300 rounded-sm '
                        >
                           <option value="">-</option>
                        {
                           ciudades.map( ( c, index ) => (
                              <option key={ index } value={ c.idCiudad }> { c.nombre } </option>
                           ) )
                        }
                        </select>
                     </div>
                     
                  </div>
               </div>

               <div className='flex flex-row justify-center w-11/12 pb-3'>
                  <button
                     type='submit'
                     className='botonverde py-1 w-48 h-10 rounded-sm shadow'
                  >
                     Guardar ubicacion
                  </button>
               </div>
               </form>
            </div>
         </div>
      </div>
  );
};
export default NuevaUbicacion;
