import React, { useCallback, useEffect, useState } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import { BiEdit } from 'react-icons/bi';
import axiosConfig from '../../utils/axiosConfig';

const initialAsignUser = {
  Usuario_asignacion_sucursal: [],
  Usuario_asignacion_segmento: []
}

const UsuariosDetalles = () => {
  
  const { id: idUsuario } = useParams();

  const [ asigUser, setAsingUser  ] = useState( initialAsignUser );
  const { Usuario_asignacion_sucursal, Usuario_asignacion_segmento } = asigUser
  
  const [ user, setUser ] = useState( {} );

  const getUsuario = useCallback( async () => {
    const data = await axiosConfig({
      method: 'get',
      url: `usuarios/${idUsuario}`,
    } );
    
    if ( !!data.user ) {
      setAsingUser( { Usuario_asignacion_segmento: data.user.Usuario_asignacion_segmento, Usuario_asignacion_sucursal: data.user.Usuario_asignacion_sucursal } )
      setUser( data.user )
    }

  }, [ idUsuario ] )

  useEffect( () => {
    getUsuario();
  }, [ idUsuario ] );
  
  return (
    <div className='pl-6 pt-2 mb-5 '>
      <div className='pb-3 flex flex-row justify-between w-11/12'>
        <div className='titulo  text-3xl'>Usuarios</div>

        <div className='flex flex-row'>
          <Link to={`/inicio/usuarios/editar/${idUsuario}`}>
            <button className='botonverde py-1 flex flex-row w-28  justify-items-center rounded-sm shadow'>
              <div className='pt-1.5 px-2'>
                <BiEdit />
              </div>
              <div>Editar</div>
            </button>
          </Link>
          <div className='w-2'></div>
        </div>
      </div>
      <div className='mb-10 '>
        <div className='Login w-11/12 bg-white shadow rounded pb-10'>
          <div className='rojodiv w-full h-6 rounded-t'></div>
          <div className='border-b-2 titulo flex flex-row pl-6 pt-5 pb-3 text-xl'>
            <Link to='/inicio/usuarios'>
              <div className='pt-1 pr-1'>
                <MdArrowBack size={24} />
              </div>
            </Link>
            Detalles de usuario
          </div>

          <div className='p-8'>
            <div className='flex flex-row tablamenu'>
              
              <div className='w-1/3  '>
                <div className='font-black'>Nombre</div>
                { user.name }
              </div>
              
              <div className='w-1/3 '>
                <div className='font-black'>Correo</div>
                { user.email }
              </div>
              
              <div className='w-1/3 '>
                <div className='font-black'>Rol</div>
                { user.rol }
              </div>
            </div>

            <div className='flex flex-row tablamenu mt-7'>
              <div className='w-1/3'>
                <div className='font-black'>Sucursal</div>
                { user["Sucursal.nombre"] }
              </div>
              
              <div className='w-1/3'>
                <div className='font-black'>Region</div>
                { user["Sucursal.region"] }
              </div>

              <div className='w-1/3'>
                <div className='font-black'>Segmento</div>
                { user["Segmento.nombre"] }
              </div>

            </div>
          </div>

          <hr />

          <div className='px-10 py-5 '>
            <div className='flex flex-row justify-between'>

              <p style={ { opacity: "0.4", fontWeight: "bold" }}>
                Asignaciones
              </p>

            </div>
          </div>

          {
            !!Usuario_asignacion_sucursal.length && Usuario_asignacion_sucursal.length > 0 && 
            <div className='flex flex-row w-full mb-5 px-20'>

              <div className='w-2/12' style={{ fontWeight: "bold" }} >
                <p className='text-center' >
                  Sucursal
                </p>
              </div>
          
          </div>
        }
        {
          Usuario_asignacion_sucursal.map( ( asg, index ) => (
            <div key={ index }>
              <div className='mb-5 flex flex-row px-10' >
                <div className='w-3/12 text-center'>
                  
                  <label className='p-2 rounded w-8/12'>
                    Nombre: 
                  </label>
                </div>

                <div className='w-9/12 flex flex-row'>
                  { asg.Sucursal.nombre }
                </div>
              </div>

            </div>
          ) )
        }
        {
          Usuario_asignacion_sucursal.length === 0 &&  <div className='w-full grid justify-items-center text-xl font-bold'> No hay Sucursales asignados para { user.name } </div>
        }
        <div className='my-5' />
        {
          Usuario_asignacion_segmento.length > 0 && 
          <div className='flex flex-row w-full mb-5 px-20'>

            <div className='w-2/12' style={{ fontWeight: "bold" }} >
              <p className='text-center' >
                Segmento
              </p>
            </div>
        
          </div>
        }

        {
          Usuario_asignacion_segmento.map( ( asg, index ) => (
            <div key={ index } >
              <div className='mb-5 flex flex-row px-10 ' >
                <div className='w-3/12 text-center'>
                  <label className='p-2 rounded w-8/12'>
                    Nombre:   
                  </label>
                </div>

                <div className='w-9/12 flex flex-row'>
                  { asg.Segmento.nombre }
                </div>
              </div>

            </div>
          ) )
        }
        {
          Usuario_asignacion_segmento.length === 0 &&  <div className='w-full grid justify-items-center text-xl font-bold'> No hay Segmentos asignados para { user.name } </div>
        }
          
        </div>
      </div>
    </div>
  );
};

export default UsuariosDetalles;
