import React from 'react';
import { MdArrowBack } from 'react-icons/md';
// import axiosConfig from '../../utils/axiosConfig';
// import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';

const UsuariosNuevo = () => {
  // const history = useHistory();

  // const formik = useFormik({
  //   initialValues: {
  //     name: '',
  //     url: '',
  //   },
  //   onSubmit: (values) => {
  //     axiosConfig({
  //       method: 'post',
  //       url: `/forms`,
  //       data: {
  //         num_form: 1785,
  //         name: values.name,
  //         url: values.url,
  //         user_id: 1,
  //       },
  //     });
  //     alert('Formulario añadido');
  //     let path = `/inicio/formularios`;
  //     history.push(path);
  //   },
  // });

  return (
    <div className='pl-6 pt-2 w-11/12'>
      <div className=' pb-3'>
        <div className='titulo  text-3xl'>Usuario</div>
      </div>
      <div className='Login  bg-white shadow rounded'>
        <div className='rojodiv w-full h-6 rounded-t'></div>
        <div className='flex flex-row border-b-2 titulo pl-6 pt-5 pb-3 text-xl'>
          <Link to='/inicio/usuarios'>
            <div className='pt-1 pr-1'>
              <MdArrowBack size={24} />
            </div>
          </Link>
          Nuevo Usuario{' '}
        </div>
        {/* <form onSubmit={formik.handleSubmit}> */}
        <form>
          <div className='tablamenu p-6 flex flex-row'>
            <div className='w-1/4'>
              <div className='font-black'>Nombre </div>

              <input
                type='text'
                // onChange={formik.handleChange}
                // value={formik.values.name}
                // onBlur={formik.handleBlur}
                className='w-11/12 border border-gray-300 rounded	 pt-1 '
              />
            </div>
            <div className='w-1/4'>
              <div className='font-black'>Correo</div>
              <input
                type='text'
                // onChange={formik.handleChange}
                // value={formik.values.url}
                className='w-11/12   border border-gray-300 rounded	 pt-1 '
              />
            </div>
            <div className='w-1/4'>
              <div className='font-black'>Rol</div>
              <input
                type='text'
                // onChange={formik.handleChange}
                // value={formik.values.url}
                className='w-11/12   border border-gray-300 rounded	 pt-1 '
              />
            </div>
            <div className='w-1/4'>
              <div className='font-black'>Sucursal</div>
              <select
                type='text'
                // onChange={formik.handleChange}
                // value={formik.values.url}
                className='w-11/12   border border-gray-300 rounded	 pt-1 '
              >
                <option>---</option>
              </select>
            </div>
          </div>
          <div className='tablamenu p-6 flex flex-row'>
            <div className='w-1/4'>
              <div className='font-black'>Sección de negocio</div>
              <input
                type='text'
                // onChange={formik.handleChange}
                // value={formik.values.url}
                className='w-11/12   border border-gray-300 rounded	 pt-1 '
              />
            </div>
          </div>
          <div className='grid justify-items-center  pb-4 pt-7'>
            <button
              //   type='submit'
              className='botonverde  w-64 h-7 rounded shadow'
              // onClick={postFormulario(form, values)}
            >
              Guardar nuevo usuario
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UsuariosNuevo;
