import React, { useCallback, useEffect, useState } from 'react';
import { Link , useRouteMatch} from 'react-router-dom';
import axiosConfig from '../../utils/axiosConfig';
//import { useParams } from 'react-router-dom';
import { ImBin } from 'react-icons/im';
import { BiEdit } from 'react-icons/bi';
import { MdOutlineArrowBack } from 'react-icons/md';

import { NotificationManager } from 'react-notifications';

const DetallesUbicacion = () => {
   const { url } = useRouteMatch();
   const lastSegment = url.split("/").pop();
   //const { id: idDetalle } = useParams();
   const [ fdetalle, setFdetalle] = useState({});
   const [ region, setRegion ] = useState("")
   const [ nombreSucursal, setNombreSucursal ] = useState("")
   const [ estado, setEstado ] = useState( '' )
   const [ ciudad, setCiudad ] = useState( '' )
   
   const getEstadosCiudad = ( obj ) => {
      axiosConfig( {
         method: 'GET',
         url: `/ubicaciones/estados`,
      } ).then( ( data ) => {

         const estado = data.rows.filter( item => String( item.idEstado )  === obj.idEstado ).pop()
         setEstado( estado.nombre )
         
         axiosConfig( {
            method: 'GET',
            url: `/ubicaciones/ciudades/${ estado.idEstado }`,
         } ).then( resp => {

            const ciudad =  resp.rows.filter( item => String( item.idCiudad ) === String( obj.idCiudad ) ).pop()

            setCiudad( ciudad.nombre )

         } ).catch( err => NotificationManager.error( "Ingresó mal los campos o hay problema con el internet", '¡Error!' , 3000, () => {} ) )

      } ).catch( err => {
         NotificationManager.error( "Ingresó mal los campos o hay problema con el internet", '¡Error!' , 3000, () => {} )
      } )
   }

   const getdetalles = useCallback( async () => {
      const resp = await axiosConfig( {
         method: 'GET',
         url: `ubicaciones/${lastSegment}`,
      } );

      if( !!resp ){
         setRegion( resp.Sucursal.region )
         setNombreSucursal(resp.Sucursal.nombre)
         setFdetalle( resp );
         getEstadosCiudad( { idCiudad: resp.direccionCiudad , idEstado: resp.direccionEstado } )
      }else{
         NotificationManager.error( "", '¡Error!' , 3000 )
      }
      
   }, [ lastSegment ] );


   useEffect( () => {
      getdetalles();
   }, [ lastSegment, getdetalles ] );

   return (
      <div>
         <div className='pl-6 pt-2 '>
            <div className='pb-3 flex flex-row justify-between w-11/12'>
               <div className='titulo  text-3xl'>Ubicaciones</div>

               <div className='flex flex-row'>
                  <Link to={`/inicio/ubicaciones/editar/${lastSegment}`}>
                     <button className='botonverde py-1 flex flex-row w-28  justify-items-center rounded-sm shadow'>
                        <div className='pt-1.5 px-2'>
                        <BiEdit />
                        </div>
                        <div>Editar</div>
                     </button>
                  </Link>
                  <div className='w-2'></div>

                  <button className='botonrojo py-1 flex flex-row w-28  justify-items-center rounded-sm shadow'>
                     <div className='pt-1 px-2'>
                        <ImBin />
                     </div>
                     <div>Eliminar</div>
                  </button>
               </div>
            </div>

            <div className='Login w-11/12 bg-white shadow rounded'>
               <div className='rojodiv w-full h-6 rounded-t'></div>

               <div className='flex flex-row p-3 border-b-2'>
                  <div>
                     <Link to='/inicio/ubicaciones'>
                        <div className=' px-2'>
                        <MdOutlineArrowBack size='24' />
                        </div>
                     </Link>
                  </div>
                  <div className=' text-xl titulo'>Detalles de ubicación</div>
               </div>

               <div className='tablamenu p-8'>
                  <div className='flex flex-row'>
                     <div className='w-1/5'>
                        <div className='font-black pb-2'>Nombre de ubicacion</div>
                        <p>{fdetalle.nombreUbicacion} </p>
                     </div>
                     <div className='w-1/5'>
                        <div className='font-black  pb-2'>Tipo de ubicacion</div>
                        <p>{fdetalle.tipoUbicacion} </p>
                     </div>
                     <div className='w-1/5'>
                        <div className='font-black  pb-2'>Sucursal</div>
                        <p>{nombreSucursal} </p>
                     </div>
                     <div className='w-1/5'>
                        <div className='font-black  pb-2'>Region</div>
                        <p> { region } </p>
                     </div>
                  </div>
               <div className='subtitulo text-xl py-5'>Dirección</div>
               <div className='flex flex-row'>
                  <div className='w-1/5'>
                     <div className='font-black pb-2'>Calle</div>
                     <p>{fdetalle.direccionCalle} </p>
                  </div>

                  <div className='w-1/5'>
                     <div className='font-black  pb-2'>Número</div>
                     <p>{fdetalle.direccionNumero} </p>
                  </div>

                  <div className='w-1/5'>
                     <div className='font-black  pb-2'>Colonia</div>
                     <p>{fdetalle.direccionColonia} </p>
                  </div>

                  <div className='w-1/5'>
                     <div className='font-black  pb-2'>Código postal</div>
                     <p>{fdetalle.direccionCP} </p>
                  </div>

                  <div className='w-1/5'>
                     <div className='font-black pb-2'>Ciudad</div>
                     <p>{ ciudad } </p>
                  </div>
               </div>

               <div className='w-1/4'>
                  <div className='font-black py-2'>Estado</div>
                  <p>{ estado } </p>
               </div>
               </div>
            </div>
         </div>
      </div>
   );
};
export default DetallesUbicacion;
