import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications'

import { MdArrowBack } from 'react-icons/md';

import axiosConfig from '../../../utils/axiosConfig';

const initialValues = {
  nombre: '',
  numero: '',
  region: '',
}

const NuevaSucursal = () => {
  const history = useHistory();
  const [ sucursal, setSucursal ] = useState( initialValues )
  const { nombre, numero, region } = sucursal

  const handleChange = ( { target } ) => {
    setSucursal( s => ( { ...s, [target.name]:target.value } ) )
  }

   const handleSubmit = ( e ) => {
      e.preventDefault();
      
      if( validar() ){
         return;
      }

      axiosConfig({
         method: 'post',
         url: `/sucursales`,
         data: {
            nombre,
            numero,
            region,
         },
      } ).then( () => {
         NotificationManager.success( "Guardado", "Formulario añadido", 2000 )
         history.push(  `/inicio/catalogo` );

      } ).catch((err) => {
         if (err === 'Validation/ValidationError') {
            NotificationManager.error( "Ingresó mal los campos o hay problema con el internet", "Oops..", 2500 )
         }
      } );
   }

   const validar = () => {
      if( nombre === "" || numero === "" || region === "" ){
         NotificationManager.warning( "Falta llenar algunos campos", "Cuidaddo", 2500 );
         return true;
      }

      return false;
   }

   return (
      <div className='w-full'>
         <div className='Login  bg-white shadow rounded'>
            <div className='flex flex-row border-b-2 titulo pl-6 pt-5 pb-3 text-xl'>
               <Link to='/inicio/catalogo'>
               <div className='pt-1 pr-1'>
                  <MdArrowBack size={24} />
               </div>
               </Link>
               Crear nueva sucursal
            </div>
            <form onSubmit={ handleSubmit } >
               <div className='tablamenu p-6 flex flex-row'>
               <div className='w-1/3'>
                  <div className='font-black'>Nombre de Sucursal</div>

                  <input
                     type='text'
                     id='nombre'
                     name='nombre'
                     onChange={ handleChange }
                     value={ nombre }
                     className='w-11/12 border border-gray-300 rounded	 pt-1 '
                     placeholder='Nombre de Sucursal'
                     autoComplete='off'
                  />
               </div>

               <div className='w-1/3'>
                  <div className='font-black'>Clave</div>
                  <input
                     type='text'
                     id='numero'
                     name='numero'
                     onChange={ handleChange }
                     value={ numero }
                     className='w-11/12  border border-gray-300 rounded	 pt-1 '
                     placeholder='ingrese numero'
                     autoComplete='off'
                  />
               </div>
               <div className='w-1/3'>
                  <div className='font-black'>Region de sucursal</div>
                  <select name='region' onChange={ handleChange } value={ region } className='w-11/12  border border-gray-300 rounded pt-1' >
                     <option value={''}> - </option>
                     <option value={'Baja Norte'}> Baja Norte </option>
                     <option value={'Noroeste'}> Noroeste </option>
                     <option value={'Norte'}> Norte </option>
                     <option value={'Centro'}> Centro </option>
                     <option value={'Occidente'}> Occidente </option>
                     <option value={'Sureste'}> Sureste </option>
                  </select>
               </div>
               </div>

               <div className='grid justify-items-center pb-4 pt-7'>
               <button
                  type='submit'
                  className='botonverde  w-64 h-7 rounded shadow'
               >
                  Guardar nueva sucursal
               </button>
               </div>
            </form>
         </div>
      </div>
   );
};

export default NuevaSucursal;
