import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import { MdArrowBack } from 'react-icons/md';

import axiosConfig from '../../../utils/axiosConfig';

const initialValues = {
  nombre: '',
  numero: '',
}

const SegmentoNuevo = () => {
  const history = useHistory();
  const [ segmento, setSegmento ] = useState( initialValues )
  const { nombre, numero } = segmento;

  const handleChange = ( { target } ) => {
    setSegmento( s => ({ ...s, [target.name]: target.value }) )
  }

  const validar = () => {
    if( nombre === "" || numero === "" ){
      NotificationManager.warning( "Favor de llenar todos los campos", "Cuidado", 2500 )
      return true
    }
    return false
  }

  const handleSubmit = ( e ) => {
    e.preventDefault();

    if( validar() ){
      return;
    }
    
    axiosConfig( {
      method: 'POST',
      url: `/segmentos`,
      data: {
        nombre,
        numero,
      },
    } ).then( (  ) => {
      NotificationManager.success( "Segmento añadido", "Guardado", 2500 )
      history.push( `/inicio/catalogo/segmentos` );
    } ).catch((err) => {
      if (err === 'Validation/ValidationError') {
        NotificationManager.error( "Ingresó mal los campos o hay problema con el internet", "Oops...", 2500 )
      }
    } );
  }

  return (
    <div className='w-full'>
      <div className='Login  bg-white shadow rounded'>
        <div className='flex flex-row border-b-2 titulo pl-6 pt-5 pb-3 text-xl'>
          <Link to='/inicio/catalogo/segmentos'>
            <div className='pt-1 pr-1'>
              <MdArrowBack size={24} />
            </div>
          </Link>
          Crear nuevo segmento
        </div>
        <form onSubmit={ handleSubmit } >
          <div className='tablamenu p-6 flex flex-row'>
            <div className='w-1/3'>
              <div className='font-black'>Nombre de segmento</div>

              <input
                type='text'
                id='nombre'
                name='nombre'
                onChange={ handleChange }
                value={ nombre }
                className='w-11/12 border border-gray-300 rounded	 pt-1 '
                placeholder='Nombre del segmento'
                autoComplete='off'
              />
              
            </div>

            <div className='w-1/3'>
              <div className='font-black'>Clave</div>
              <input
                type='text'
                id='numero'
                name='numero'
                onChange={ handleChange }
                value={ numero }
                className='w-11/12  border border-gray-300 rounded	 pt-1 '
                placeholder='ingrese numero'
                autoComplete='off'
              />
              
            </div>
          </div>

          <div className='grid justify-items-center pb-4 pt-7'>
            <button
              type='submit'
              className='botonverde  w-64 h-7 rounded shadow'
            >
              Guardar nuevo segmento
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SegmentoNuevo;
