import React, { useEffect, useState } from 'react';
import { BiRefresh } from 'react-icons/bi';
import { BsCardList } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import axiosConfig from '../../utils/axiosConfig';
import { FaEraser } from 'react-icons/fa';
import { DateTime } from 'luxon';
import { Tooltip } from '@mui/material';
import { Pagination } from '../Pagination'

const AutorizarGuias = () => {
  const tableNames = [
    'Fecha de creación ',
    'Usuario que generó',
    'Origen',
    'Destino',
    '#Guia',
    'Contenido',
    'Segmento',
    'Opciones',
  ];

  const [ rowsPerPage, setRowsPerPage] = useState( 10 );
  const [borrar, setborrar] = useState(true)
  const [ prevPage, setPrevPage ] = useState( null )
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ nextPage, setNextPage ] = useState( null )
  const [change, setchange] = useState(false)
  const [ Drives, setDrives ] = useState( [] );
  const [ fecha,  setfecha  ] = useState( '' );
  

  const getDrives = async () => {
    setchange(true)
    const data = await axiosConfig({
      method: 'GET',
      url: `/guias`,
      params: {
        isAutorizada: false,
        limit: rowsPerPage,
        page: currentPage,
        ...(fecha ? { fecha } : {}),
        
      },
    } );
    
    
    if( !!data.rows && data.rows.length > 0 ) {
      setDrives( data.rows );
      setchange(false)
      setCurrentPage( data.currentPage )
      setNextPage(data.nextPage)
      setPrevPage(data.prevPage)
    }
  }

  const borrarFiltros = () => {
    setborrar(!borrar);
    setfecha('');
  };


  useEffect( () => {
    getDrives();
  }, [ rowsPerPage, borrar, fecha ,currentPage] );


  return (
    <div className=''>
      <div className='p-8'>
        <div className='flex flex-row justify-between'>
        <div className='flex flex-row '>
            <Tooltip title="Borrar busqueda" placement="top" arrow>
              <button className=' px-1.5 bg-gray-100 border-gray-400 border rounded'
              onClick={() => borrarFiltros()}>
                <FaEraser size={16} />
              </button>
            </Tooltip>

              <div className='w-4'></div>
              <Tooltip title="Actualizar Busqueda" placement="top" arrow>
                <button
                  className=' px-1 bg-gray-100 border-gray-400 border rounded'
                  onClick={() => {
                    getDrives();
                  }}
                >
                  <BiRefresh size={24} />
                </button>
              </Tooltip>
              
              <div className='w-4'></div>
            </div>
          <div className='flex flex-row '>
            <div className='tablamenu pt-1 pr-2'>Número de registros</div>

            <div className='flex flex-row'>
              <select
                value={ rowsPerPage }
                onChange={ ( event ) => setRowsPerPage( parseInt( event.target.value ) ) }
                className=' border-gray-400 border rounded '
              >
                <option value='10'>10</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
              </select>
            </div>
          </div>
        </div>
        <div className='flex flex-row pt-4'>
          <div className='w-1/4'>
            <div className='titulo text-lg'> Filtrar por fecha</div>
            <input
              value={fecha}
              onChange={(event) => setfecha(event.target.value)}
              className='w-full  border border-gray-300 rounded pt-1 '
              type='date'
            />
          </div>

          <div className='w-2'></div>
        </div>
        <div style={{ overflow: "auto" }} >
          <table className='content-table w-full'>
            <thead>
              <tr>
                { Drives.length === 0 && <th /> }
                { Drives.length > 0 && tableNames.map((name, index) => {
                  return <th key={ index } >{name}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              { Drives.length === 0 && 
                <tr><td> 
                  <div className='w-full grid justify-items-center text-xl font-bold'> No hay Guías para Autorizar </div>
                </td></tr>
              }
              { Drives.length > 0 && Drives.map( ( Drives, index ) => {
                return (
                  <tr key={ index }>
                    <td>{DateTime.fromISO(Drives.createdAt).toLocaleString()}</td>
                    <td>{Drives.User.name}</td>
                    <td>{Drives.UbicacionOrigen.nombreUbicacion }</td>
                    <td>{Drives.UbicacionDestino === null ? `${ Drives.DireccionAlternativa.direccionCalle } `: Drives.UbicacionDestino.nombreUbicacion } </td>
                    <td>{Drives.numGuia }</td>
                    <td>{Drives.descripcionContenido}</td>
                    <td>{Drives.Segmento.nombre}</td>

                    <td>
                      <button className=' botonazuldetalles   '>
                        <Link
                          to={`/inicio/guias/autorizarguiasdetalles/${Drives.idGuia}`}
                          className='w-16'
                        >
                          <div className='flex flex-row'>
                            <div className='pt-1 px-1'>
                              <BsCardList />
                            </div>
                            <div>Detalles</div>
                          </div>
                        </Link>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className='flex   mt-3 flex-row-reverse'>
          <Pagination 
            { ...{ currentPage, prevPage, nextPage, change } }
            { ...{ setCurrentPage} }
          />
            
          
        </div>
      </div>
    </div>
  );
};

export default AutorizarGuias;