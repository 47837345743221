import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import axiosConfig from '../../utils/axiosConfig';

import { NotificationManager } from 'react-notifications';

import { MdOutlineArrowBack } from 'react-icons/md';

const initVal = {
    nombreUbicacion: '',
    tipoUbicacion: '',
    sucursal: '',
    direccionCalle: '',
    direccionNumero: '',
    direccionColonia: '',
    direccionCP: '',
    direccionCiudad: '',
    direccionEstado: '',
}

const EditarUbicacion = () => {
   
   const { id:idUbicacion } = useParams()

   const history = useHistory()
   const [ region, setRegion ] = useState( "" )

   const [ ubicacion, setUbicacion ] = useState( initVal )
   const { nombreUbicacion, tipoUbicacion, sucursal,
      direccionCalle, direccionNumero, direccionColonia, 
      direccionCP, direccionCiudad, direccionEstado }  = ubicacion

   const [ sucursales, setSucursales ] = useState( [] );
   const [ estados, setEstados ] = useState( [] );
   const [ ciudades, setCiudades ] = useState( [] );
   
   const handleSubmit =  ( e ) => {
      
      e.preventDefault()

      const data = {
         nombreUbicacion:  nombreUbicacion,
         tipoUbicacion:    tipoUbicacion,
         sucursal:         String( sucursal ),
         direccionCalle:   direccionCalle,
         direccionNumero:  direccionNumero,
         direccionColonia: direccionColonia,
         direccionCP:      direccionCP,
         direccionCiudad:  direccionCiudad,
         direccionEstado:  direccionEstado,
      }

      if( nombreUbicacion === "" || tipoUbicacion === "" || sucursal === "" || 
         direccionCalle === "" || direccionNumero === "" || direccionColonia === "" || 
         direccionCP === "" || direccionCiudad === "" || direccionEstado === "" ){
         NotificationManager.warning( "Falta llenar campos", '¡Cuidado!' , 2500 )
         return;
      }
      axiosConfig( {
         method: 'PUT',
            url: `/ubicaciones/${ idUbicacion }`,
            data: {
            ...data,
         },
      } ).then( () => {
         NotificationManager.success( "Ubicacion Guardada con exito", "¡Guardado!", 2000 )
         history.push( "/inicio/ubicaciones" );
      } ).catch( err => {
         NotificationManager.error( err.message, '¡Error!' , 3000, () => {}  )
      } )
   }
   

   const handleChange = ( { target } ) => setUbicacion( s => ( { ...s, [ target.name ]: target.value } ) )
   
   const handleSucursal = ( { target } ) => {
      const { value } = target
      if( value === '' ){
         setRegion( "" )
         return;
      }

      setUbicacion( s => ( { ...s, 'sucursal': value } ) )

      const s = sucursales.filter( item => String(item.idSucursal) === String(target.value) ).pop()
      setRegion( s.region )
   }

   const getCiudades =  async ( idEstado ) => {
      const data = await axiosConfig( {
         method: 'GET',
         url: `/ubicaciones/ciudades/${ idEstado }`,
      } );

      if( !!data.rows ){
         setCiudades( data.rows )
         setUbicacion( s => ( { ...s, "direccionCiudad": direccionCiudad } ) )
      }else{
         NotificationManager.error( "Ingresó mal los campos o hay problema con el internet", '¡Error!' , 3000, () => {} )
      }
   }

   const getEstados = useCallback ( async () => {
      const data = await axiosConfig( {
         method: 'GET',
         url: `/ubicaciones/estados`
      } );

      if( !!data.rows ){
         setEstados( data.rows )
      }else{
         NotificationManager.error( "Ingresó mal los campos o hay problema con el internet", '¡Error!' , 3000, () => {} )
      }
   }, [] )

   const handleEstado = ( { target } ) => {
      const idEstado = target.value
      
      if( idEstado === "" ){
         setUbicacion( s => ( {...s, "direccionEstado": "" } )  )
         setCiudades( [] )
         return
      }

      setUbicacion( s => ( {...s, "direccionEstado": idEstado } )  )
      
      axiosConfig( {
         method: 'GET',
         url: `/ubicaciones/ciudades/${ idEstado }`,
      } ).then( ( data ) => {
         setCiudades( data.rows )

      } ).catch( () => {
         NotificationManager.error( "Ingresó mal los campos o hay problema con el internet", '¡Error!' , 3000, () => {} )
      } )

   }

   const getUbicacion = useCallback( async () => {
      const resp = await axiosConfig( {
         method: 'GET',
         url: `ubicaciones/${ idUbicacion }`,
      } )
      
      if( !!resp ){
         setUbicacion( { ...resp, sucursal: resp.idSucursal } )
         setRegion( resp.Sucursal.region )
         getEstados();  
      }
   }, [ idUbicacion ] );

   const getSucursales = () => {
      axiosConfig( {
         method: 'GET',
         url: `sucursales`,
         params: {
            limit: 1000
         }
      } ).then( ({ rows }) => {
         setSucursales( !!rows ? rows : [] )
      } ).catch( err => console.error( err ) );
   }

   useEffect( () => {
      getUbicacion();
      getSucursales();
   }, [ idUbicacion, getUbicacion ] );

   useEffect( () => {
      const aux = async () =>{
         if( ubicacion.direccionCiudad !== '' ){
            await getCiudades( ubicacion.direccionEstado )
         }
      } 
      aux()
   }, [ ubicacion.direccionCiudad ] )

   return (
      <div>
         <div className='pl-6 pt-2 '>
            <div className='pb-3  w-11/12'>
               <div className='titulo  text-3xl'>Ubicaciones</div>
            </div>
            <form onSubmit={ handleSubmit } >
               <div className='Login w-11/12 bg-white shadow rounded'>
                  <div className='rojodiv w-full h-6 rounded-t'></div>
                  <div className='flex flex-row p-3 border-b-2'>
                     <div>
                        <Link to='/inicio/ubicaciones'>
                           <div className=' px-2'>
                           <MdOutlineArrowBack size='24' />
                           </div>
                        </Link>
                     </div>
                     <div className='text-xl titulo'>Editar ubicación</div>
                  </div>
                  <div className='tablamenu p-8'>
                     <div className='flex flex-row'>
                        <div className='w-1/5'>
                           <div className='font-black pb-2'>Nombre de ubicacion</div>
                           <input
                              type='text'
                              id='nombreUbicacion'
                              name='nombreUbicacion'
                              onChange={ handleChange }
                              value={nombreUbicacion}
                              className='w-11/12 border border-gray-300 rounded	 pt-1 '
                           />
                        </div>
                        <div className='w-1/5'>
                           <div className='font-black  pb-2'>Tipo de ubicacion</div>
                           <select
                              id='tipoUbicacion'
                              name='tipoUbicacion'
                              onChange={handleChange}
                              value={tipoUbicacion}
                              className='w-11/12 border border-gray-300 rounded	 pt-1 '
                           >
                              <option value="">-</option>
                              <option value='Sucursal'>Sucursal</option>
                              <option value='CEDIS'>CEDIS</option>
                              <option value='CEDIS Oxxo'>CEDIS Oxxo</option>
                              <option value='Plaza'>Plaza</option>
                              <option value="Direccion particular">Direccion particular</option>
                              <option value="Proveedor Externo"> Proveedor Externo </option>
                              <option value="Cliente Externo">Cliente Externo</option>
                              <option value="Otros">Otros</option>
                           </select>
                        </div>
                        <div className='w-1/5'>
                           <div className='font-black  pb-2'>Sucursal</div>
                              <select
                                 name='sucursal'
                                 id='sucursal'
                                 value={ sucursal }
                                 onChange={ handleSucursal }
                                 className='w-11/12  border border-gray-300 rounded-sm '
                              >
                                 <option value={""}>-</option>
                                 {
                                    sucursales.map( ( item, index ) => (
                                          <option key={ index }  value={ item.idSucursal }>{ item.nombre }</option>
                                       ) )
                                 }
                              </select>
                           </div>
                           <div className="w-1/5">
                              <div className='font-black  pb-2'>Region</div>
                              <input
                                    disabled
                                    readOnly
                                    type='text'
                                    id='region'
                                    name='region'
                                    onChange={()=>{}}
                                    value={ region }
                                    className='w-11/12 border border-gray-300 rounded	 pt-1 '
                              />
                           </div>
                        </div>
                        <div className='subtitulo text-xl py-5'>Dirección</div>
                        <div className='flex flex-row'>
                           <div className='w-1/5'>
                              <div className='font-black pb-2'>Calle</div>
                              <input
                              type='text'
                              id='direccionCalle'
                              name='direccionCalle'
                              onChange={handleChange}
                              value={direccionCalle}
                              className='w-11/12 border border-gray-300 rounded	 pt-1 '
                           />
                        </div>
                        <div className='w-1/5'>
                           <div className='font-black  pb-2'>Número</div>
                              <input
                                 type='text'
                                 id='direccionNumero'
                                 name='direccionNumero'
                                 onChange={handleChange}
                                 value={direccionNumero}
                                 className='w-11/12 border border-gray-300 rounded	 pt-1 '
                              />
                           </div>

                        <div className='w-1/5'>
                           <div className='font-black  pb-2'>Colonia</div>
                           <input
                              type='text'
                              id='direccionColonia'
                              name='direccionColonia'
                              onChange={handleChange}
                              value={direccionColonia}
                              className='w-11/12 border border-gray-300 rounded	 pt-1 '
                           />
                        </div>

                        <div className='w-1/5'>
                           <div className='font-black  pb-2'>Código postal</div>
                           <input
                              type='numer'
                              id='direccionCP'
                              name='direccionCP'
                              onChange={handleChange}
                              value={direccionCP}
                              className='w-11/12 border border-gray-300 rounded	 pt-1 '
                           />
                        </div>

                     </div>

                     <div className='flex flex-rows mt-5'>

                        <div className='w-1/5'>
                           <div className='font-black py-2'>Estado</div>
                           <select
                              onChange={ handleEstado }
                              value={direccionEstado}
                              id='direccionEstado'
                              name='direccionEstado'
                              className=' w-11/12 border border-gray-300 rounded-sm '
                           >
                              
                              <option value={""} >-</option>
                              { estados.map( ( e, index ) => (
                                 <option key={ index } value={ e.idEstado }  >{ e.nombre }</option>
                              ) ) }
                           </select>
                        </div>

                        <div className='w-1/5'>
                           <div className='font-black py-2'>Ciudad</div>
                           <select
                              onChange={handleChange}
                              value={direccionCiudad}
                              id='direccionCiudad'
                              name='direccionCiudad'
                              className='w-11/12  border border-gray-300 rounded-sm '
                           >
                              <option value="">-</option>
                           {
                              ciudades.map( ( c, index ) => (
                                 <option key={ index } value={ c.idCiudad }> { c.nombre } </option>
                              ) )
                           }
                           </select>
                        </div>
                     </div>
                  </div>
                  <div className='flex flex-row justify-center w-11/12 pb-3'>
                     <button type='submit' className='botonverde  w-64 h-7 rounded shadow'>
                        Guardar ubicacion
                     </button>
                  </div>
               </div>
            </form>
         </div>
      </div>
   );
};
export default EditarUbicacion;
