import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import axiosConfig from '../utils/axiosConfig';
import jwt from 'jsonwebtoken';
import { getToken } from '../utils/authService';


export const TabsGuias = () => {
   const [openTab, setOpenTab] = useState( 1 );
   const { pathname } = useLocation();
   const [notificacion, setNotificacion] = useState();

   const token = getToken();
   const user = jwt.decode(token);
   
   useEffect( () => {
      const tab = pathname.split('/').pop()

      switch( tab ){
         case 'guias':
            setOpenTab( 1 )
            break;
         case 'autorizarguias':
            setOpenTab( 2 )
            break;
         default:
            setOpenTab( 1 )
            break;
      }
   }, [] )

   useEffect(() => {
     getDrives();
   }, [])
   

   const getDrives = async () => {
      const data = await axiosConfig({
        method: 'GET',
        url: `/guias`,
        params: {
          isAutorizada: false,
        },
      } );
      if( !!data.rows && data.rows.length > 0 ) {
        setNotificacion(data.count)
      }
    }

   return (
      <ul className='flex  flex-wrap pb-4 flex-row' role='tablist'>
         <li>
            <Link to="/inicio/guias" >
               <span className={ 'text-xs px-5 py-3 border-b-2  block leading-normal  ' + ( openTab === 1 ? ' text-lg	 border-b-4 bg-white TabsActive' : 'rojoletra text-lg	 bg-white Tabs 	') }
               onClick={ () => setOpenTab( 1 ) } data-toggle='tab' role='tablist' >
                  Lista de guías
               </span>
            </Link>
         </li>
         {
            user.role === "Administrador" && 
            <li>
               <Link to="/inicio/guias/autorizarguias">
                  <span className={ 'text-xs px-5 py-3 border-b-2  block leading-normal ' + ( openTab === 2 ? ' text-lg	 border-b-4 bg-white TabsActive' : 'rojoletra text-lg	 bg-white Tabs 	') }
                  onClick={ () => setOpenTab( 2 ) } data-toggle='tab' role='tablist' >
                     Autorizar guías { notificacion && 
                     <label className=' bg-red-700 pl-2 pr-2 rounded-full text-white'>{notificacion}
                     </label>}
                  </span>
               </Link>
            </li>
         }
      </ul>
   )
}