import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axiosConfig from '../../../utils/axiosConfig';
import { useParams } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';

const DetalleSucursal = () => {
  const { id: idSucursal } = useParams();

  const [ sucursal, setSucursal ] = useState({});

  const getSucursal = useCallback( async () => {
      
      const data = await axiosConfig({
        method: 'get',
        url: `sucursales/${ idSucursal }`,
      });
      
      if (data) {
        setSucursal( data );
      }
    }, [ idSucursal, setSucursal ] ) 

  useEffect(() => {
    getSucursal();
  }, [ idSucursal, getSucursal ]);
  
  return (
    <div>
      <div className='pl-6 pt-2 '>
        <div className='titulo pb-3 text-3xl'>Catálogos</div>

        <div className='Login w-11/12 bg-white shadow rounded'>
          <div className='rojodiv w-full h-6 rounded-t'></div>

          <div className='flex flex-row p-3 border-b-2'>
            <div>
              <Link to='/inicio/catalogo'>
                <div className=' px-2'>
                  <MdOutlineArrowBack size='24' />
                </div>
              </Link>
            </div>
            <div className=' text-xl titulo'>Detalles de Sucursal</div>
          </div>

          <div className='tablamenu p-8'>
            <div className='flex flex-row'>
              <div className='w-1/5'>
                <div className='font-black pb-2'>Nombre de sucursal</div>
                {sucursal.nombre}
              </div>
              <div className='w-1/5'>
                <div className='font-black  pb-2'>Id de sucursal</div>
                {sucursal.idSucursal}
              </div>
              <div className='w-1/5'>
                <div className='font-black  pb-2'>Clave</div>
                {sucursal.numero}
              </div>
              <div className='w-1/5'>
                <div className='font-black  pb-2'>Region de sucursal</div>
                {sucursal.region}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetalleSucursal;
