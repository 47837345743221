import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { NotificationManager } from 'react-notifications'
import Modal from 'react-modal';

import { AiOutlineCloseSquare } from 'react-icons/ai'
import { MdArrowBack } from 'react-icons/md';
import { BiPlus } from 'react-icons/bi';
import { FaTrash } from 'react-icons/fa'
import { RiSave3Fill } from 'react-icons/ri'

import axiosConfig from '../../utils/axiosConfig';

Modal.setAppElement('#root');

const estiloModal = {
   content: {
      top:'310px',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: '30%',
      transform: 'translate(-50%, -50%)',
   },
}

const Usuarioseditar = () => {

   const initialValues = {
      asignacionSucursal: [],
      asignacionSegmento: []
   }

   const initialAsignUser = {
      Usuario_asignacion_sucursal: [],
      Usuario_asignacion_segmento: []
   }

   const { id:idUsuario } = useParams();
   const [ user, setUser ] = useState( {} );

   const [ sucursales, setSucursales ] = useState( [] );
   const [ segmentos,  setSegmentos  ] = useState( [] );
   
   const [ region,   setRegion     ] = useState( '' )

   const [ asigUser, setAsingUser  ] = useState( initialAsignUser );

   const [ formAsing, setFormAsing ] = useState( initialValues );
   const { asignacionSucursal, asignacionSegmento } = formAsing;

   const [ openModal, setOpenModal ] = useState( false )

   const getUsuario = useCallback( async ()=> {
      const data = await axiosConfig({
         method: 'GET',
         url: `/usuarios/${ idUsuario }`
      } );
      
      if( !!data.user ){
         setUser( data.user )
         setRegion( data.user["Sucursal.region"] )
         if( !!data.user.Usuario_asignacion_segmento ){
            const { Usuario_asignacion_segmento } = data.user
            setAsingUser( s => ({...s, Usuario_asignacion_segmento }) )
         }
         if( !!data.user.Usuario_asignacion_sucursal ){
            const { Usuario_asignacion_sucursal } = data.user
            setAsingUser( s => ({...s, Usuario_asignacion_sucursal }) )
         }
      }
   }, [ idUsuario ] )

   const getSegmentos = useCallback( async () => {
      const data = await axiosConfig({
         method: 'GET',
         url: `/segmentos`,
         params: {
            limit: 1000,
            page: 1,
         },
      } );

      if( !!data.rows && data.rows.length > 0  ){
         setSegmentos( data.rows.sort( (a, b ) => ( Number( a.idSucursal ) - Number( b.idSucursal ) ) ) )
      }
   }, [] )

   const getSucursales = useCallback( async () => {
      const data = await axiosConfig({
         method: 'GET',
         url: `/sucursales/`,
         params: {
            limit: 1000,
            page: 1,
         },
      } );

      if( !!data.rows && data.rows.length > 0 ){
         setSucursales( data.rows.sort( (a, b ) => ( Number( a.idSucursal ) - Number( b.idSucursal ) ) ) )
      }
   }, [] )

   useEffect( ()=> {
      getSegmentos()
      getSucursales()
      getUsuario()
      return () => {
         setFormAsing( initialValues )
      }
   }, [ idUsuario ] );
   
   // Modal -----------------------------------------
   const handlenModal = () => {
      setOpenModal( s => !s );
      setFormAsing( initialValues )
   }

   const handleChangeSucursal = ( { target } ) => {
      const idSucursal = target.value
      
      const item = sucursales.filter( item => String( item.idSucursal ) === String( idSucursal ) ).pop()
      
      setFormAsing( s => {
         const { asignacionSucursal } = s;
         asignacionSucursal.push( item  ) 
         return { ...s };
      } )

      setSucursales( s => s.filter( item => String( item.idSucursal ) !== String( idSucursal ) ) )
   }

   const handleDeleteSucursal = ( sucursal ) => {
      
      setFormAsing( s => {
         const { asignacionSucursal } = s;
         const aux = asignacionSucursal.filter( item => item.idSucursal !== sucursal.idSucursal ) 
         return { ...s, asignacionSucursal: aux };
      } )

      sucursales.push( sucursal ) 
      setSucursales( sucursales.sort( (a, b ) => ( Number( a.idSucursal ) - Number( b.idSucursal ) ) ) )
   }

   const handleChangeSegmento = ( { target } ) =>{
      const idSegmento = target.value
      
      const item = segmentos.filter( item => String( item.idSegmento ) === String( idSegmento ) ).pop()
      
      setFormAsing( s => {
      const { asignacionSegmento } = s;
      asignacionSegmento.push( item  ) 
      return { ...s };
      } )

      setSegmentos( s => s.filter( item => String( item.idSegmento ) !== String( idSegmento ) ) )
   }

   const handleDeleteSegmento =  ( segmento ) => {
      
      setFormAsing( s => {
      const { asignacionSegmento } = s;
      const aux = asignacionSegmento.filter( item => item.idSegmento !== segmento.idSegmento ) 
      return { ...s, asignacionSegmento: aux };
      } )

      segmentos.push( segmento ) 
      setSegmentos( segmentos.sort( (a, b ) => ( Number( a.idSegmento ) - Number( b.idSegmento ) ) ) )
   }

   const handleSave = async () => {
      let flat = false;

      if( asignacionSucursal.length > 0 ){
         flat  = true
         for( let i = 0; i < asignacionSucursal.length; i++ ){
            const idSucursal = asignacionSucursal[i].idSucursal;
            
            await axiosConfig( {
               method: 'POST',
               url: `usuarioAsignacion/addSucursalUser/`,
               data: {
                  idSucursal,
                  idUsuario: Number( idUsuario )
               }
            } );
         }
      }

      if( asignacionSegmento.length > 0 ){  
         flat  = true
         for( let i = 0; i < asignacionSegmento.length; i++ ){
            const idSegmento = asignacionSegmento[i].idSegmento;
            
            await axiosConfig( {
               method: 'POST',
               url: `usuarioAsignacion/addSegmentoUser/`,
               data: {
                  idSegmento,
                  idUsuario: Number( idUsuario )
               }
            } );
         }
      }

      if( flat ){
         getUsuario();
         NotificationManager.success( "", "Guardado", 2500 )
      }

      setOpenModal( false )
   }
   // ----------------------------------------------

   const handleDeleteSucursalById = ( index ) => {
      const { idUsuario_asignacion_sucursal } = asigUser.Usuario_asignacion_sucursal[index]
      
      Swal.fire({
         title: '¿Estás seguro que quieres eliminarlo?',
         showDenyButton: true,
         confirmButtonText: 'Eliminar',
         denyButtonText: `Cancelar`,
      } ).then( ( result ) => {
         if( result.isConfirmed ){
            
            axiosConfig({
               method: 'PUT',
               url: `usuarioAsignacion/activoUsuarioAsignacionSucursal/${ idUsuario_asignacion_sucursal }`,
               data: {
                  idUsuario: Number( idUsuario )
               }
            } ).then( async ( resp ) => {
               NotificationManager.success( "", "Eliminado", 2500 )
               await getUsuario();
            } ).catch( (err) => NotificationManager.error( "Ingresó mal los campos o hay problema con el internet", "Oops", 2500 ) )

         } else {
            if( result.isDenied ) {
               NotificationManager.info( "", "Cancelado", 2500 )
            }
         }
      } );
   }

   const handleDeleteSegmentoById = ( index ) => {
      const { idUsuario_asignacion_segmento } = asigUser.Usuario_asignacion_segmento[index]

      Swal.fire( {
         title: '¿Estás seguro que quieres eliminarlo?',
         showDenyButton: true,
         confirmButtonText: 'Eliminar',
         denyButtonText: `Cancelar`,
      } ).then( ( result ) => {
         if( result.isConfirmed ){
           axiosConfig({
             method: 'PUT',
             url: `/usuarioAsignacion/activoUsuarioAsignacionSegmento/${ idUsuario_asignacion_segmento }`,
             params: {
                idUsuario
             }
           } ).then( async () => {
             NotificationManager.success( "", "Eliminado", 2500 )
             await getUsuario();
           } ).catch( () => NotificationManager.success( "Ingresó mal los campos o hay problema con el internet", "Oops", 2500 ) )
         } else {
           if( result.isDenied ) {
             NotificationManager.info( "", "Cancelado", 2500 )
           }
         }
      } )

   }

   const handleSaveUserSucursal = () =>{
      
      if( user["Sucursal.idSucursal"] === "" ){
         return;
      }

      const idSucursal = user["Sucursal.idSucursal"]
      
      axiosConfig( {
         method: 'POST',
         url: `usuarios/addSucursalUser/`,
         data: {
            idSucursal: Number( idSucursal ) ,
            idUsuario:  Number( idUsuario )
         }
      } ).then( () => {
         NotificationManager.success("", "Guardado", 2500 )
      } ).catch( () => NotificationManager.error( "Ingresó mal los campos o hay problema con el internet", "Oops", 2500 ) ) 
   }

   const handleSaveUserSegmento = () => {
      
      if( user["Segmento.idSegmento"] === '' ){
         return;
      }

      const idSegmento = user["Segmento.idSegmento"]
   
      axiosConfig( {
         method: 'POST',
         url: `usuarios/addSegmentoUser/`,
         data: {
            idSegmento: Number( idSegmento ),
            idUsuario:  Number( idUsuario )
         }
      } ).then( ( ) => {
         NotificationManager.success("", "Guardado", 2500 )
      } ).catch( () => NotificationManager.error( "Ingresó mal los campos o hay problema con el internet", "Oops", 2500 ) )

   }

   const handleChangeSucursalRegion = ( { target } ) => {
      let sucursal = target.value

      if( sucursal === '' ){
         setUser( s=> ( {...s, "Sucursal.idSucursal": "" } ) )
         // setRegion( '' )
         return;
      }
      
      setUser( s => ( {...s, "Sucursal.idSucursal": sucursal } ) )
      const auxRegion = sucursales.filter( item => item.idSucursal === Number( sucursal ) ).pop()
      setRegion( auxRegion.region )
   }
   // ----------------------------------------------

   
   return (
      <div className='pl-6 pt-2 w-11/12 mb-10'>
         <div className=' pb-3'>
            <div className='titulo  text-3xl'>Usuario</div>
         </div>
         <div className='Login  bg-white shadow rounded'>
            <div className='rojodiv w-full h-6 rounded-t'></div>
            <div className='flex flex-row border-b-2 titulo pl-6 pt-5 pb-3 text-xl'>
               <Link to='/inicio/usuarios'>
               <div className='pt-1 pr-1'>
                  <MdArrowBack size={24} />
               </div>
               </Link>
               Editar Usuario
            </div>
            
            <div>
               <div className='tablamenu p-6 flex flex-row'>
                  <div className='w-1/3'>
                     <div className='font-black'>Nombre</div>
                     { user.name }
                  </div>
                  <div className='w-1/3 '>
                     <div className='font-black'>Correo</div>
                     { user.email }
                  </div>
                  <div className='w-1/3 '>
                     <div className='font-black'>Rol</div>
                     { user.rol }
                  </div>       
               </div>
               <div className='tablamenu p-6 flex flex-row'>
                  <div className='w-1/3'>
                     <div className='font-black'>Sucursal</div>
                     <select name="sucursal" id="sucursal" value={ user["Sucursal.idSucursal"] }   onChange={ handleChangeSucursalRegion } >
                        <option value={""}>-</option>
                        {
                           sucursales.map( ( s, index ) => (
                              <option key={ index } value={s.idSucursal} > { s.nombre } </option>
                           ) )
                        }
                     </select>
                     <span className='botonverde pt-1 px-1 mx-3 rounded shadow'>
                        <button onClick={handleSaveUserSucursal} className="m-0">
                           <RiSave3Fill  className=' text-xl	m-0' />
                        </button>
                     </span>
                  </div>

                  <div className='w-1/3'>
                     <div className='font-black'>Region</div>
                     <input
                        disabled
                        readOnly
                        type='text'
                        id='region'
                        name='region'
                        onChange={()=>{}}
                        value={ region }
                        className='w-6/12 border border-gray-300 rounded	 pt-1 '
                     />
                  </div>

                  <div className='w-1/3' >
                     <div className='font-black'>Segmento</div>
                     <select name="segmento" id="segmento"  value={ user["Segmento.idSegmento"] } onChange={ ( { target } ) => setUser( s=> ( {...s, "Segmento.idSegmento": target.value } ) ) } >
                        <option value={""}> - </option>
                        {
                           segmentos.map( ( s, index ) => (
                              <option key={ index } value={ s.idSegmento } > { s.nombre } </option>
                           ) )
                        }
                     </select>
                     <span className='botonverde pt-1 px-1 mx-3 rounded shadow'>
                        <button onClick={ handleSaveUserSegmento } className="m-0">
                           <RiSave3Fill className='text-xl m-0' />
                        </button>
                     </span>
                  </div>

                  </div>

               <hr />
               
               <div className='px-10 py-5 ' >
               
                  <div className='flex flex-row justify-between '>

                     <p style={ { opacity: "0.4", fontWeight: "bold" }}>
                        Asignaciones
                     </p>

                     <button type='button' className='botonazul rounded shadow' onClick={ handlenModal } >
                        <div className='pt-1 px-2 flex flex-row '>
                        <span className='pt-1 px-1'>
                           <BiPlus />
                        </span>
                        Nueva Sucursal
                        </div>
                     </button>

                  </div>

                  { asigUser.Usuario_asignacion_sucursal.length > 0 && 
                     <div className='flex  px-16 flex-row ' >
                        <div className='flex flex-row w-full mb-2'>
                           <p className='text-center'   style={{ fontWeight: "bold" }}>
                              Sucursal
                           </p>
                        </div>
                     </div>
                  }
                  {
                     asigUser.Usuario_asignacion_sucursal.length > 0 && 
                     asigUser.Usuario_asignacion_sucursal.map( ( asig, index ) => (
                        <div className='my-4 px-16 w-3/6 ' key={ index } >
                           <div className=' flex flex-row justify-between '>
                              <label name={ `sucursalAsign-${ index }` } className='  w-full '> { asig.Sucursal.nombre } </label>
                              
                              <button type='button' className=' w-1/12 '  onClick={ () => handleDeleteSucursalById( index ) } >
                                 <FaTrash className=' mx-auto text-red-700 text-xl	' size={20} />
                              </button>

                           </div>
                           
                        </div>
                     ) )
                  }
                  {
                     asigUser.Usuario_asignacion_sucursal.length === 0 && <div className='w-full grid justify-items-center my-5'>
                        <h1 className='font-bold'> No Hay Asignaciones de sucursales para { user.name } </h1>
                     </div>
                  }
                  <hr className='my-5' />
                  { asigUser.Usuario_asignacion_segmento.length > 0 && 
                     <div className='flex px-16 flex-row ' >
                        <div className='flex flex-row w-full mb-2'>
                           <p className='text-center'   style={{ fontWeight: "bold" }}>
                              Segmento
                           </p>
                        </div>
                     </div>
                  }
                  {
                     asigUser.Usuario_asignacion_segmento.length > 0 && 
                     asigUser.Usuario_asignacion_segmento.map( ( asig, index ) => (
                        <div className='my-4 px-16 w-3/6' key={ index } >
                           <div className='flex flex-row justify-between '>
                              <label name={ `segmentoAsign-${ index }` } className=' w-full '> { asig.Segmento.nombre } </label>
                              
                              <button type='button' className=' w-1/12 '  onClick={ () => handleDeleteSegmentoById( index ) } >
                                 <FaTrash className=' text-red-700 text-2xl	' size={20} />
                              </button>

                           </div>
                           
                        </div>
                     ) )
                  }
                  {
                     asigUser.Usuario_asignacion_segmento.length === 0 && <div className='w-full grid justify-items-center my-5'>
                        <h1 className='font-bold'> No Hay Asignaciones de segmentos para { user.name } </h1>
                     </div>
                  }
               </div>
               <div className='grid justify-items-center  pb-4 '></div>
            </div>
         </div>
         <Modal  isOpen={ openModal } contentdiv='Usuario Modal' style={estiloModal} onRequestClose={ () => handlenModal() } >
            
            <div className='flex flex-row justify-between border-b-2 pb-2'>
               <div className='titulo text-xl'>
               Asignación de Sucursal y Segmento
               </div>

               <button className='rojoletra' onClick={ () => setOpenModal( false ) } >
               <AiOutlineCloseSquare size={28} />
               </button>
            </div>
            
            <div className='w-1/12'  >
               <p className='my-2' >
               Sucursal
               </p>
            </div>
               
            <div className='w-11/12 flex flex-row'>
               <select name='asignacionSucursal' className='border-gray-400 border p-2 rounded w-full ' value={ '' } onChange={ handleChangeSucursal }>
               <option  value={ "" }></option> 
               {
                     sucursales.filter( ( item ) => {
                        if( asigUser.Usuario_asignacion_sucursal.length === 0 ){
                           return true
                        }
                        const flat = asigUser.Usuario_asignacion_sucursal.find( ( elem ) => elem.idSucursal === item.idSucursal )
                        return !flat
                     } ).map( ( s, index ) => (
                        <option  key={ index } value={ s.idSucursal }> { s.nombre } </option>
                     ) )
               }
               </select>
            
            </div>
            
            <div className='px-5 mt-5' style={ { overflow: "auto", width: "91%",  maxHeight: "140px" } }>
               <ul>
                  {
                     asignacionSucursal.map( ( s , index ) => ( 
                        <li key={ index } className="flex justify-between" >
                           <div>
                              { s.nombre } 
                           </div>
                           <button type='button' className='m-0 w-1/6 text-red-700	' onClick={ () => handleDeleteSucursal( s ) } >
                              <FaTrash className=' mx-auto text-2xl	my-1' size={18} />
                           </button>
                        </li>
                     ) )
                  }
                  
               </ul>
            </div>

            <hr  className='mt-5'/>

            <div className='w-1/12' >
               <p className='my-2' >
               Segmento
               </p>
            </div>
            
            <div className='w-11/12 flex flex-row'>
               <select name='segmentoAsign' className='border-gray-400 border p-2 rounded w-full ' value={ ''  } onChange={ (e) => handleChangeSegmento( e ) }>
               <option  value={ "" }></option>
               {
                  segmentos.filter( ( item ) => {
                     if( asigUser.Usuario_asignacion_segmento.length === 0 ){
                        return true
                     }
                     const flat = asigUser.Usuario_asignacion_segmento.find( ( elem ) => elem.idSegmento === item.idSegmento )
                     return !flat;
                  } ).map( ( s, index ) => (
                     <option  key={ index } value={ s.idSegmento }> { s.nombre } </option>
                  ) )
               }
               </select>
            </div>

            <div className='px-5 mt-5' style={ { overflow: "auto", width: "91%",  maxHeight: "140px" } }>
               <ul>
               {
                  asignacionSegmento.map( ( s, index ) => ( 
                     <li key={ index } className="flex justify-between">
                        <div>
                           { s.nombre } 
                        </div>
                        <button type='button' className='m-0 w-1/6 text-red-700	' onClick={ ()=> handleDeleteSegmento( s ) } >
                           <FaTrash className=' mx-auto text-2xl	my-1' size={18} />
                        </button>
                     </li>
                  ) )
               }
               </ul>
            </div>

            <div className='flex flex-row mt-5'>
               <button type='button' className='botonverde m-auto px-7 rounded shadow' onClick={ handleSave } >
               <div className='pt-1 px-2 flex flex-row '>
                  <span className=' px-1'>
                     <RiSave3Fill className=' mx-auto text-2xl	' />
                  </span>
                  Guardar
               </div>
               </button>
            </div>

         </Modal>
      </div>
  );
};

export default Usuarioseditar;
