import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { FaPlus } from 'react-icons/fa';

import { TabsGuias } from '../TabsGuias';
import GuiasRoute from '../../routes/guias.routes';

const Guias = () => {
   const [ mostrar, setMostrar ] = useState( 1 )
   const { pathname } = useLocation();
   const [ estilo, setEstilo ] = useState( {maxWidth: "80vw"} )
   
   const toggle = !!localStorage.getItem("toggle") || false
   
   useEffect( ()=> {
      setEstilo( ({ ...( toggle? {maxWidth: "90vw"} : {maxWidth: "80vw"} ) })  )
         
      localStorage.setItem("toggle", !toggle  )
   }, [ toggle ] )

   const tabGuia = pathname.split('/').pop()
  
   useEffect( ()=> {
      setMostrar( ( tabGuia === 'guias' || tabGuia === 'autorizarguias' ) )
   }, [ tabGuia ] )

   return (
      <div className='pl-6 pt-2 pr-6'>
         <div className='flex flex-row justify-between w-full pb-3'>
            <div className='titulo text-3xl'>Guías</div>
            { mostrar &&
               <Link to={`/inicio/guias/nuevaguia`}>
                  <button className='botonverde py-1 flex flex-row w-48 h-10 justify-items-center rounded-sm shadow'>
                     <div className='pt-1 px-2'>
                        <FaPlus />
                     </div>
                     <div> Generar nueva guía </div>
                  </button>
               </Link>
            }
         </div>
         
         <div className='Login bg-white shadow rounded' style={ estilo } >
            <div className='rojodiv w-full h-6 rounded-t'></div>
            <div className='w-full ' >
               { mostrar && <TabsGuias/> }
               <GuiasRoute />
            </div>
         </div>
      </div>
   );
};

export default Guias;
