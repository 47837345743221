import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import Swal from 'sweetalert2';

import Tooltip from '@mui/material/Tooltip';
import { BiRefresh } from 'react-icons/bi';
import { FaSearch, FaPlus, FaEdit, FaEraser } from 'react-icons/fa';
import { BsCardList } from 'react-icons/bs';
import { Pagination } from '../Pagination'
import { ImBin } from 'react-icons/im';
import { getToken } from '../../utils/authService';
import jwt from 'jsonwebtoken';
import axiosConfig from '../../utils/axiosConfig';

const UbicacionesPanel = () => {
  const tableNames = ['Nombre de ubicacion', 'Tipo de Ubicacion', 'Opciones'];
  
  const [ rowsPerPage, setRowsPerPage ] = useState( 10 );
  const [ prevPage,    setPrevPage    ] = useState( null ) 
  const [ currentPage, setCurrentPage  ] = useState( 1 );
  const [ nextPage,    setNextPage    ] = useState( null )  
  const [borrar, setborrar] = useState(true)
  const [ Drives, setDrives ] = useState( [] );
  const [ search, setSearch ] = useState( '' );
  const [change, setchange] = useState(false)

  const permisos = async() => {
    const token = getToken();
    const {email}  = jwt.decode( token );
    const rol = await axiosConfig({
      method: 'GET',
      url: `/usuarios`,
      params: {
        search: email
      },
    } );
    if( rol.rows ){
      return rol.rows[0];
    }else{
      console.log("No encontre el rol");
    }

  }

  const getUbicacionOrig = async () => {
    const {user_id} = await permisos();
      const resp = await axiosConfig( {
        method: "GET",
        url: `usuarios/${user_id}`
      } );
      if( !!resp.user ){
        const ubicaciones = await axiosConfig( {
          method: "GET",
          url: `ubicaciones`,
          params: {
            limit: rowsPerPage,
            page: currentPage,
            ...(search ? { search } : {}),
          },
        } );

        if( !!ubicaciones.rows ){
          return( ubicaciones.rows.filter( item => item.idSucursal === parseInt( resp.user["Sucursal.idSucursal"] ) ) );
          
        } else {
          NotificationManager.error( "Hay problema con el internet", "Oops..", 2500 )
        }
      } else {
        NotificationManager.error( "Hay problema con el internet", "Oops..", 2500 )
      }
    };

  const getDrives = async() => {
    setchange(true)
    const {rol} = await permisos();
    const ubiOrigen = await getUbicacionOrig();
    const data = await axiosConfig({
      method: 'GET',
      url: `/ubicaciones`,
      params: {
        limit: rowsPerPage,
        page: currentPage,
        ...(search ? { search } : {}),
      },
    } );

    if( !!data.rows ){
      if (rol === "Administrador") {
        setDrives( data.rows.sort( ( a, b ) => ( a.numero > b.numero ? 1 : ( a.numero < b.numero ? -1 : 0 ) ) ) )
        setCurrentPage(data.currentPage)
        setNextPage(data.nextPage)
        setPrevPage(data.prevPage)
        setchange(false)
      } else {
        setchange(false)
        setDrives(ubiOrigen)
        setCurrentPage(data.currentPage)
        setNextPage(data.nextPage)
        setPrevPage(data.prevPage)
      }
    }
    
  }

  const handleBorrar = () => {
    setRowsPerPage( 10 );
    setSearch('');
    setborrar(!borrar);
  }

  useEffect( () => {
    getDrives();
    getUbicacionOrig();

  }, [ rowsPerPage, borrar, currentPage] );

  return (
      <div className='pl-6 pt-2 pr-6'>
        <div className='pb-3 flex flex-row justify-between w-full'>
          <div className='titulo  text-3xl'>Ubicaciones</div>
          <Link to='/inicio/ubicaciones/nuevaubicacion'>
            <button className='botonverde py-1 flex flex-row w-44  justify-items-center rounded-sm shadow'>
              <div className='pt-1.5 px-2'>
                <FaPlus />
              </div>
              <div>Agregar ubicación</div>
            </button>
          </Link>
        </div>

        <div className='Login w-full bg-white shadow rounded'>
          <div className='rojodiv w-full h-6 rounded-t'></div>
          <div className='p-8'>
            <div className='flex flex-row justify-between'>
              <div className='flex flex-row '>
              <Tooltip title="Borrar busqueda" placement="top" arrow>
                <button className=' px-1.5 bg-gray-100 border-gray-400 border rounded' onClick={()=> handleBorrar()}>
                  <FaEraser size={16} />
                </button>
                </Tooltip>
                <div className='w-4'></div>
                <Tooltip title="Actualizar Busqueda" placement="top" arrow>
                <button
                  className=' px-1 bg-gray-100 border-gray-400 border rounded'
                  onClick={(event) => {
                    event.preventDefault();
                    getDrives();
                  }}
                >
                  <BiRefresh size={24} />
                </button>
                </Tooltip>
                <div className='w-4'></div>
                <Tooltip title="Buscar" placement="top" arrow>
                <button
                  className='  px-2 bg-gray-300 border-gray-300 border-b-2 border-l-2 border-t-2 rounded-l'
                  onClick={(event) => {
                    event.preventDefault();
                    getDrives();
                  }}
                >
                  <FaSearch />
                </button>
                </Tooltip>
                <input
                  className='w-60  border-2 border-gray-300 rounded-r	 pt-1 '
                  placeholder='Buscar'
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                />
              </div>
              <div className='flex flex-row '>
                <div className='tablamenu pt-1 pr-2'>Número de registros</div>

                <div className='flex flex-row'>
                  <select
                    value={ rowsPerPage }
                    onChange={ ( event ) => setRowsPerPage( parseInt( event.target.value ) ) }
                    className='   border-gray-400 border rounded '
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </select>
                </div>
              </div>
            </div>
            <table className='content-table w-full'>
              <thead>
              { Drives.length === 0 && <tr /> }  
            { Drives.length > 0 &&
              <tr>
                { Drives.length > 0 && tableNames.map( ( name, index ) => {
                  return <th key={ index } > { name } </th>;
                } ) }
              </tr>
            }  
              </thead>
              <tbody>
              { Drives.length === 0 && 
              <tr><td> 
                <div className='w-full grid justify-items-center text-xl font-bold'> No hay ubicaciones </div>
              </td></tr>
              }
                {Drives.map((Drives) => {
                  return (
                    <tr key={Drives.idUbicacion}>
                      <td>{Drives.nombreUbicacion}</td>
                      <td>{Drives.tipoUbicacion}</td>

                      <td>
                        <div className='flex flex-row justify-center'>
                          <button className=' botonazuldetalles   '>
                            <Link
                              to={`/inicio/ubicaciones/detalle/${Drives.idUbicacion}`}
                              className='w-16'
                            >
                              <div className='flex flex-row'>
                                <div className='pt-1 px-1'>
                                  <BsCardList />
                                </div>
                                <div>Detalles</div>
                              </div>
                            </Link>
                          </button>
                          <div className='p-3'></div>
                          <button className=' botonverdedetalles   '>
                            <Link
                              to={`/inicio/ubicaciones/editar/${Drives.idUbicacion}`}
                              className='w-16'
                            >
                              <div className='flex flex-row'>
                                <div className='pt-1 px-1'>
                                  <FaEdit />
                                </div>
                                <div>Editar</div>
                              </div>
                            </Link>
                          </button>
                          <div className='p-3'></div>

                          <button
                            className=' botonrojodetalles   '
                            onClick={async () => {
                              Swal.fire({
                                title: 'Seguro que quiere eliminar?',
                                showDenyButton: true,
                                confirmButtonText: 'Eliminar',
                                denyButtonText: `Cancelar`,
                              }).then(async(result) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (result.isConfirmed) {
                                  await axiosConfig({
                                    method: 'PUT',
                                    url: `/ubicaciones/activo/${Drives.idUbicacion}`,
                                  });
                                  NotificationManager.success( "", "Eliminado", 2500 )
                                  await getDrives();
                                } else if (result.isDenied) {
                                  NotificationManager.info( "", "Cancelado", 2500 )
                                }
                              });
                            }}
                          >
                            <div className='flex flex-row'>
                              <div className='pt-1 px-1'>
                                <ImBin />
                              </div>
                              <div>Eliminar</div>
                            </div>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div className='flex   mt-3 flex-row-reverse'>
        
        <Pagination 
          { ...{ currentPage, prevPage, nextPage, change } }
          { ...{ setCurrentPage} }
        />
          
      </div>
          </div>
        </div>
      </div>
  );
};

export default UbicacionesPanel;
