import React, { useCallback, useEffect, useState } from 'react';
import { BiRefresh } from 'react-icons/bi';
import { FaSearch } from 'react-icons/fa';
import { FaDownload } from 'react-icons/fa';
import { saveAs } from 'file-saver';
// import Select from 'react-select';
import { Tooltip } from '@mui/material';
import axiosConfig from '../../utils/axiosConfig';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { FaEraser } from 'react-icons/fa';

const ReportesC = () => {

  const tableNames = ['Sucursal ', 'Segmento', 'Costo'];
  const [formularioPerPage, setformularioPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [totalRows, setTotalRows] = useState();
  const [Drives, setDrives] = useState([]);
  const [search, setSearch] = useState('');
  const [fechaInicio, setfechaInicio] = useState([]);
  const [fechaFinal, setfechaFinal] = useState([]);
  const [sucursal, setSucursal] = useState([]);
  const [segmentos, setSegmentos] = useState([]);
  const [idSegmento, setidSegmento] = useState([]);
  const [idSucursal, setidSucursal] = useState([]);

  const getSucursales = async () => {
    const { count, rows } = await axiosConfig({
      method: 'get',
      url: `sucursales`,
      params: {
        limit: 1000,
        page: 1,
      },
    });
    if (rows) {
      if (count > 0) {
        setTotalRows(count);
      }
      setSucursal(rows);
    }
  };

  const getSegmentos = async () => {
    const { count, rows } = await axiosConfig({
      method: 'get',
      url: `segmentos`,
      params: {
        limit: 1000,
        page: 1,
      },
    });
    if (rows) {
      if (count > 0) {
        setTotalRows(count);
      }
      setSegmentos(rows);
    }
  };
  const borrarFiltros = () => {
    setfechaInicio([]);
    setfechaFinal([]);
    setidSegmento('');
    setidSucursal('');
  };
  useEffect(() => {
    getSucursales();
    getSegmentos();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [formularioPerPage, search]);
  
  const getDrives = useCallback( async () => {
      const { count, rows } = await axiosConfig({
        method: 'get',
      url: `/guias`,
      params: {
        isAutorizada: true,
        limit: formularioPerPage,
        page: currentPage,
        ...(idSegmento ? { idSegmento } : {}),
        ...(idSucursal ? { idSucursal } : {}),
      },
    });

    if (rows) {
      if (count > 0) {
        setTotalRows(count);
      }
      setDrives(rows);
    }
  }, [ formularioPerPage, currentPage, idSegmento ] )
  
  useEffect(() => {
    getDrives();
  }, [ getDrives, formularioPerPage, search, idSegmento, idSucursal]);
  
  useEffect(() => {
    const auxPageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalRows / 3); i++) {
      auxPageNumbers.push(i);
    }
    setPageNumbers(auxPageNumbers);
  }, [ totalRows ] );

  const handleDownloadReporte = async (values, actions) => {
    try {
      let extension = 'xlsx';

      const response = await axiosConfig({
        method: 'post',
        url: `/reporte/repGuiasCostosConsolidados`,
        data:{
          fechaInicio: fechaInicio,
          fechaFinal: fechaFinal
        },
        responseType: 'blob',
      });

      saveAs(response.data, `${'reporteguiacostoconsolidados'}.${extension}`);
    } catch (error) {
      console.log('Error:', error);
    }
  };

  return (
    <div>
      <div className='pl-6 pt-2 '>
        <div className='flex flex-row justify-between w-11/12 pb-3'>
          <div className='titulo  text-3xl'>Reportes Consolidados</div>
          <div className='flex flex-row Letralight'>
            <div className='mr-4'>
              <div> Fecha de inicio</div>

              <input
                value={fechaInicio}
                onChange={(event) => setfechaInicio(event.target.value)}
                className='w-full  border border-gray-300 rounded pt-1 '
                type='date'
              />
            </div>

            <div className='mr-4'>
              <div> Fecha de fin</div>

              <input
                value={fechaFinal}
                onChange={(event) => setfechaFinal(event.target.value)}
                className='w-full  border border-gray-300 rounded pt-1 '
                type='date'
              />
            </div>

            <button
              className='botonverde pr-2 mt-5 py-1 flex flex-row w-58 h-10 justify-items-center rounded shadow'
              onClick={() => {
                handleDownloadReporte();
              }}
            >
              <div className='pt-1 px-2'>
                <FaDownload />
              </div>
              <div>Descargar reporte de guías</div>
            </button>
          </div>
        </div>
        <div className='Login w-11/12 bg-white shadow rounded'>
          <div className='rojodiv w-full h-6 rounded-t'></div>
          <div className='p-8'>
            <div className='flex flex-row justify-between'>
              <div className='flex flex-row '>
                <Tooltip title="Borrar filtros" placement="top" arrow>
                <button
                  className=' px-1.5 bg-gray-100 border-gray-400 border rounded'
                  onClick={() => {
                    borrarFiltros();
                  }}
                >
                  <FaEraser size={16} />
                </button>
                </Tooltip>
                <div className='w-4'></div>
                <Tooltip title="Actualizar Busqueda" placement="top" arrow>
                <button
                  className=' px-1 bg-gray-100 border-gray-400 border rounded'
                  onClick={(event) => {
                    event.preventDefault();
                    getDrives();
                  }}
                >
                  <BiRefresh size={24} />
                </button>
                </Tooltip>
                <div className='w-4'></div>
                <Tooltip title="Buscar" placement="top" arrow>
                <button
                  className='  px-2 bg-gray-300 border-gray-300 border-b-2 border-l-2 border-t-2 rounded-l'
                  onClick={(event) => {
                    event.preventDefault();
                    getDrives();
                  }}
                >
                  <FaSearch />
                </button>
                </Tooltip>
                <input
                  className='w-60  border-2 border-gray-300 rounded-r	 pt-1 '
                  placeholder='Buscar'
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                />
              </div>
              <div className='flex flex-row '>
                <div className='tablamenu pt-1 pr-2'>Número de registros</div>

                <div className='flex flex-row'>
                  <select
                    value={formularioPerPage}
                    onChange={(event) =>
                      setformularioPerPage(parseInt(event.target.value))
                    }
                    className='   border-gray-400 border rounded '
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='flex flex-row justify-between'>
              <div className='flex flex-row pt-4'>
                <div className=''>
                  <div className='text-lg titulo'> Filtrar por sucursal</div>
                  <select
                    name={`idSucursal`}
                    id='idSucursal'
                    value={idSucursal}
                    onChange={(event) => setidSucursal(event.target.value)}
                    className='w-11/12  border border-gray-300 rounded-sm '
                  >
                    <option>-</option>
                    {sucursal.map((sucursal) => {
                      return (
                        <option
                          key={sucursal.idSucursal}
                          value={sucursal.idSucursal}
                        >
                          {sucursal.nombre}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className='p-2'></div>
                <div className=''>
                  <div className='text-lg titulo'> Filtrar por segmento</div>

                  <select
                    name={`idSegmento`}
                    id='idSegmento'
                    value={idSegmento}
                    onChange={(event) => setidSegmento(event.target.value)}
                    className='w-11/12  border border-gray-300 rounded-sm '
                  >
                    <option>-</option>
                    {segmentos.map((segmento) => {
                      return (
                        <option
                          key={segmento.idSegmento}
                          value={segmento.idSegmento}
                        >
                          {segmento.nombre}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* <div className='text-lg titulo'> Filtrar por Contenido</div>

                  <select className=' w-full border h-8 border-gray-300 rounded '>
                    <option>---</option>

                    <option>---</option>

                    <option>---</option>

                    <option>---</option>
                  </select> */}
              </div>
            </div>
            <table className='content-table w-full'>
              <thead>
                <tr>
                  {tableNames.map((name) => {
                    return <th key={name}>{name}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {Drives.map((Drives) => {
                  return (
                    <tr key={Drives.id_drive}>
                      <td>
                        {Drives.Sucursal
                          ? Drives.Sucursal.nombre
                          : 'Sin sucursal asignada'}
                      </td>
                      <td>{Drives.Segmento.nombre}</td>
                      <td>{Drives.costo}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* <div>
            Mostrando registros del 1 al 10 de un total de 0000 registros
          </div> */}
            <div className='flex   mt-3 flex-row-reverse'>
              {/* Rows per page select */}

              {/* Page navigator */}
              <div className='inline-flex	'>
                <button
                  onClick={() => {
                    setCurrentPage((prev) => {
                      return prev - 1;
                    });
                  }}
                  disabled={currentPage === 1 ? 'disabled' : ''}
                  className={`btn-primary bg-blue-500 text-white py-1 px-2 
								rounded-l focus:outline-none text-xl ${
                  currentPage === 1
                    ? 'opacity-50 cursor-not-allowed'
                    : 'opacity-75 hover:opacity-100'
                }`}
                >
                  <BsArrowLeftShort />
                </button>
                <select
                  value={currentPage}
                  onChange={(event) =>
                    setCurrentPage(parseInt(event.target.value))
                  }
                  className='btn-primary bg-blue-500 text-white py-1 px-2 focus:outline-none'
                >
                  {pageNumbers.map((p) => {
                    return (
                      <option key={p} value={p}>
                        {p}
                      </option>
                    );
                  })}
                </select>
                <button
                  onClick={() => {
                    setCurrentPage((prev) => {
                      return prev + 1;
                    });
                  }}
                  disabled={
                    currentPage === pageNumbers.length ? 'disabled' : ''
                  }
                  className={`btn-primary bg-blue-500 text-white py-1 px-2 rounded-r focus:outline-none text-xl ${
                    currentPage === pageNumbers.length
                      ? 'opacity-50 cursor-not-allowed'
                      : 'opacity-75 hover:opacity-100'
                  }`}
                >
                  <BsArrowRightShort />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReportesC;
