import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axiosConfig from '../../utils/axiosConfig';
import { useParams } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';

const DetallesGuias = () => {
  const { id: idVdetalle } = useParams();

  const [ vdetalle, setVdetalle ] = useState({});
  const [ estados,  setEstados  ] = useState( [] );
  const [ ciudades, setCiudades ] = useState( [] );

  const getFdetalles = useCallback( async () => {
    const data = await axiosConfig({
      method: 'get',
      url: `guias/${idVdetalle}`,
    });
    
    if (!!data) {
      if (!!data.DireccionAlternativa) {
        getEstado(data.DireccionAlternativa.direccionEstado);
        getCiudad(data.DireccionAlternativa.direccionEstado, data.DireccionAlternativa.direccionCiudad);
      }
      setVdetalle(data);
    }
}, [ idVdetalle ] )

const getEstado = async (idEstado) => {
  const data = await axiosConfig( {
     method: 'GET',
     url: `/ubicaciones/estados`,
  } )
  if (data) {
    setEstados( data.rows.filter(estado => estado.idEstado===parseInt(idEstado))) 
  }
};

const getCiudad = async(idEstado,idCiudad) => {
  const data = await axiosConfig( {
    method: 'GET',
    url: `/ubicaciones/ciudades/${idEstado}`,
  } )
  if (data) {
  setCiudades( data.rows.filter(estado => estado.idCiudad===parseInt(idCiudad))) 
  }
}
  
  useEffect(() => {
    getFdetalles();
  }, [ idVdetalle, getFdetalles ]);

  return (
    <div className='pt-2 mb-10'>
      <div className='Login w-full bg-white shadow rounded'>
        <div className='flex flex-row p-3 border-b-2'>
          <div>
            <Link to='/inicio/guias'>
              <div className=' px-2'>
                <MdOutlineArrowBack size='24' />
              </div>
            </Link>
          </div>
          <div className=' text-xl titulo'>Detalles de guía</div>
        </div>
        <div className='tablamenu p-8'>
          <div className='flex flex-row'>
            <div className='w-1/4'>
              <div className=' text-lg font-black'> Segmento</div>
              { !!vdetalle.Segmento && vdetalle.Segmento.nombre }
            </div>
            <div className='p-2'></div>
            <div className='w-1/4'>
              <div className=' text-lg font-black'> Usuario de origen</div>
              { !!vdetalle.User && vdetalle.User.name }
            </div>
            <div className='p-2'></div>

            <div className='w-1/4'>
              <div className=' text-lg font-black'> Ubicación de origen </div>{' '}
              { !!vdetalle.UbicacionOrigen && vdetalle.UbicacionOrigen.nombreUbicacion }
            </div>
            <div className='p-2'></div>

            <div className='w-1/4'>
              <div className=' text-lg font-black'>
                Nombre de destinatario
              </div>
              { !!vdetalle.nombreDestinatario && vdetalle.nombreDestinatario }
            </div>
          </div>
          <div className='p-2'></div>
          <div className='flex flex-row'>
            {!!vdetalle.UbicacionDestino && 
            <div className='w-1/4'>
              <div className=' text-lg font-black'>
                {' '}
                Ubicación de destino{' '}
              </div>
              { !!vdetalle.UbicacionDestino && vdetalle.UbicacionDestino.nombreUbicacion }
            </div>
            }
            {!!vdetalle.UbicacionDestino && 
            <div className='p-2'></div>}        

            <div className='w-1/4'>
              <div className=' text-lg font-black'> Valor declarado </div>{' '}
              {!!vdetalle.valorDeclarado && vdetalle.valorDeclarado}
            </div>
            <div className='p-2'></div>

            <div className='w-1/4'>
              <div className=' text-lg font-black'> Servicio </div>{' '}
              {!!vdetalle.servicio && vdetalle.servicio}
            </div>
            <div className='p-2'></div>

            <div className='w-1/4'>
              <div className=' text-lg font-black'> Seguro opcional </div>{' '}
              { vdetalle.seguroOpcional ? "Activo" : "Inactivo" } 
            </div>
          </div>
          <div className='p-2'></div>
          {!!vdetalle.DireccionAlternativa &&
          <div className='felx-flex-row'>
          <div className=' text-lg font-black pb-2 border-b-2'>
            {' '}
            Direccion Alternativa{' '}
          </div>
          <div className='p-2'></div>
          <div className='flex flex-row'>
          <div className='w-1/4'>
            <div className=' text-lg font-black'>
              {' '}
              Calle{' '}
            </div>
            { !!vdetalle.DireccionAlternativa && vdetalle.DireccionAlternativa.direccionCalle }
          </div>           
          <div className='p-2'></div>        
          <div className='w-1/4'>
            <div className=' text-lg font-black'> Numero </div>{' '}
            {!!vdetalle.DireccionAlternativa && vdetalle.DireccionAlternativa.direccionNumero}
          </div>
          <div className='p-2'></div>

          <div className='w-1/4'>
            <div className=' text-lg font-black'> Colonia </div>{' '}
            {!!vdetalle.DireccionAlternativa && vdetalle.DireccionAlternativa.direccionColonia}
          </div>
          <div className='p-2'></div>

          <div className='w-1/4'>
            <div className=' text-lg font-black'> Codigo Postal </div>{' '}
            {!!vdetalle.DireccionAlternativa && vdetalle.DireccionAlternativa.direccionCP} 
          </div>
        </div>
        <div className='p-2'></div>
        </div>
        }
        {!!vdetalle.DireccionAlternativa &&
          <div className='flex flex-row'>
          <div className='w-1/4'>
            <div className=' text-lg font-black'>
              {' '}
              Estado{' '}
            </div>
            { estados.length!==0 && estados[0].nombre}
          </div>           
          <div className='p-2'></div>        
          <div className='w-1/4'>
            <div className=' text-lg font-black'> Ciudad </div>{' '}
            {ciudades.length!==0 && ciudades[0].nombre}
          </div>
          <div className='p-2'></div>
        </div>
        }
        <div className='p-2'></div>
          <div>
            <div className=' text-lg font-black pb-2 border-b-2'>
              {' '}
              Dimensiones del paquete{' '}
            </div>
          </div>
          <div className='p-2'></div>
          <div className='flex flex-row'>
              <div className='w-1/4'>
                <div className=' text-lg font-black'> Largo </div>{' '}
                {!!vdetalle.largo && vdetalle.largo}
              </div>
              <div className='p-2'></div>

              <div className='w-1/4'>
                <div className=' text-lg font-black'> Alto </div>{' '}
                {!!vdetalle.alto && vdetalle.alto}
              </div>
              <div className='p-2'></div>

              <div className='w-1/4'>
                <div className=' text-lg font-black'> Ancho </div>{' '}
                {!!vdetalle.ancho && vdetalle.ancho}
              </div>
            </div>
          <div className='p-2'></div>
          <div className=' text-lg font-black'>Información adicional</div>
          <div> {!!vdetalle.infoAdicional && vdetalle.infoAdicional}</div>
          <div className='p-2'></div>
          <div className='flex flex-row'>
            <div className='w-1/2'>
              <div className=' text-lg font-black'> Contenido </div>{' '}
              {!!vdetalle.Contenido && vdetalle.Contenido.descripcion}
            </div>
            <div className='p-2'></div>

            <div className='w-1/2'>
              <div className=' text-lg font-black'>
                Descripción de contenido
              </div>
              {!!vdetalle.descripcionContenido && vdetalle.descripcionContenido}
            </div>
          </div>
          <div className='p-2'></div>
          <div className='flex flex-row'>
            <div className='w-1/2'>
              <div className=' text-lg font-black'> Referencia </div>{' '}
              {!!vdetalle.referenciaContenido && vdetalle.referenciaContenido}
            </div>
            <div className='p-2'></div>

            <div className='w-1/2'>
              <div className=' text-lg font-black'> Descripción </div>{' '}
              {!!vdetalle.descripcion && vdetalle.descripcion}
            </div>
          </div>
          <div className='p-2'></div>
          <div className=' text-lg font-black pb-2 border-b-2'></div>
          <div className='p-2'></div>
          <div className='flex flex-row'>
            <div className='w-1/2'>
              <div className='text-lg font-black'> Numero de Guia</div>
                { !!vdetalle.numGuia && vdetalle.numGuia }
            </div>
            <div className='p-2'></div>
            <div className='w-1/2'>
              <div className='text-lg font-black'> Costo </div>{' '}
              {!!vdetalle.costo && vdetalle.costo}$
            </div>
          </div>        
        </div>
      </div>
    </div>
  );
};
export default DetallesGuias;
