import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import { MdArrowBack } from 'react-icons/md';

import axiosConfig from '../../../utils/axiosConfig';

const initialValues = {
  descripcion: '',
}

const ContenidoNuevo = () => {
  const history = useHistory();
  
  const [ contenido,  setContenido ] = useState( initialValues )
  const { descripcion } = contenido

  const handleChange = ( { target } ) => {
    setContenido( s => ( { ...s, [target.name]:target.value } ) )
  }

  const handleSubmit = ( e ) => {
    e.preventDefault()
    
    if( descripcion === "" ){
      NotificationManager.warning( "Falta llenar algunos campos", "Cuidado", 2500 )
      return
    }

    axiosConfig({
      method: 'POST',
      url: `/contenidos`,
      data: {
        descripcion,
      },
    } ).then( () => {
      NotificationManager.success( "Contenido Añadido", "Guardado", 2500 );
      history.push( `/inicio/catalogo/contenidos` );
    } ).catch( () => NotificationManager.error( "Ingresó mal los campos o hay problema con el internet", 'Oops...', 2500 ) );
  }

  return (
    <div className='w-full'>
      <div className='Login  bg-white shadow rounded'>
        <div className='flex flex-row border-b-2 titulo pl-6 pt-5 pb-3 text-xl'>
          <Link to='/inicio/catalogo/contenidos'>
            <div className='pt-1 pr-1'>
              <MdArrowBack size={24} />
            </div>
          </Link>
          Crear nuevo contenido
        </div>
        <form onSubmit={ handleSubmit } >
          
          <div className='tablamenu p-6 flex flex-row'>
            <div className='w-1/3'>
              <div className='font-black'>descripcion de contenido</div>
              <input
                type='text'
                id='descripcion'
                name='descripcion'
                onChange={ handleChange }
                value={ descripcion }
                className='w-11/12  border border-gray-300 rounded	 pt-1 '
                placeholder='ingrese descripcion'
                autoCapitalize='off'
              />
            </div>
          </div>
          
          <div className='grid justify-items-center pb-4 pt-7'>
            <button type='submit' className='botonverde  w-64 h-7 rounded shadow' >
              Guardar nuevo contenido
            </button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default ContenidoNuevo;
