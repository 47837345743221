import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import logo from '../assets/logo_caffenio.png';
import axiosConfig from '../utils/axiosConfig';
import { logout, storeToken } from '../utils/authService';
import { decode } from 'jsonwebtoken';

import { FaUserAlt } from 'react-icons/fa';
import { BsLockFill } from 'react-icons/bs';

const Login = () => {
  const [ token, setToken ] = useState(null);

  useEffect( () => {
    setToken( sessionStorage.getItem( 'token' ) );
  }, [] );

  const esRolAutorizado = ( token ) => {
    const user = decode( token )
    
    const roles = [
      'Encargado Administrativo',
      'Administrador',
      'Supervisor de Operaciones Drive',
      'Coordinador',
      'Entrenador',
      'Encargado de integración',
      'Encargado de Calidad' 
    ];
    
    for (let i = 0; i < roles.length; i++) {
      const rol = roles[i];
      if( user.role === rol ){
        return true
      }
    }
    
    return false;
  }

  const formik = useFormik({
    initialValues: {
      user: '',
      password: '',
    },
    onSubmit: (values) => {
      axiosConfig({
        method: 'post',
        url: '/auth',
        data: {
          usuario: values.user,
          password: values.password,
        },
      })
      .then( ( { token } ) => {
        if( !esRolAutorizado( token ) ){
          alert( "Su rol no tiene acceso al sistema" )
          return logout()
        }
        
        storeToken( token );
        setToken( token );
      })
      .catch((err) => {
        if (err === 'Auth/BadEmailOrPassword') {
          alert('El correo electrónico o contraseña es inválida.', {
            type: 'error',
          });
        } else {
          alert(
            'Error inesperado. Por favor sera este? revisa tu conexión a internet',
            {
              type: 'error',
            },
          );
        }
      });
    },

    validationSchema: Yup.object({
      user: Yup.string().required('Required'),
      password: Yup.string().required('Required'),
    }),
  });

  if ( token ) {
    return <Redirect to='/inicio' />;
  }

  return (
    <div className='Loginstyle grid justify-items-center pt-32'>
      <div className='w-60'>
        <img src={logo} alt='Logo' />
      </div>

      <div className='py-5'>
        <label>Plataforma de guías</label>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className='Login grid justify-items-center py-8 px-5 shadow-lg bg-white rounded'>
          <label>Inicio de sesión</label>
          <label htmlFor='user' className='pt-3'>
            Usuario CAFFENIO
          </label>
          <div className='grid grid-flow-col auto-cols-max'>
            <div className='pt-1.5 pr-2'>
              {' '}
              <FaUserAlt />
            </div>
            <div>
              <input
                type='text'
                id='user'
                name='user'
                onChange={formik.handleChange}
                value={formik.values.user}
                onBlur={formik.handleBlur}
                className='w-60 h-8 border-2 border-black focus:border-blue-500 rounded'
                placeholder='Ingrese su usuario Caffenio'
              />{' '}
            </div>
          </div>
          {formik.touched.user && formik.errors.user ? (
            <div>{formik.errors.user}</div>
          ) : null}
          <label htmlFor='password' className='pt-3'>
            Contraseña
          </label>
          <div className='grid grid-flow-col auto-cols-max'>
            <div className='pt-1.5 pr-2'>
              <BsLockFill />
            </div>
            <div>
              <input
                type='password'
                id='password'
                name='password'
                onChange={formik.handleChange}
                value={formik.values.password}
                onBlur={formik.handleBlur}
                className='w-60 h-8 border-2 border-black focus:border-blue-500 rounded'
                placeholder='Ingrese su contraseña'
              />
            </div>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div>{formik.errors.password}</div>
          ) : null}
          <div className='p-2'>
            <button type='submit' className='BlueButton rounded'>
              Acceder
            </button>
          </div>
          <div className='text-sm font-thin azul'>
            <a href='https://auth.caffenio.com/change-password'>¿Olvidaste tu contraseña? presione aqui</a>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
