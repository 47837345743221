import React, { useState, useEffect } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { NotificationManager } from "react-notifications";

import { MdOutlineArrowBack } from 'react-icons/md';

import axiosConfig from '../../../utils/axiosConfig';

const initialValues = {
  nombre: "",
  descripcion: ""
}

const ContenidoEditar = () => {
  const history = useHistory();
  const { id: idContenido } = useParams();

  const [ contenidoValue, setContenidoValue ] = useState( initialValues );
  const { descripcion } =  contenidoValue;

  const getContenido = async () => {
    const data = await axiosConfig({
      method: 'GET',
      url: `contenidos/${ idContenido }`,
    });

    if( !!data ) {
      setContenidoValue( data );
    }
  };

  const handleChange = ( { target } ) => {
    setContenidoValue( s => ( { ...s, [target.name]: target.value } ) )
  }

  const handleSubmit = ( e ) => {
    e.preventDefault();

    if( descripcion === '' ){
      NotificationManager.warning( "Faltan LLenar Campos", "Cuidado", 2500 );
      return;
    }

    axiosConfig( {
      method: 'PUT',
      url: `/contenidos/${ idContenido }`,
      data: {
        descripcion,
      },
    }).then( () => {
      NotificationManager.success( "Contenido Editado", "Guardado", 2500 );
      history.push(`/inicio/catalogo/contenidos` );
    } ).catch( () => NotificationManager.error( "Ingresó mal los campos o hay problema con el internet", "Oops", 2500 ) )
    
  }
  
  useEffect( () => {
    getContenido();
  }, [ idContenido ] );
  
  return (
    <div className='w-full'>
      <div className='Login  bg-white shadow rounded'>
        <div className='flex flex-row p-3 border-b-2'>
          <div>
            <Link to='/inicio/catalogo/contenidos'>
              <div className='px-2'>
                <MdOutlineArrowBack size='24' />
              </div>
            </Link>
          </div>
          <div className=' text-xl titulo'>Editar contenido</div>
        </div>
        <form onSubmit={ handleSubmit } >
          <div className='tablamenu p-6 flex flex-row'>
            <div className='w-1/3'>
              <div className='font-black'>Descripcion de contenido</div>
              <input
                type='text'
                id='descripcion'
                name='descripcion'
                onChange={ handleChange }
                value={ descripcion }
                className='w-11/12  border border-gray-300 rounded	 pt-1 '
                autoComplete='off'                
              />
            </div>
          </div>

          <div className='grid justify-items-center pb-4 pt-7'>
            <button
              type='submit'
              className='botonverde  w-64 h-7 rounded shadow'
              // onClick={postFormulario(form, values)}
            >
              Guardar cambios
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContenidoEditar;
